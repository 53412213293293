function Options({option}) {
    return (
        <>
            {
                option.map((a) => (
                    <option value={a.v}>{a.l}</option>
                ))
            }
        </>
    );
}

export default Options;
