import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useRef, useState, } from "react";
import { Row, Col, Modal, Button, Collapse, Card, Form, ListGroup, Spinner, InputGroup } from 'react-bootstrap';
import { Rating } from 'react-simple-star-rating';
import AnketaService from "../services/anketa.service";
import { useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Check2 from '../components/CheckBoxs'
import QA from '../components/QA' // quality $ age
import { BrowserView, MobileView } from 'react-device-detect';
import Options from "./Options";

export default function Anketa() {

    const radios10 = [
        // {name: '0', value: 'No info'},
        { name: '1', value: 1 },
        { name: '2', value: 2 },
        { name: '3', value: 3 },
        { name: '4', value: 4 },
        { name: '5', value: 5 },
        { name: '6', value: 6 },
        { name: '7', value: 7 },
        { name: '8', value: 8 },
        { name: '9', value: 9 },
        { name: '10', value: 10 }
    ];
    const radios20 = [
        // {name: '0', value: 'No info'},
        { name: '1', value: 1 },
        { name: '2', value: 2 },
        { name: '3', value: 3 },
        { name: '4', value: 4 },
        { name: '5', value: 5 },
        { name: '6', value: 6 },
        { name: '7', value: 7 },
        { name: '8', value: 8 },
        { name: '9', value: 9 },
        { name: '10', value: 10 },
        { name: '11', value: 11 },
        { name: '12', value: 12 },
        { name: '13', value: 13 },
        { name: '14', value: 14 },
        { name: '15', value: 15 },
        { name: '16', value: 16 },
        { name: '17', value: 17 },
        { name: '18', value: 18 },
        { name: '19', value: 19 },
        { name: '20', value: 20 }
    ];

    const optionProf = [
        { v: 1, l: 'сварщик' },
        { v: 2, l: 'сборщик' },
        { v: 4, l: 'монтажник' },
        { v: 8, l: 'бригадир' }
    ];

    const optionArr = [
        [
            {v: 1, l: '111 - MMA'},
            {v: 2, l: '121'},
            {v: 3, l: '136'},
            {v: 4, l: '138'},
            {v: 5, l: '141 - TIG'},
            {v: 6, l: '135 - MAG'},
            {v: 7, l: '311'}
        ],
        [
            {v: 1, l: '111 - MMA'},
            {v: 2, l: '121'},
            {v: 3, l: '136'},
            {v: 4, l: '138'},
            {v: 5, l: '141 - TIG'},
            {v: 6, l: '135 - MAG'},
            {v: 7, l: '311'}
        ]
    ]

    // const optionPros = [
    //     {v: 1, l: '111 - MMA'},
    //     {v: 2, l: '121-SAW - трактором' },
    //     {v: 15, l: '132 - MIG'},
    //     {v: 6, l: '135 - MAG'},
    //     {v: 3, l: '136 - MAG'},
    //     {v: 4, l: '138 - MAG'},
    //     {v: 5, l: '141 - TIG'},
    //     {v: 7, l: '311 - GAS'},
    //     {v: 16, l: '316 Duplex - Нержавейка'},
    // ];
    //
    // const optionSert = [
    //     {v: 9, l: '111 - MMA'},
    //     {v: 19, l: '121-SAW - трактором'},
    //     {v: 17, l: '132 - MIG'},
    //     {v: 5, l: '135 - MAG'},
    //     {v: 14, l: '136 - MAG'},
    //     {v: 15, l: '138 - MAG'},
    //     {v: 11, l: '141 - TIG'},
    //     {v: 18, l: '311 - GAS'},
    //     {v: 13, l: '316 Duplex - Нержавейка'},
    // ];


    const optionPros = [
        {v: 1, l: '111 - MMA'},
        {v: 5, l: '141 - TIG'},
        {v: 15, l: '132 - MIG'},

        {v: 6, l: '135 - MAG'},
        {v: 3, l: '136 - MAG'},
        {v: 4, l: '138 - MAG'},

        {v: 7, l: '311 - GAS'},
        {v: 16, l: '316 Duplex - Нержавейка'},
        {v: 2, l: '121-SAW - трактором' },
    ];

    const optionSert = [
        {v: 9, l: '111 - MMA'},
        {v: 11, l: '141 - TIG'},
        {v: 17, l: '132 - MIG'},

        {v: 5, l: '135 - MAG'},
        {v: 14, l: '136 - MAG'},
        {v: 15, l: '138 - MAG'},

        {v: 18, l: '311 - GAS'},
        {v: 13, l: '316 Duplex - Нержавейка'},
        {v: 19, l: '121-SAW - трактором'},
    ];


    // const optionCountries = [
    //     { v: 1, l: 'Эстония' },
    //     { v: 2, l: 'Финляндия' },
    //     { v: 3, l: 'Швеция' },
    //     { v: 4, l: 'Норвегия' },
    //     { v: 5, l: 'Дания' },
    //     { v: 6, l: 'Латвия' },
    //     { v: 7, l: 'Литва' },
    //     { v: 8, l: 'Польша' },
    //     { v: 9, l: 'Россия' },
    //     { v: 10, l: 'Украина' },
    //     { v: 11, l: 'Беларусь' },
    //     { v: 12, l: 'Другое' }
    // ];

    const optionStanok = [
        { v: 1, l: 'вальцы' },
        { v: 2, l: 'гибка' },
        { v: 3, l: 'пробойники' },
        { v: 4, l: 'пила' },
        { v: 5, l: 'сверловка' },
        { v: 6, l: 'фрезеровка' },
        { v: 7, l: 'токарка' },
        { v: 8, l: 'гильотина' },
        { v: 9, l: 'плазма' },
        { v: 11, l: 'резка' },
    ];

    const optionKonst = [
        { v: 1, l: 'контейнеры' },
        { v: 2, l: 'листовой металл' },
        { v: 4, l: 'поворотные узлы' },
        { v: 5, l: 'ковши' },
        { v: 6, l: 'фермы' },
        { v: 7, l: 'судострой' },
        { v: 9, l: 'модули' },
        { v: 10, l: 'теплотрассы' },
        { v: 11, l: 'теплообменники' },
    ];

    const optionApparati = [
        { v: 1, l: 'Kemppi' },
        { v: 2, l: 'Fronius' },
        { v: 3, l: 'Lincoln' },
        { v: 4, l: 'EWM' },
        { v: 5, l: 'ESAB' },
        { v: 6, l: 'Miller' },
        { v: 7, l: 'Wallius' },
    ];

    const executeScroll = (ref, t) => {
        console.log('ref ' + ref);
        if (!t) {
            if (ref.current) {
                ref.current.scrollIntoView()
            }
        } else {
            setTimeout(() => {
                console.log('Timer')
                if (ref.current) {
                    ref.current.scrollIntoView()
                }
            }, 500);
        }
    }

    const navigate = useNavigate();
    const redirect = event => navigate('/login');

    const [r, setR] = useState(0); // active question block
    const [rating, setRating] = useState(0); // % rating
    const [qCount, setQCount] = useState(0);  // count of question block
    const [qMask, setQMask] = useState(0); // Question bit mask
    const [qMaskDone, setQMaskDone] = useState(0); // Question bit mask done
    const [alertM, setAlertM] = useState('');
    const [show, setShow] = useState(false);
    const [isloading, setIsloading] = useState(false);

    const openArray = [
        { id: 0, op: false, er: true, ref: useRef(null), p: 15 },
        { id: 1, op: false, er: true, ref: useRef(null), p: 1 },
        { id: 2, op: false, er: true, ref: useRef(null), p: 1 },
        { id: 3, op: false, er: true, ref: useRef(null), p: 1 },
        { id: 4, op: false, er: true, ref: useRef(null), p: 1 },
        { id: 5, op: false, er: true, ref: useRef(null), p: 1 },
        { id: 6, op: false, er: true, ref: useRef(null), p: 1 },
        { id: 7, op: false, er: true, ref: useRef(null), p: 1 },
        { id: 8, op: false, er: true, ref: useRef(null), p: 1 },
        { id: 9, op: false, er: true, ref: useRef(null), p: 1 },
        { id: 10, op: false, er: true, ref: useRef(null), p: 1 },
        { id: 11, op: false, er: true, ref: useRef(null), p: 2 },
        { id: 12, op: false, er: true, ref: useRef(null), p: 2 },
        { id: 13, op: false, er: true, ref: useRef(null), p: 2 },
        { id: 14, op: false, er: true, ref: useRef(null), p: 2 },
        { id: 15, op: false, er: true, ref: useRef(null), p: 2 },
        { id: 16, op: false, er: true, ref: useRef(null), p: 2 },
        { id: 17, op: false, er: true, ref: useRef(null), p: 2 },
        { id: 18, op: false, er: true, ref: useRef(null), p: 2 },
        { id: 19, op: false, er: true, ref: useRef(null), p: 10 },
        { id: 20, op: false, er: true, ref: useRef(null), p: 2 },
        { id: 21, op: false, er: true, ref: useRef(null), p: 12 },
        { id: 22, op: false, er: true, ref: useRef(null), p: 15 },
        { id: 23, op: false, er: true, ref: useRef(null), p: 15 }
    ];
    const [opens, setOpens] = useState(openArray);

    const handleOpens = (id) => {
        setOpens(opens.map(x => (x.id === id ? { ...x, op: !x.op } : { ...x })));
        console.log('handleOpens ', opens)
    };

    const handleError = (id, b) => {
        setOpens(opens.map(x => (x.id === id ? { ...x, er: b } : { ...x })));
    };

    const valuesArray = [
        { // 0
            prof: [],
        }, { // 1
            text_135_1: "",
            text_135_1_2: "",
            text_135_1_c: "",
            text_136_1: "",
            text_136_1_2: "",
            text_136_1_c: "",
            text_138_1: "",
            text_138_1_2: "",
            text_138_1_c: "",
            text_141_1: "",
            text_141_1_2: "",
            text_141_1_c: "",
            text_111_1: "",
            text_111_1_2: "",
            text_111_1_c: "",
            text_121_1: "",
            text_121_1_2: "",
            text_121_1_c: "",
            text_311_1: "",
            text_311_1_2: "",
            text_311_1_c: "",
        }, { // 2
            mshinostroyeniye_2: "",
            mshinostroyeniye_2_2: "",
            mshinostroyeniye_2_3: "",
            mshinostroyeniye_2_3_till: "",
            mshinostroyeniye_nerzhaveyka: [],
            mshinostroyeniye_nerzhaveyka2: "",
            mshinostroyeniye_nerzhaveyka_q: "",
            mshinostroyeniye_alyuminiy: [],
            mshinostroyeniye_alyuminiy2: "",
            mshinostroyeniye_alyuminiy_q: "",
            mshinostroyeniye_stal: [],
            mshinostroyeniye_stal2: "",
            mshinostroyeniye_stal_q: "",
            mshinostroyeniye_titan: [],
            mshinostroyeniye_titan2: "",
            mshinostroyeniye_titan_q: "",
        }, { // 3
            metallokonsturkcii_2: "",
            metallokonsturkcii_2_2: "",
            metallokonsturkcii_2_3: "",
            metallokonsturkcii_2_3_till: "",

            metallokonsturkcii_nerzhaveyka: [],
            metallokonsturkcii_nerzhaveyka2: "",
            metallokonsturkcii_nerzhaveyka_q: "",
            metallokonsturkcii_nerzhaveyka_u: false,
            metallokonsturkcii_nerzhaveyka_r: false,
            metallokonsturkcii_nerzhaveyka_m: false,
            metallokonsturkcii_nerzhaveyka_k: false,

            metallokonsturkcii_alyuminiy: [],
            metallokonsturkcii_alyuminiy2: "",
            metallokonsturkcii_alyuminiy_q: "",
            metallokonsturkcii_alyuminiy_u: false,
            metallokonsturkcii_alyuminiy_r: false,
            metallokonsturkcii_alyuminiy_m: false,
            metallokonsturkcii_alyuminiy_k: false,

            metallokonsturkcii_stal: [],
            metallokonsturkcii_stal2: "",
            metallokonsturkcii_stal_q: "",
            metallokonsturkcii_stal_u: false,
            metallokonsturkcii_stal_r: false,
            metallokonsturkcii_stal_m: false,
            metallokonsturkcii_stal_k: false,

            metallokonsturkcii_hardox: [],
            metallokonsturkcii_hardox2: "",
            metallokonsturkcii_hardox_q: "",
            metallokonsturkcii_hardox_u: false,
            metallokonsturkcii_hardox_r: false,
            metallokonsturkcii_hardox_m: false,
            metallokonsturkcii_hardox_k: false,

            metallokonsturkcii_duplex: [],
            metallokonsturkcii_duplex2: "",
            metallokonsturkcii_duplex_q: "",
            metallokonsturkcii_duplex_u: false,
            metallokonsturkcii_duplex_r: false,
            metallokonsturkcii_duplex_m: false,
            metallokonsturkcii_duplex_k: false,

            metallokonsturkcii_super_duplex: [],
            metallokonsturkcii_super_duplex2: "",
            metallokonsturkcii_super_duplex_q: "",
            metallokonsturkcii_super_duplex_u: false,
            metallokonsturkcii_super_duplex_r: false,
            metallokonsturkcii_super_duplex_m: false,
            metallokonsturkcii_super_duplex_k: false,

            metallokonsturkcii_titan: [],
            metallokonsturkcii_titan2: "",
            metallokonsturkcii_titan_q: "",
            metallokonsturkcii_titan_u: false,
            metallokonsturkcii_titan_r: false,
            metallokonsturkcii_titan_m: false,
            metallokonsturkcii_titan_k: false,

            metallokonsturkcii_chugun: [],
            metallokonsturkcii_chugun2: "",
            metallokonsturkcii_chugun_q: "",
            metallokonsturkcii_chugun_u: false,
            metallokonsturkcii_chugun_r: false,
            metallokonsturkcii_chugun_m: false,
            metallokonsturkcii_chugun_k: false,
        }, { // 4
            truby_povorotnye_2: "",
            truby_povorotnye_2_2: "",
            truby_povorotnye_2_3: "",
            truby_povorotnye_2_3_till: "",
            truby_povorotnye_nerzhaveyka: [],
            truby_povorotnye_nerzhaveyka2: "",
            truby_povorotnye_nerzhaveyka3: false,
            truby_povorotnye_nerzhaveyka4: false,
            truby_povorotnye_nerzhaveyka_q: "",
            truby_povorotnye_alyuminiy: [],
            truby_povorotnye_alyuminiy2: "",
            truby_povorotnye_alyuminiy3: false,
            truby_povorotnye_alyuminiy4: false,
            truby_povorotnye_alyuminiy_q: "",
            truby_povorotnye_stal: [],
            truby_povorotnye_stal2: "",
            truby_povorotnye_stal3: false,
            ttruby_povorotnye_stal4: false,
            truby_povorotnye_stal_q: "",
            truby_povorotnye_hardox: [],
            truby_povorotnye_hardox2: "",
            truby_povorotnye_hardox3: false,
            truby_povorotnye_hardox4: false,
            truby_povorotnye_hardox_q: "",
            truby_povorotnye_duplex: [],
            truby_povorotnye_duplex2: "",
            truby_povorotnye_duplex3: false,
            truby_povorotnye_duplex4: false,
            truby_povorotnye_duplex_q: "",
            truby_povorotnye_super_duplex: [],
            truby_povorotnye_super_duplex2: "",
            truby_povorotnye_super_duplex3: false,
            truby_povorotnye_super_duplex4: false,
            truby_povorotnye_super_duplex_q: "",
            truby_povorotnye_titan: [],
            truby_povorotnye_titan2: "",
            truby_povorotnye_titan_u: false,
            truby_povorotnye_titan_r: false,
            truby_povorotnye_titan_q: "",

            truby_nepovorotnye_2: "",
            truby_nepovorotnye_2_2: "",
            truby_nepovorotnye_2_3: "",
            truby_nepovorotnye_2_3_till: "",
            truby_nepovorotnye_nerzhaveyka: [],
            truby_nepovorotnye_nerzhaveyka2: "",
            truby_nepovorotnye_nerzhaveyka3: false,
            truby_nepovorotnye_nerzhaveyka4: false,
            truby_nepovorotnye_nerzhaveyka_q: "",
            truby_nepovorotnye_alyuminiy: [],
            truby_nepovorotnye_alyuminiy2: "",
            truby_nepovorotnye_alyuminiy3: false,
            truby_nepovorotnye_alyuminiy4: false,
            truby_nepovorotnye_alyuminiy_q: "",
            truby_nepovorotnye_stal: [],
            truby_nepovorotnye_stal2: "",
            truby_nepovorotnye_stal3: false,
            truby_nepovorotnye_stal4: false,
            truby_nepovorotnye_stal_q: "",
            truby_nepovorotnye_hardox: [],
            truby_nepovorotnye_hardox2: "",
            truby_nepovorotnye_hardox3: false,
            truby_nepovorotnye_hardox4: false,
            truby_nepovorotnye_hardox_q: "",
            truby_nepovorotnye_duplex: [],
            truby_nepovorotnye_duplex2: "",
            truby_nepovorotnye_duplex3: false,
            truby_nepovorotnye_duplex4: false,
            truby_nepovorotnye_duplex_q: "",
            truby_nepovorotnye_super_duplex: [],
            truby_nepovorotnye_super_duplex2: "",
            truby_nepovorotnye_super_duplex3: false,
            truby_nepovorotnye_super_duplex4: false,
            truby_nepovorotnye_super_duplex_q: "",
            truby_nepovorotnye_titan: [],
            truby_nepovorotnye_titan2: "",
            truby_nepovorotnye_titan_u: false,
            truby_nepovorotnye_titan_r: false,
            truby_nepovorotnye_titan_q: "",

            truby_profilnyye_2: "",
            truby_profilnyye_2_2: "",
            truby_profilnyye_2_3: "",
            truby_profilnyye_2_3_till: "",
            truby_profilnyye_nerzhaveyka: [],
            truby_profilnyye_nerzhaveyka2: "",
            truby_profilnyye_nerzhaveyka3: false,
            truby_profilnyye_nerzhaveyka4: false,
            truby_profilnyye_nerzhaveyka_q: "",
            truby_profilnyye_alyuminiy: [],
            truby_profilnyye_alyuminiy2: "",
            truby_profilnyye_alyuminiy3: false,
            truby_profilnyye_alyuminiy4: false,
            truby_profilnyye_alyuminiy_q: "",
            truby_profilnyye_stal: [],
            truby_profilnyye_stal2: "",
            truby_profilnyye_stal3: false,
            truby_profilnyye_stal4: false,
            truby_profilnyye_stal_q: "",
            truby_profilnyye_hardox: [],
            truby_profilnyye_hardox2: "",
            truby_profilnyye_hardox3: false,
            truby_profilnyye_hardox4: false,
            truby_profilnyye_hardox_q: "",
            truby_profilnyye_duplex: [],
            truby_profilnyye_duplex2: "",
            truby_profilnyye_duplex3: false,
            truby_profilnyye_duplex4: false,
            truby_profilnyye_duplex_q: "",
            truby_profilnyye_super_duplex: [],
            truby_profilnyye_super_duplex2: "",
            truby_profilnyye_super_duplex3: false,
            truby_profilnyye_super_duplex4: false,
            truby_profilnyye_super_duplex_q: "",
            truby_profilnyye_titan: [],
            truby_profilnyye_titan2: "",
            truby_profilnyye_titan_u: false,
            truby_profilnyye_titan_r: false,
            truby_profilnyye_titan_q: "",

            tig_2: false,
            tig_3: false,
            tig_15: false,
            tig_10_20: false,

            tig_duplex_3_10: false,
            tig_duplex_10_20: false,

            tig_duplex: "",
            tig_s_duplex: "",
            tig_duplex_faulty: "",

        }, { // 5
            bochki_yomkosti_2: "",
            bochki_yomkosti_2_2: "",
            bochki_yomkosti_2_3: "",
            bochki_yomkosti_2_3_till: "",
            tbochki_yomkosti_nerzhaveyka: [],
            tbochki_yomkosti_nerzhaveyka2: "",
            tbochki_yomkosti_nerzhaveyka3: false,
            tbochki_yomkosti_nerzhaveyka4: false,
            tbochki_yomkosti_nerzhaveyka5: false,
            tbochki_yomkosti_nerzhaveyka6: false,
            tbochki_yomkosti_nerzhaveyka_q: "",
            tbochki_yomkosti_alyuminiy: [],
            tbochki_yomkosti_alyuminiy2: "",
            tbochki_yomkosti_alyuminiy3: false,
            tbochki_yomkosti_alyuminiy4: false,
            tbochki_yomkosti_alyuminiy5: false,
            tbochki_yomkosti_alyuminiy6: false,
            tbochki_yomkosti_alyuminiy_q: "",
            tbochki_yomkosti_stal: [],
            tbochki_yomkosti_stal2: "",
            tbochki_yomkosti_stal3: false,
            tbochki_yomkosti_stal4: false,
            tbochki_yomkosti_stal5: false,
            tbochki_yomkosti_stal6: false,
            tbochki_yomkosti_stal_q: "",
            tbochki_yomkosti_hardox: [],
            tbochki_yomkosti_hardox2: "",
            tbochki_yomkosti_hardox3: false,
            tbochki_yomkosti_hardox4: false,
            tbochki_yomkosti_hardox5: false,
            tbochki_yomkosti_hardox6: false,
            tbochki_yomkosti_hardox_q: "",
            tbochki_yomkosti_duplex: [],
            tbochki_yomkosti_duplex2: "",
            tbochki_yomkosti_duplex3: false,
            tbochki_yomkosti_duplex4: false,
            tbochki_yomkosti_duplex5: false,
            tbochki_yomkosti_duplex6: false,
            tbochki_yomkosti_duplex_q: "",
            tbochki_yomkosti_super_duplex: [],
            tbochki_yomkosti_super_duplex2: "",
            tbochki_yomkosti_super_duplex3: false,
            tbochki_yomkosti_super_duplex4: false,
            tbochki_yomkosti_super_duplex5: false,
            tbochki_yomkosti_super_duplex6: false,
            tbochki_yomkosti_super_duplex_q: "",
            tbochki_yomkosti_titan: [],
            tbochki_yomkosti_titan2: "",
            tbochki_yomkosti_titan_u: false,
            tbochki_yomkosti_titan_r: false,
            tbochki_yomkosti_titan_m: false,
            tbochki_yomkosti_titan_k: false,
            tbochki_yomkosti_titan_q: "",
            migmag_duplex: false,
            migmag_duplex_1: false,
        }, { // 6
            svarka_na_manipulyatore_2: "",
            svarka_na_manipulyatore_2_2: "",
            svarka_rotatore_2: "",
            svarka_rotatore_2_2: "",
            svarka_traktorom_2: "",
            svarka_traktorom_2_2: "",
            svarka_traktorom_2_3: "",
            svarka_traktorom_2_3_till: "",
            svarka_traktorom_2_4: ""
        }, { // 7
            svar_apparati: "",
            svar_apparati_other: "",
        }, { // 8
            umeete_ispolzovat_strogach_2: "",
            umeete_ispolzovat_strogach_2_2: "",
            umeete_ispolzovat_strogach_2_3: "",
            umeete_ispolzovat_strogach_2_3_till: ""
        }, { // 9
            nizhnem_polozhenii_5: "",
            nizhnem_polozhenii_5_2: "",
            nizhnem_proc: [],
            potolochnoe_polozhenie_5: "",
            potolochnoe_polozhenie_5_2: "",
            potolochnoe_proc: [],
            vertikalnoe_polozhenie_5_2: "",
            vertikalnoe_polozhenie_5: "",
            vertikalnoe_proc: [],
            gorizontalnoe_polozhenie_5: "",
            gorizontalnoe_polozhenie_5_2: "",
            gorizontalnoe_proc: [],
            pod_gradusov_5: "",
            pod_gradusov_5_2: "",
            pod_gradusov_proc: [],

            pod_gradusov_5_p: "",
            pod_gradusov_5_2_p: "",
            pod_gradusov_proc_p: [],

            pod_truba_5: "",
            pod_truba_5_2: "",
            pod_truba_proc: [],

            detail_pod_5: "",
            detail_pod_5_2: "",
            detail_pod_proc: [],

            max_pressure_5: "",

            svarka_shkale_5: "",
            truby_diametry_tolshiny_5: "",
            truby_diametry_tolshiny_5_2: "",
            truby_diametry_tolshiny_5_2_till: "",
            truby_diametry_tolshiny_5_3: "",
        }, { // 10
            tig_duplex_impulse: false,
            tig_impulse: false,
            shablon: false,
            heating_material: false,
            thermo_pen: false,
            kaskad: false,
            knowledge_setup_w_machine: false,
            knowledge_work_wps: false,
            knowledge_gas: false,
            migmag_angle: false,
            tig_tandem: false,
            migmag_vert_down: false,
            tehnology_nerz: "",
            tehnology_carbon: "",
            knowledge_wire: false,
            knowledge_brands_welding_filler_rods: false,
            knowledge_brands_welding_electrodes: false,
            knowledge_electric_clean: false,
            knowledge_facet_tube: false,
            knowledge_facet_plate: false,
            knowledge_center: false,
            knowledge_tall: false,
            knowledge_facet_bolgarka: false,
            knowledge_gap: false,
            knowledge_repair_defect: false,
        }, { // 11
            sborka_metalokonstrukcij_6_1: "",
            sborka_metalokonstrukcij_6: "",
            sborka_metalokonstrukcij_6_2: "",
            sborka_metalokonstrukcij_6_3: "",
        }, { // 12
            sborka_mashinostroenie_6: "",
            sborka_mashinostroenie_6_1: "",
            sborka_mashinostroenie_6_2: "",
            sborka_mashinostroenie_6_3: "",
        }, { // 13
            sborka_trub_6: "",
            sborka_trub_6_1: "",
            sborka_trub_6_2: "",
            sborka_trub_6_3: ""
        }, { // 14
            sborka_bochek_6: "",
            sborka_bochek_6_1: "",
            sborka_bochek_6_2: "",
            sborka_bochek_6_3: "",
        }, { // 15
            konst_kakie: [],
            konst_kakie_other: "",
        }, { // 16
            sborka_konduktorah_6: "",
            sborka_konduktorah_6_1: "",
            sborka_konduktorah_6_2: "",
            sborka_konduktorah_6_3: "",
        }, { // 17
            sborkavtorym_6_2: "",
        }, { // 18
            opyt_konstrukcij_7: "",
            esli_smesyu_7: "",
        }, { // 19
            svarochnye_chertezhi_8: "",
            svarochnye_chertezhi_8_2: "",
            sborochnye_chertezhi_8: "",
            sborochnye_chertezhi_8_2: "",
            mehanicheskie_chertezhi_8: "",
            mehanicheskie_chertezhi_8_2: "",
            izometricheskie_chertezhi_8: "",
            izometricheskie_chertezhi_8_2: "",
        }, { // 20
            sposobnost_samostoyatelno_10: "",
            stano: [],
            stano_other: "",
            sposobnost_samostoyatelno_zagotovitj: "",
            sposobnost_samostoyatelno_razmetka: "",
        }, { // 21
            instalwork_waist: false,
            instalwork_️winches: false,
            instalwork_screw_jacks: false,
            instalwork_hydraulic_jacks: false,
            instalwork_rack_jacks: false,
            instalwork_lifts: false,
            instalwork_beam_crane: false,
            instalwork_forklifts: false,
            instalwork_turning_large_structures: false,
            instalwork_mount_large_structures: false,
            instalwork_dismantle_large_structures: false,
            instalwork_carryout_slinging_structures: false,
            instalwork_carryout_unslinging_structures: false,
            instalwork_thermal_straightening: false
        }, { // 22
            veronumero_9: "",
            veronumero_9_2: "",
            veronumero_9_3: null,
            henkilotunnus_9: "",
            henkilotunnus_9_2: "",
            henkilotunnus_9_3: null,
            seraya_raboty_9: "",
            seraya_raboty_9_1: null,
            zelenaya_bezopastnosti_9: "",
            zelenaya_bezopastnosti_9_1: null,
            passport: "",
            passport_1: null,
            serdejs: [],
            notserdejs: [],
            voditelskie_prava_9: "",
            voditelskie_prava_9_1: "",
            nalichie_mashiny_9: "",
            kurite_9: "",
            // countries: "",
            angl_uroven_9: "",
            fins_uroven_9: "",
            another_lang: "",
        },
        { // 23
            approved: false,
            company: "",
            companyexp: "",
            companyperson: "",
            company2: "",
            companyexp2: "",
            companyperson2: "",
            company3: "",
            companyexp3: "",
            companyperson3: "",
            company4: "",
            companyexp4: "",
            companyperson4: ""
        }
    ];
    const [values, setValues] = useState(valuesArray);

    useEffect(() => {


        console.log("useEffect PROF ", values[0].prof);
        console.log("useEffect PROF2 ", optionProf);
        let profb = 0;
        for (let arr of values[0].prof) {
            const p = parseInt(arr);
            profb += p;
        }
        console.log("bit mask: ", profb);
        setQMask(profb);

        let r = 0;
        openArray.forEach((element) => {
            r += element.p & profb ? 1 : 0
        })
        console.log("count of question blocks: ", r);
        setQCount(r);

    }, [values[0].prof]);


    const handleChangeQA = (name, value) => {
        console.log("handleChangeQA -> " + name + " : " + value);
        setValues(values.map(x => x.hasOwnProperty(name) ? {
                ...x, [name]: value
            }
            :
            { ...x }));
    };


    // check array not string int
    const handleChangeC = (name, value, val) => {
        console.log("handleChangeC -> " + name);
        console.log("handleChangeC -> value ", value);
        console.log("handleChangeC -> val ", val);
        let value2 = [...value];
        if (value2.length > 0) {
            if (!value2.includes(val)) {
                console.log("add checked");
                value2.push(val);
            } else {
                console.log("delete checked");
                value2 = value2.filter(data => data !== val);
            }
        } else {
            console.log("add checked to empty array");
            value2.push(val);
        }
        console.log("value2 -> " + value2);
        setValues(values.map(x => x.hasOwnProperty(name) ? { ...x, [name]: value2 } : { ...x }));
    };

    const handleChangeM = (event) => {
        console.log("value " + values[0]);
        if (event.target.value !== null && event.target.value !== 'null' && event.target.value !== '') {
            console.log("handleChangeM -> " + event.target.name + " : " + event.target.value);
            const options = event.target.options;
            let value2 = [];
            for (let i = 0, l = options.length; i < l; i++) {
                if (options[i].selected) {
                    value2.push(options[i].value);
                }
            }
            console.log("value2 -> " + value2);
            setValues(values.map(x => x.hasOwnProperty(event.target.name) ? {
                ...x,
                [event.target.name]: value2
            } : { ...x }));
        } else {
            console.log("valueM NULL");
            setValues(values.map(x => x.hasOwnProperty(event.target.name) ? { ...x, [event.target.name]: [] } : { ...x }));
        }
    };

    const handleChangeDate = (n, d) => {
        console.log("handleChangeDate n -> " + n);
        console.log("handleChangeDate d -> " + d);
        setValues(values.map(x => x.hasOwnProperty(n) ? {
            ...x,
            [n]: d
        } : { ...x }));
    };

    const handleChange = (event) => {
        console.log("handleChange -> " + event.target.name + " : " + event.target.value);
        // console.log(values)
        setValues(values.map(x => x.hasOwnProperty(event.target.name) ? {
            ...x,
            [event.target.name]: event.target.value
        } : { ...x }));
    };

    const handleChangeB = (event) => {
        console.log(
            "handleChangeB -> " + event.target.name + " : " + event.target.value // + " : " + i
        );
        setValues(values.map(x => x.hasOwnProperty(event.target.name) ? {
            ...x,
            [event.target.name]: !event.target.value
        } : { ...x }));
    };

    const getAnketa = () => {
        AnketaService.get()
            .then(response => {
                    console.log("<- after get data ", response.data)
                    console.log("<- after get status ", response.status)
                    const DateNameArr = [
                        'veronumero_9_3',
                        'henkilotunnus_9_3',
                        'seraya_raboty_9_1',
                        'zelenaya_bezopastnosti_9_1',
                        'passport_1'
                    ];

                    if (response.status === 200) {
                        console.log("after get 200")
                        let V = values;
                        for (const [key, value] of Object.entries(response.data)) {
                            if (value !== null && key !== 'id' && key !== 'createdAt' && key !== 'updatedAt' && key !== 'userId' && key !== 'rate') {
                                if (DateNameArr.includes(key)) {
                                    V = V.map(x => x.hasOwnProperty(key) ? { ...x, [key]: new Date(value) } : { ...x })
                                } else {
                                    V = V.map(x => x.hasOwnProperty(key) ? { ...x, [key]: value } : { ...x })
                                }
                            }
                        }
                        setValues(V)
                        setIsloading(false);

                        const R = parseInt(response.data.rate);
                        console.log("----R ", R)

                        const Rmask = parseInt(response.data.rateb);
                        console.log("----RB ", Rmask)

                        setQMaskDone(Rmask);

                        console.log("----ratem ", response.data.ratem)
                        setRating(parseFloat(response.data.ratem));

                        let o = opens;

                        let qM = 0;
                        for (let pr of V[0].prof) {
                            const p = parseInt(pr);
                            qM += p;
                        }

                        console.log("----prof mask: ", qM)

                        for (let i = 0; i < opens.length; i++) {
                            console.log("block i: ", i);
                            const q = Math.pow(2, i);
                            if (q & Rmask) {
                                console.log("block m: ", opens[i].p);
                                // console.log("block m2: ", (opens[i].p & qM ));
                                o[i].er = false;
                            }
                        }

                        let found = false;
                        let foundId = null;

                        for (let i = 0; i < opens.length; i++) {
                            //find next
                            if (opens[i].p & qM) {
                                console.log("check block mask ", i);
                                const Q = Math.pow(2, i);
                                console.log("--------  ", Q);
                                if (!(Rmask & Q)) {
                                    console.log("found next !!! ", i);
                                    o[i].op = true;
                                    o[i].er = false;
                                    found = true;
                                    foundId = i;
                                    break;
                                }
                            }
                        }

                        if (found) {
                            console.log("--- open new block .....")
                            setOpens(o);
                            executeScroll(opens[foundId].ref, false);
                        }
                        setR(R + 1);
                    } else if (response.status === 201) {
                        console.log("after get 201 - not found");
                        let o = opens;
                        o[0].op = true;
                        o[0].er = false;
                        setOpens(o);
                        setIsloading(false);
                    } else {
                        setIsloading(false);
                        redirect();
                    }
                },
                error => {
                    console.log("after save 3 - error ", error);
                    if (error.response) {
                        console.log('E data ', error.response.data);
                        console.log('E status ', error.response.status);
                        console.log('E headers ', error.response.headers);
                    }

                    if (error.response && error.response.status === 401) {
                        console.log("need to refresh token");
                        // need to refresh token

                        const user = JSON.parse(localStorage.getItem('user'));
                        if (user && user.refreshToken) {
                            AnketaService.refreshToken(user.refreshToken)
                                .then(function (response) {
                                    if (response.status === 200) {
                                        console.log("<-after t_refresh 200");
                                        if (response.data.accessToken !== undefined && response.data.refreshToken !== undefined) {
                                            console.log('<--- onFailureRefresh OK');
                                            localStorage.setItem("user", JSON.stringify(response.data));
                                            getAnketa()
                                        } else {
                                            console.log("<-after t_refresh 200 2");
                                            setIsloading(false);
                                            redirect();
                                        }
                                    } else if (response.status === 201) {
                                        console.log("<-after t_refresh 201");
                                        setIsloading(false);
                                        redirect();
                                    } else {
                                        console.log("<-after t_refresh else");
                                        setIsloading(false);
                                        redirect();
                                    }
                                })
                                .catch(function (error) {
                                    console.log('<-after t_refresh catch ', error);
                                    setIsloading(false);
                                    redirect();
                                });
                        } else {
                            setIsloading(false);
                            redirect();
                        }
                    } else {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        setAlertM(resMessage);
                        handleShow();
                        setIsloading(false);
                        redirect();
                    }
                }
            );
    }

    useEffect(() => {
        console.log("useEffect anketa get data");
        setIsloading(true);
        getAnketa();
    }, []);

    const handleClose = () => {
        console.log("handleClose ")
        setShow(false);
    }
    const handleShow = () => {
        console.log("handleShow ")
        setShow(true);
    }

    const save = (i, Q) => {
        console.log("save ", i);
        console.log("save rating ", rating)
        let rec = values[i];
        console.log("save rec ", rec)
        console.log('qMaskDone: ', qMaskDone);

        let A = 0;
        let aCount = 0;
        for (const [key, value] of Object.entries(rec)) {
            console.log("--- " + key + " " + value);
            console.log("-------------OK " + key);
            aCount++;
            if (value !== null && value !== '' && value !== 'No info') {
                A++;
            }
        }
        const A2 = A / aCount;
        console.log("block rating: ", A2);
        setRating(rating + A2)

        rec['rate'] = i;
        rec['ratem'] = rating + A2;
        rec['rateb'] = Q;

        setIsloading(true)

        return new Promise((resolve, reject) => {
            AnketaService.save(rec)
                .then(response => {
                        console.log("after save ", response)
                        if (response.status === 200) {
                            console.log("after save 1")
                            resolve("SUCCESS")
                        } else {
                            if (response.status === 201) {
                                console.log("after save 2 - error")
                                setAlertM('something goes wrong, please contact with administrator for additional info');
                                handleShow();
                                reject("FAILURE")
                            }
                        }
                        setIsloading(false)
                    },
                    error => {
                        console.log("after save 3 - error ", error);
                        if (error.response) {
                            console.log('E data ', error.response.data);
                            console.log('E status ', error.response.status);
                            console.log('E headers ', error.response.headers);
                        }

                        if (error.response.status === 401) {
                            console.log("need to refresh token");
                            // need to refresh token
                            const user = JSON.parse(localStorage.getItem('user'));
                            if (user && user.refreshToken) {
                                AnketaService.refreshToken(user.refreshToken)
                                    .then(function (response) {
                                        if (response.data.accessToken !== undefined && response.data.refreshToken !== undefined) {
                                            console.log('<--- onFailureRefresh OK');
                                            localStorage.setItem("user", JSON.stringify(response.data));
                                            save(values[i]);
                                        } else {
                                            console.log('<--- onFailureRefresh');
                                            redirect();
                                        }
                                    })
                                    .catch(function (error) {
                                        console.log('<--- onFailureRefresh catch   - old token ', error);
                                        redirect();
                                    });
                            } else {
                                redirect();
                            }
                        } else {
                            const resMessage =
                                (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                error.message ||
                                error.toString();
                            setAlertM(resMessage);
                            handleShow();
                            reject("FAILURE")
                        }
                        setIsloading(false)
                    }
                );
        })
    }

    const onSubmit = (i) => (event) => {
        if (event) event.preventDefault();
        console.log('onSubmit ', i);

        const Q = qMaskDone | Math.pow(2, i);

        setQMaskDone(Q)
        console.log('qMaskDone: ', Q);

        save(i, Q)
            .then(res => {

                let o = opens;
                o[i].er = false;
                // o[i].op = false;

                for (let oi = 0; oi < opens.length; oi++) {
                    o[oi].op = false;
                }

                if (i !== 23) {
                    console.log('not last')

                    let found = false;
                    let foundId = null;

                    for (let i2 = 0; i2 < opens.length; i2++) {
                        //find next
                        if (opens[i2].p & qMask) {
                            console.log("check block qMaskDone ", Q);
                            console.log("check block mask i2 ", i2);
                            const q = Math.pow(2, i2);
                            // const QMD = qMaskDone | Math.pow(2, i);
                            // console.log("--QMD  ",QMD);
                            console.log("check block mask Q  ", Q);
                            if (!(Q & q)) {
                                console.log("check block mask found next !!! ", i2);
                                found = true;
                                foundId = i2;
                                break;
                            }
                        }
                    }
                    if (found) {

                        o[foundId].op = true;
                        handleError(foundId, false);
                        setOpens(o);
                        if (i > 0) {
                            setR(i)
                        }
                        executeScroll(opens[foundId].ref, true);
                    }




                } else {
                    console.log('not found next elem!!! ')
                    alert('поздравляем, вы заполнили анкету')
                    //     setOpens(o);
                    //     handleOpens(i)
                    //     setR(i)
                }
            })
            .catch(function (error) {
                    //console.log('onSubmit1 err ')
                }
            );
    }

    return (
        <div>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    {alertM}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="fixed-top">
                <div className="d-flex flex-row-reverse bd-highlight">
                    <div style={{ visibility: isloading ? 'visible' : 'hidden' }}>
                        <Spinner
                            as="span"
                            animation="border"
                            variant="success"
                            // size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    </div>
                    {/*ratingValue={100 / (qCount + 1) * countiter}*/}
                    <Rating
                        readonly={true}
                        allowHalfIcon={true}
                        ratingValue={100 / qCount * rating}
                        iconsCount={10}
                        size={30}
                        fillColor='orange'
                        emptyColor='gray'
                    />


                        {/*<Form.Check // prettier-ignore*/}
                        {/*    type="switch"*/}
                        {/*    id="custom-switch"*/}
                        {/*    label="Check this switch"*/}
                        {/*/>*/}




                </div>
            </div>

            <form onSubmit={onSubmit(0)}>
                <Card style={{ padding: '3px' }} ref={opens[0].ref} border={Math.pow(2, 0) & qMaskDone ? 'success' : 'light'}>
                    <Card.Header>
                        <Button disabled={opens[0].er} variant={Math.pow(2, 0) & qMaskDone ? 'success' : 'secondary'}
                                onClick={() => handleOpens(0)}
                                aria-expanded={opens[0].op}>{opens[0].op ? '-' : '+'}</Button>{' '}
                        Специализация
                    </Card.Header>
                    <Card.Body>
                        <Collapse in={opens[0].op}>
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    <Row>
                                        <Col>
                                            <BrowserView>
                                                <h6>Выберите специализацию, можно несколько</h6>
                                                <Check2
                                                    cols={2}
                                                    rows={2}
                                                    option={optionProf}
                                                    name="prof"
                                                    value={values[0].prof}
                                                    change={handleChangeC}
                                                />
                                            </BrowserView>
                                            <MobileView>
                                                <Form.Label title="Выберите один из вариантов.">профессия</Form.Label>
                                                <Form.Select
                                                    name="prof"
                                                    value={values[0].prof}
                                                    onChange={handleChangeM}
                                                    required
                                                    multiple={true}
                                                >
                                                    <Options
                                                        option={optionProf}
                                                    />
                                                </Form.Select>
                                            </MobileView>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <Row>
                                        <Col>
                                            <Button as="input" type="submit" value="Сохранить" />
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            </ListGroup>
                        </Collapse>
                    </Card.Body>
                </Card>
            </form>

            {qMask & 1 ? (
                <form onSubmit={onSubmit(1)}>
                    <Card style={{ padding: '3px' }} ref={opens[1].ref} border={Math.pow(2, 1) & qMaskDone ? 'success' : 'light'}>
                        <Card.Header>
                            <Button disabled={opens[1].er} variant={Math.pow(2, 1) & qMaskDone ? 'success' : 'secondary'}
                                    onClick={() => handleOpens(1)}
                                    aria-expanded={opens[1].op}>{opens[1].op ? '-' : '+'}</Button>{' '}
                            Какими методами сварки владеете?
                        </Card.Header>
                        <Card.Body>
                            <Collapse in={opens[1].op}>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Процесс сварки 135</Form.Label>
                                                <Form.Select
                                                    name="text_135_1"
                                                    value={values[1].text_135_1}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>
                                                </Form.Select>
                                            </Col>
                                            {(values[1].text_135_1 !== 'Нет' && values[1].text_135_1 !== '') && <>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Сколько всего лет опыта (135) процессом?</Form.Label>
                                                    <QA
                                                        rows={2}
                                                        option={radios20}
                                                        name="text_135_1_2"
                                                        value={values[1].text_135_1_2}
                                                        change={handleChangeQA}
                                                        required={values[1].text_111_1 !== 'Нет'}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label>Комментарий</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={3}
                                                        name="text_135_1_c"
                                                        value={values[1].text_135_1_c}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </>
                                            }
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item variant="secondary">
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Процесс сварки 136</Form.Label>
                                                <Form.Select
                                                    name="text_136_1"
                                                    value={values[1].text_136_1}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>

                                                </Form.Select>
                                            </Col>
                                            {(values[1].text_136_1 !== 'Нет' && values[1].text_136_1 !== '') && <>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Сколько всего лет опыта (136) процессом?</Form.Label>
                                                    <QA
                                                        rows={2}
                                                        option={radios20}
                                                        name="text_136_1_2"
                                                        value={values[1].text_136_1_2}
                                                        change={handleChangeQA}
                                                        required={values[1].text_111_1 !== 'Нет'}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label>Комментарий</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={3}
                                                        name="text_136_1_c"
                                                        value={values[1].text_136_1_c}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </>
                                            }
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Процесс сварки 138</Form.Label>
                                                <Form.Select
                                                    name="text_138_1"
                                                    value={values[1].text_138_1}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>

                                                </Form.Select>
                                            </Col>
                                            {(values[1].text_138_1 !== 'Нет' && values[1].text_138_1 !== '') && <>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Сколько всего лет опыта (138) процессом?</Form.Label>
                                                    <QA
                                                        rows={2}
                                                        option={radios20}
                                                        name="text_138_1_2"
                                                        value={values[1].text_138_1_2}
                                                        change={handleChangeQA}
                                                        required={values[1].text_111_1 !== 'Нет'}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label>Комментарий</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={3}
                                                        name="text_138_1_c"
                                                        value={values[1].text_138_1_c}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </>
                                            }
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item variant="secondary">
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Процесс сварки 141</Form.Label>
                                                <Form.Select
                                                    name="text_141_1"
                                                    value={values[1].text_141_1}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>

                                                </Form.Select>
                                            </Col>
                                            {(values[1].text_141_1 !== 'Нет' && values[1].text_141_1 !== '') && <>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Сколько всего лет опыта (141) процессом?</Form.Label>

                                                    <QA
                                                        rows={2}
                                                        option={radios20}
                                                        name="text_141_1_2"
                                                        value={values[1].text_141_1_2}
                                                        change={handleChangeQA}
                                                        required={values[1].text_111_1 !== 'Нет'}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label>Комментарий</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={3}
                                                        name="text_141_1_c"
                                                        value={values[1].text_141_1_c}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </>
                                            }
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Процесс сварки 111</Form.Label>
                                                <Form.Select
                                                    name="text_111_1"
                                                    value={values[1].text_111_1}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>

                                                </Form.Select>
                                            </Col>
                                            {(values[1].text_111_1 !== 'Нет' && values[1].text_111_1 !== '') && <>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Сколько всего лет опыта (111) процессом?</Form.Label>
                                                    <QA
                                                        rows={2}
                                                        option={radios20}
                                                        name="text_111_1_2"
                                                        value={values[1].text_111_1_2}
                                                        change={handleChangeQA}
                                                        required={values[1].text_111_1 !== 'Нет'}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label>Комментарий</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={3}
                                                        name="text_111_1_c"
                                                        value={values[1].text_111_1_c}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </>
                                            }
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item variant="secondary">
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Процесс сварки 121</Form.Label>
                                                <Form.Select
                                                    name="text_121_1"
                                                    value={values[1].text_121_1}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>
                                                </Form.Select>
                                            </Col>
                                            {(values[1].text_121_1 !== 'Нет' && values[1].text_121_1 !== '') && <>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Сколько всего лет опыта (121) процессом?</Form.Label>
                                                    <QA
                                                        rows={2}
                                                        option={radios20}
                                                        name="text_121_1_2"
                                                        value={values[1].text_121_1_2}
                                                        change={handleChangeQA}
                                                        required={values[1].text_121_1 !== 'Нет'}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label>Комментарий</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={3}
                                                        name="text_121_1_c"
                                                        value={values[1].text_121_1_c}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </>
                                            }
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Процесс сварки 311</Form.Label>
                                                <Form.Select
                                                    name="text_311_1"
                                                    value={values[1].text_311_1}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>
                                                </Form.Select>
                                            </Col>
                                            {(values[1].text_311_1 !== 'Нет' && values[1].text_311_1 !== '') && <>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Сколько всего лет опыта (311) процессом?</Form.Label>
                                                    <QA
                                                        rows={2}
                                                        option={radios20}
                                                        name="text_311_1_2"
                                                        value={values[1].text_311_1_2}
                                                        change={handleChangeQA}
                                                        required={values[1].text_311_1 !== 'Нет'}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label>Комментарий</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={3}
                                                        name="text_311_1_c"
                                                        value={values[1].text_311_1_c}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </>
                                            }
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Button as="input" type="submit" value="Сохранить" />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Collapse>
                        </Card.Body>
                    </Card>
                </form>) : (<></>)
            }

            {qMask & 1 ? (
                <form onSubmit={onSubmit(2)}>
                    <Card style={{ padding: '3px' }} ref={opens[2].ref} border={Math.pow(2, 2) & qMaskDone ? 'success' : 'light'}>
                        <Card.Header>
                            <Button disabled={opens[2].er} variant={Math.pow(2, 2) & qMaskDone ? 'success' : 'secondary'}
                                    onClick={() => handleOpens(2)}
                                    aria-expanded={opens[2].op}>{opens[2].op ? '-' : '+'}</Button>{' '}
                            Машиностроение
                        </Card.Header>
                        <Card.Body>
                            <Collapse in={opens[2].op}>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Form.Label
                                                    title="Выберите один из вариантов.">Машиностроение</Form.Label>
                                                <Form.Select
                                                    name="mshinostroyeniye_2"
                                                    value={values[2].mshinostroyeniye_2}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>
                                                </Form.Select>
                                            </Col>
                                            {(values[2].mshinostroyeniye_2 !== 'Нет' && values[2].mshinostroyeniye_2 !== '') && <>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Сколько всего лет опыта? Примерно</Form.Label>
                                                    <QA
                                                        rows={2}
                                                        option={radios20}
                                                        name="mshinostroyeniye_2_2"
                                                        value={values[2].mshinostroyeniye_2_2}
                                                        change={handleChangeQA}
                                                        required={values[2].mshinostroyeniye_2 !== 'Нет'}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Толщины (мм)</Form.Label>
                                                    <InputGroup className="mb-3">
                                                        <Form.Control
                                                            placeholder="мин"
                                                            type="number"
                                                            name="mshinostroyeniye_2_3"
                                                            value={values[2].mshinostroyeniye_2_3}
                                                            onChange={handleChange}
                                                            required={values[2].mshinostroyeniye_2 !== 'Нет'}
                                                        />
                                                        <Form.Control
                                                            placeholder="макс"
                                                            type="number"
                                                            name="mshinostroyeniye_2_3_till"
                                                            value={values[2].mshinostroyeniye_2_3_till}
                                                            onChange={handleChange}
                                                            required={values[2].mshinostroyeniye_2 !== 'Нет'}
                                                        />
                                                    </InputGroup>
                                                </Col>
                                            </>
                                            }
                                        </Row>
                                    </ListGroup.Item>
                                    {(values[2].mshinostroyeniye_2 !== 'Нет' && values[2].mshinostroyeniye_2 !== '') && <>
                                        <ListGroup.Item variant="secondary">
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Hержавейка - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="mshinostroyeniye_nerzhaveyka"
                                                            value={values[2].mshinostroyeniye_nerzhaveyka}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Hержавейка - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="mshinostroyeniye_nerzhaveyka"
                                                            value={values[2].mshinostroyeniye_nerzhaveyka}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[2].mshinostroyeniye_nerzhaveyka.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Hержавейка</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="mshinostroyeniye_nerzhaveyka_q"
                                                            value={values[2].mshinostroyeniye_nerzhaveyka_q}
                                                            change={handleChangeQA}
                                                            required={values[2].mshinostroyeniye_nerzhaveyka !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label
                                                            title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="mshinostroyeniye_nerzhaveyka2"
                                                            value={values[2].mshinostroyeniye_nerzhaveyka2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Алюминий - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="mshinostroyeniye_alyuminiy"
                                                            value={values[2].mshinostroyeniye_alyuminiy}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Алюминий - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="mshinostroyeniye_alyuminiy"
                                                            value={values[2].mshinostroyeniye_alyuminiy}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[2].mshinostroyeniye_alyuminiy.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Алюминий</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="mshinostroyeniye_alyuminiy_q"
                                                            value={values[2].mshinostroyeniye_alyuminiy_q}
                                                            change={handleChangeQA}
                                                            required={values[2].mshinostroyeniye_alyuminiy !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="mshinostroyeniye_alyuminiy2"
                                                            value={values[2].mshinostroyeniye_alyuminiy2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item variant="secondary">
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Углеродистая сталь - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="mshinostroyeniye_stal"
                                                            value={values[2].mshinostroyeniye_stal}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Углеродистая сталь - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="mshinostroyeniye_stal"
                                                            value={values[2].mshinostroyeniye_stal}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[2].mshinostroyeniye_stal.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Углеродистая сталь</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="mshinostroyeniye_stal_q"
                                                            value={values[2].mshinostroyeniye_stal_q}
                                                            change={handleChangeQA}
                                                            required={values[2].mshinostroyeniye_stal !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="mshinostroyeniye_stal2"
                                                            value={values[2].mshinostroyeniye_stal2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Титан - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="mshinostroyeniye_titan"
                                                            value={values[2].mshinostroyeniye_titan}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Титан - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="mshinostroyeniye_titan"
                                                            value={values[2].mshinostroyeniye_titan}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[2].mshinostroyeniye_titan.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Титан</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="mshinostroyeniye_titan_q"
                                                            value={values[2].mshinostroyeniye_titan_q}
                                                            change={handleChangeQA}
                                                            required={values[2].mshinostroyeniye_titan !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="mshinostroyeniye_titan2"
                                                            value={values[2].mshinostroyeniye_titan2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                    </>
                                    }
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Button as="input" type="submit" value="Сохранить" />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Collapse>
                        </Card.Body>
                    </Card>
                </form>) : (<></>)
            }

            {qMask & 1 ? (
                <form onSubmit={onSubmit(3)}>
                    <Card style={{ padding: '3px' }} ref={opens[3].ref} border={Math.pow(2, 3) & qMaskDone ? 'success' : 'light'}>
                        <Card.Header>
                            <Button disabled={opens[3].er} variant={Math.pow(2, 3) & qMaskDone ? 'success' : 'secondary'}
                                    onClick={() => handleOpens(3)}
                                    aria-expanded={opens[3].op}>{opens[3].op ? '-' : '+'}</Button>{' '}
                            Металлоконструкции
                        </Card.Header>
                        <Card.Body>
                            <Collapse in={opens[3].op}>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Form.Label
                                                    title="Выберите один из вариантов.">Металлоконструкции</Form.Label>
                                                <Form.Select
                                                    name="metallokonsturkcii_2"
                                                    value={values[3].metallokonsturkcii_2}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>
                                                </Form.Select>
                                            </Col>
                                            {(values[3].metallokonsturkcii_2 !== 'Нет' && values[3].metallokonsturkcii_2 !== '') && <>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Сколько всего лет опыта? Примерно</Form.Label>
                                                    <QA
                                                        rows={2}
                                                        option={radios20}
                                                        name="metallokonsturkcii_2_2"
                                                        value={values[3].metallokonsturkcii_2_2}
                                                        change={handleChangeQA}
                                                        required={values[3].metallokonsturkcii_2 !== 'Нет'}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Толщины (мм)</Form.Label>
                                                    <InputGroup className="mb-3">
                                                        <Form.Control
                                                            placeholder="мин"
                                                            type="number"
                                                            name="metallokonsturkcii_2_3"
                                                            value={values[3].metallokonsturkcii_2_3}
                                                            onChange={handleChange}
                                                            required={values[3].metallokonsturkcii_2 !== 'Нет'}
                                                        />
                                                        <Form.Control
                                                            placeholder="макс"
                                                            type="number"
                                                            name="metallokonsturkcii_2_3_till"
                                                            value={values[3].metallokonsturkcii_2_3_till}
                                                            onChange={handleChange}
                                                            required={values[3].metallokonsturkcii_2 !== 'Нет'}
                                                        />
                                                    </InputGroup>
                                                </Col>
                                            </>
                                            }
                                        </Row>
                                    </ListGroup.Item>
                                    {(values[3].metallokonsturkcii_2 !== 'Нет' && values[3].metallokonsturkcii_2 !== '') && <>
                                        <ListGroup.Item variant="secondary">
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Hержавейка - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="metallokonsturkcii_nerzhaveyka"
                                                            value={values[3].metallokonsturkcii_nerzhaveyka}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Hержавейка - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="metallokonsturkcii_nerzhaveyka"
                                                            value={values[3].metallokonsturkcii_nerzhaveyka}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[3].metallokonsturkcii_nerzhaveyka.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Под Контроль?</Form.Label>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="metallokonsturkcii_nerzhaveyka_u"
                                                            value={values[3].metallokonsturkcii_nerzhaveyka_u}
                                                            onChange={handleChangeB}
                                                            label='УЗК'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="metallokonsturkcii_nerzhaveyka_r"
                                                            value={values[3].metallokonsturkcii_nerzhaveyka_r}
                                                            onChange={handleChangeB}
                                                            label='Рентген'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="metallokonsturkcii_nerzhaveyka_m"
                                                            value={values[3].metallokonsturkcii_nerzhaveyka_m}
                                                            onChange={handleChangeB}
                                                            label='Магнитный'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="metallokonsturkcii_nerzhaveyka_k"
                                                            value={values[3].metallokonsturkcii_nerzhaveyka_k}
                                                            onChange={handleChangeB}
                                                            label='Капиллярный (пенетрантами)'
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Hержавейка</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="metallokonsturkcii_nerzhaveyka_q"
                                                            value={values[3].metallokonsturkcii_nerzhaveyka_q}
                                                            change={handleChangeQA}
                                                            required={values[3].metallokonsturkcii_nerzhaveyka !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="metallokonsturkcii_nerzhaveyka2"
                                                            value={values[3].metallokonsturkcii_nerzhaveyka2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Алюминий - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="metallokonsturkcii_alyuminiy"
                                                            value={values[3].metallokonsturkcii_alyuminiy}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Алюминий - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="metallokonsturkcii_alyuminiy"
                                                            value={values[3].metallokonsturkcii_alyuminiy}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[3].metallokonsturkcii_alyuminiy.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Под Контроль?</Form.Label>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="metallokonsturkcii_alyuminiy_u"
                                                            value={values[3].metallokonsturkcii_alyuminiy_u}
                                                            onChange={handleChangeB}
                                                            label='УЗК '
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="metallokonsturkcii_alyuminiy_r"
                                                            value={values[3].metallokonsturkcii_alyuminiy_r}
                                                            onChange={handleChangeB}
                                                            label='Рентген '
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="metallokonsturkcii_alyuminiy_m"
                                                            value={values[3].metallokonsturkcii_alyuminiy_m}
                                                            onChange={handleChangeB}
                                                            label='Магнитный '
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="metallokonsturkcii_alyuminiy_k"
                                                            value={values[3].metallokonsturkcii_alyuminiy_k}
                                                            onChange={handleChangeB}
                                                            label='Капиллярный (пенетрантами)'
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Алюминий</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="metallokonsturkcii_alyuminiy_q"
                                                            value={values[3].metallokonsturkcii_alyuminiy_q}
                                                            change={handleChangeQA}
                                                            required={values[3].metallokonsturkcii_alyuminiy !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="metallokonsturkcii_alyuminiy2"
                                                            value={values[3].metallokonsturkcii_alyuminiy2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item variant="secondary">
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Углеродистая сталь - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="metallokonsturkcii_stal"
                                                            value={values[3].metallokonsturkcii_stal}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Углеродистая сталь - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="metallokonsturkcii_stal"
                                                            value={values[3].metallokonsturkcii_stal}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[3].metallokonsturkcii_stal.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Под Контроль?</Form.Label>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="metallokonsturkcii_stal_u"
                                                            value={values[3].metallokonsturkcii_stal_u}
                                                            onChange={handleChangeB}
                                                            label='УЗК'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="metallokonsturkcii_stal_r"
                                                            value={values[3].metallokonsturkcii_stal_r}
                                                            onChange={handleChangeB}
                                                            label='Рентген'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="metallokonsturkcii_stal_m"
                                                            value={values[3].metallokonsturkcii_stal_m}
                                                            onChange={handleChangeB}
                                                            label='Магнитный '
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="metallokonsturkcii_stal_k"
                                                            value={values[3].metallokonsturkcii_stal_k}
                                                            onChange={handleChangeB}
                                                            label='Капиллярный (пенетрантами) '
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Углеродистая сталь</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="metallokonsturkcii_stal_q"
                                                            value={values[3].metallokonsturkcii_stal_q}
                                                            change={handleChangeQA}
                                                            required={values[3].metallokonsturkcii_stal !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="metallokonsturkcii_stal2"
                                                            value={values[3].metallokonsturkcii_stal2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Hardox - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="metallokonsturkcii_hardox"
                                                            value={values[3].metallokonsturkcii_hardox}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Hardox - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="metallokonsturkcii_hardox"
                                                            value={values[3].metallokonsturkcii_hardox}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[3].metallokonsturkcii_hardox.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Под Контроль?</Form.Label>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="metallokonsturkcii_hardox_u"
                                                            value={values[3].metallokonsturkcii_hardox_u}
                                                            onChange={handleChangeB}
                                                            label='УЗК '
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="metallokonsturkcii_hardox_r"
                                                            value={values[3].metallokonsturkcii_hardox_r}
                                                            onChange={handleChangeB}
                                                            label='Рентген '
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="metallokonsturkcii_hardox_m"
                                                            value={values[3].metallokonsturkcii_hardox_m}
                                                            onChange={handleChangeB}
                                                            label='Магнитный '
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="metallokonsturkcii_hardox_k"
                                                            value={values[3].metallokonsturkcii_hardox_k}
                                                            onChange={handleChangeB}
                                                            label='Капиллярный (пенетрантами) '
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Hardox</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="metallokonsturkcii_hardox_q"
                                                            value={values[3].metallokonsturkcii_hardox_q}
                                                            change={handleChangeQA}
                                                            required={values[3].metallokonsturkcii_hardox !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="metallokonsturkcii_hardox2"
                                                            value={values[3].metallokonsturkcii_hardox2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item variant="secondary">
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Duplex - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="metallokonsturkcii_duplex"
                                                            value={values[3].metallokonsturkcii_duplex}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Duplex - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="metallokonsturkcii_duplex"
                                                            value={values[3].metallokonsturkcii_duplex}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[3].metallokonsturkcii_duplex.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Под Контроль?</Form.Label>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="metallokonsturkcii_duplex_u"
                                                            value={values[3].metallokonsturkcii_duplex_u}
                                                            onChange={handleChangeB}
                                                            label='УЗК '
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="metallokonsturkcii_duplex_r"
                                                            value={values[3].metallokonsturkcii_duplex_r}
                                                            onChange={handleChangeB}
                                                            label='Рентген '
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="metallokonsturkcii_duplex_m"
                                                            value={values[3].metallokonsturkcii_duplex_m}
                                                            onChange={handleChangeB}
                                                            label='Магнитный '
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="metallokonsturkcii_duplex_k"
                                                            value={values[3].metallokonsturkcii_duplex_k}
                                                            onChange={handleChangeB}
                                                            label='Капиллярный (пенетрантами) '
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Duplex</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="metallokonsturkcii_duplex_q"
                                                            value={values[3].metallokonsturkcii_duplex_q}
                                                            change={handleChangeQA}
                                                            required={values[3].metallokonsturkcii_duplex !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="metallokonsturkcii_duplex2"
                                                            value={values[3].metallokonsturkcii_duplex2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Super Duplex - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="metallokonsturkcii_super_duplex"
                                                            value={values[3].metallokonsturkcii_super_duplex}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Super Duplex - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="metallokonsturkcii_super_duplex"
                                                            value={values[3].metallokonsturkcii_super_duplex}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[3].metallokonsturkcii_super_duplex.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Под Контроль?</Form.Label>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="metallokonsturkcii_super_duplex_u"
                                                            value={values[3].metallokonsturkcii_super_duplex_u}
                                                            onChange={handleChangeB}
                                                            label='УЗК '
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="metallokonsturkcii_super_duplex_r"
                                                            value={values[3].metallokonsturkcii_super_duplex_r}
                                                            onChange={handleChangeB}
                                                            label='Рентген '
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="metallokonsturkcii_super_duplex_m"
                                                            value={values[3].metallokonsturkcii_super_duplex_m}
                                                            onChange={handleChangeB}
                                                            label='Магнитный '
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="metallokonsturkcii_super_duplex_k"
                                                            value={values[3].metallokonsturkcii_super_duplex_k}
                                                            onChange={handleChangeB}
                                                            label='Капиллярный (пенетрантами) '
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Super Duplex</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="metallokonsturkcii_super_duplex_q"
                                                            value={values[3].metallokonsturkcii_super_duplex_q}
                                                            change={handleChangeQA}
                                                            required={values[3].metallokonsturkcii_super_duplex !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="metallokonsturkcii_super_duplex2"
                                                            value={values[3].metallokonsturkcii_super_duplex2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item variant="secondary">
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Титан - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="metallokonsturkcii_titan"
                                                            value={values[3].metallokonsturkcii_titan}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Титан - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="metallokonsturkcii_titan"
                                                            value={values[3].metallokonsturkcii_titan}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[3].metallokonsturkcii_titan.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Под Контроль?</Form.Label>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="metallokonsturkcii_titan_u"
                                                            value={values[3].metallokonsturkcii_titan_u}
                                                            onChange={handleChangeB}
                                                            label='УЗК '
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="metallokonsturkcii_titan_r"
                                                            value={values[3].metallokonsturkcii_titan_r}
                                                            onChange={handleChangeB}
                                                            label='Рентген '
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="metallokonsturkcii_titan_m"
                                                            value={values[3].metallokonsturkcii_titan_m}
                                                            onChange={handleChangeB}
                                                            label='Магнитный '
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="metallokonsturkcii_titan_k"
                                                            value={values[3].metallokonsturkcii_titan_k}
                                                            onChange={handleChangeB}
                                                            label='Капиллярный (пенетрантами) '
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Титан</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="metallokonsturkcii_titan_q"
                                                            value={values[3].metallokonsturkcii_titan_q}
                                                            change={handleChangeQA}
                                                            required={values[3].metallokonsturkcii_titan !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="metallokonsturkcii_titan2"
                                                            value={values[3].metallokonsturkcii_titan2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Чугун - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="metallokonsturkcii_chugun"
                                                            value={values[3].metallokonsturkcii_chugun}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Чугун - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="metallokonsturkcii_chugun"
                                                            value={values[3].metallokonsturkcii_chugun}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[3].metallokonsturkcii_chugun.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Под Контроль?</Form.Label>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="metallokonsturkcii_chugun_u"
                                                            value={values[3].metallokonsturkcii_chugun_u}
                                                            onChange={handleChangeB}
                                                            label='УЗК '
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="metallokonsturkcii_chugun_r"
                                                            value={values[3].metallokonsturkcii_chugun_r}
                                                            onChange={handleChangeB}
                                                            label='Рентген '
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="metallokonsturkcii_chugun_m"
                                                            value={values[3].metallokonsturkcii_chugun_m}
                                                            onChange={handleChangeB}
                                                            label='Магнитный '
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="metallokonsturkcii_chugun_k"
                                                            value={values[3].metallokonsturkcii_chugun_k}
                                                            onChange={handleChangeB}
                                                            label='Капиллярный (пенетрантами) '
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Чугун</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="metallokonsturkcii_chugun_q"
                                                            value={values[3].metallokonsturkcii_chugun_q}
                                                            change={handleChangeQA}
                                                            required={values[3].metallokonsturkcii_chugun !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="metallokonsturkcii_chugun2"
                                                            value={values[3].metallokonsturkcii_chugun2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                    </>
                                    }
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Button as="input" type="submit" value="Сохранить" />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Collapse>
                        </Card.Body>
                    </Card>
                </form>) : (<></>)
            }

            {qMask & 1 ? (
                <form onSubmit={onSubmit(4)}>
                    <Card style={{ padding: '3px' }} ref={opens[4].ref} border={Math.pow(2, 4) & qMaskDone ? 'success' : 'light'}>
                        <Card.Header>
                            <Button disabled={opens[4].er} variant={Math.pow(2, 4) & qMaskDone ? 'success' : 'secondary'}
                                    onClick={() => handleOpens(4)}
                                    aria-expanded={opens[4].op}>{opens[4].op ? '-' : '+'}</Button>{' '}
                            Трубы
                        </Card.Header>
                        <Card.Body>
                            <Collapse in={opens[4].op}>
                                <ListGroup variant="flush">
                                    <ListGroup.Item variant="dark">
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Трубы поворотные</Form.Label>
                                                <Form.Select
                                                    name="truby_povorotnye_2"
                                                    value={values[4].truby_povorotnye_2}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>
                                                </Form.Select>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>

                                    {(values[4].truby_povorotnye_2 !== 'Нет' && values[4].truby_povorotnye_2 !== '') && <>
                                        <ListGroup.Item variant="secondary">
                                            <Row>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Сколько всего лет опыта? Примерно</Form.Label>
                                                    <QA
                                                        rows={2}
                                                        option={radios20}
                                                        name="truby_povorotnye_2_2"
                                                        value={values[4].truby_povorotnye_2_2}
                                                        change={handleChangeQA}
                                                        required={values[4].truby_povorotnye_2 !== 'Нет'}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Толщины (мм)</Form.Label>
                                                    <InputGroup className="mb-3">
                                                        <Form.Control
                                                            placeholder="мин"
                                                            type="number"
                                                            name="truby_povorotnye_2_3"
                                                            value={values[4].truby_povorotnye_2_3}
                                                            onChange={handleChange}
                                                            required={values[4].truby_povorotnye_2 !== 'Нет'}
                                                        />
                                                        <Form.Control
                                                            placeholder="макс"
                                                            type="number"
                                                            name="truby_povorotnye_2_3_till"
                                                            value={values[4].truby_povorotnye_2_3_till}
                                                            onChange={handleChange}
                                                            required={values[4].truby_povorotnye_2 !== 'Нет'}
                                                        />
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Hержавейка - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="truby_povorotnye_nerzhaveyka"
                                                            value={values[4].truby_povorotnye_nerzhaveyka}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Hержавейка - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="truby_povorotnye_nerzhaveyka"
                                                            value={values[4].truby_povorotnye_nerzhaveyka}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[4].truby_povorotnye_nerzhaveyka.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Под Контроль?</Form.Label>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_povorotnye_nerzhaveyka3"
                                                            value={values[4].truby_povorotnye_nerzhaveyka3}
                                                            onChange={handleChangeB}
                                                            label='УЗК'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_povorotnye_nerzhaveyka4"
                                                            value={values[4].truby_povorotnye_nerzhaveyka4}
                                                            onChange={handleChangeB}
                                                            label='Рентген'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_povorotnye_nerzhaveyka5"
                                                            value={values[4].truby_povorotnye_nerzhaveyka5}
                                                            onChange={handleChangeB}
                                                            label='Магнитный '
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_povorotnye_nerzhaveyka6"
                                                            value={values[4].truby_povorotnye_nerzhaveyka6}
                                                            onChange={handleChangeB}
                                                            label='Капиллярный (пенетрантами) '
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Hержавейка</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="truby_povorotnye_nerzhaveyka_q"
                                                            value={values[4].truby_povorotnye_nerzhaveyka_q}
                                                            change={handleChangeQA}
                                                            required={values[4].truby_povorotnye_nerzhaveyka !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label
                                                            title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="truby_povorotnye_nerzhaveyka2"
                                                            value={values[4].truby_povorotnye_nerzhaveyka2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item variant="secondary">
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Алюминий - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="truby_povorotnye_alyuminiy"
                                                            value={values[4].truby_povorotnye_alyuminiy}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Алюминий - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="truby_povorotnye_alyuminiy"
                                                            value={values[4].truby_povorotnye_alyuminiy}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[4].truby_povorotnye_alyuminiy.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Под Контроль?</Form.Label>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_povorotnye_alyuminiy3"
                                                            value={values[4].truby_povorotnye_alyuminiy3}
                                                            onChange={handleChangeB}
                                                            label='УЗК'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_povorotnye_alyuminiy4"
                                                            value={values[4].truby_povorotnye_alyuminiy4}
                                                            onChange={handleChangeB}
                                                            label='Рентген'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_povorotnye_alyuminiy5"
                                                            value={values[4].truby_povorotnye_alyuminiy5}
                                                            onChange={handleChangeB}
                                                            label='Магнитный '
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_povorotnye_alyuminiy6"
                                                            value={values[4].truby_povorotnye_alyuminiy6}
                                                            onChange={handleChangeB}
                                                            label='Капиллярный (пенетрантами) '
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Алюминий</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="truby_povorotnye_alyuminiy_q"
                                                            value={values[4].truby_povorotnye_alyuminiy_q}
                                                            change={handleChangeQA}
                                                            required={values[4].truby_povorotnye_alyuminiy !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label
                                                            title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="truby_povorotnye_alyuminiy2"
                                                            value={values[4].truby_povorotnye_alyuminiy2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Углеродистая сталь - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="truby_povorotnye_stal"
                                                            value={values[4].truby_povorotnye_stal}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Углеродистая сталь - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="truby_povorotnye_stal"
                                                            value={values[4].truby_povorotnye_stal}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[4].truby_povorotnye_stal.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Под Контроль?</Form.Label>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_povorotnye_stal3"
                                                            value={values[4].truby_povorotnye_stal3}
                                                            onChange={handleChangeB}
                                                            label='УЗК'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="ttruby_povorotnye_stal4"
                                                            value={values[4].ttruby_povorotnye_stal4}
                                                            onChange={handleChangeB}
                                                            label='Рентген'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="ttruby_povorotnye_stal5"
                                                            value={values[4].ttruby_povorotnye_stal5}
                                                            onChange={handleChangeB}
                                                            label='Магнитный '
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="ttruby_povorotnye_stal6"
                                                            value={values[4].ttruby_povorotnye_stal6}
                                                            onChange={handleChangeB}
                                                            label='Капиллярный (пенетрантами) '
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Углеродистая сталь</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="truby_povorotnye_stal_q"
                                                            value={values[4].truby_povorotnye_stal_q}
                                                            change={handleChangeQA}
                                                            required={values[4].truby_povorotnye_stal !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label
                                                            title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="truby_povorotnye_stal2"
                                                            value={values[4].truby_povorotnye_stal2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item variant="secondary">
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Hardox - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="truby_povorotnye_hardox"
                                                            value={values[4].truby_povorotnye_hardox}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Hardox - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="truby_povorotnye_hardox"
                                                            value={values[4].truby_povorotnye_hardox}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[4].truby_povorotnye_hardox.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Под Контроль?</Form.Label>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_povorotnye_hardox3"
                                                            value={values[4].truby_povorotnye_hardox3}
                                                            onChange={handleChangeB}
                                                            label='УЗК'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_povorotnye_hardox4"
                                                            value={values[4].truby_povorotnye_hardox4}
                                                            onChange={handleChangeB}
                                                            label='Рентген'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_povorotnye_hardox5"
                                                            value={values[4].truby_povorotnye_hardox5}
                                                            onChange={handleChangeB}
                                                            label='Магнитный'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_povorotnye_hardox6"
                                                            value={values[4].truby_povorotnye_hardox6}
                                                            onChange={handleChangeB}
                                                            label='Капиллярный (пенетрантами)'
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Hardox</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="truby_povorotnye_hardox_q"
                                                            value={values[4].truby_povorotnye_hardox_q}
                                                            change={handleChangeQA}
                                                            required={values[4].truby_povorotnye_hardox !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label
                                                            title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="truby_povorotnye_hardox2"
                                                            value={values[4].truby_povorotnye_hardox2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Duplex - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="truby_povorotnye_duplex"
                                                            value={values[4].truby_povorotnye_duplex}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Duplex - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="truby_povorotnye_duplex"
                                                            value={values[4].truby_povorotnye_duplex}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[4].truby_povorotnye_duplex.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Под Контроль?</Form.Label>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_povorotnye_duplex3"
                                                            value={values[4].truby_povorotnye_duplex3}
                                                            onChange={handleChangeB}
                                                            label='УЗК'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_povorotnye_duplex4"
                                                            value={values[4].truby_povorotnye_duplex4}
                                                            onChange={handleChangeB}
                                                            label='Рентген'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_povorotnye_duplex5"
                                                            value={values[4].truby_povorotnye_duplex5}
                                                            onChange={handleChangeB}
                                                            label='Магнитный'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_povorotnye_duplex6"
                                                            value={values[4].truby_povorotnye_duplex6}
                                                            onChange={handleChangeB}
                                                            label='Капиллярный (пенетрантами)'
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Duplex</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="truby_povorotnye_duplex_q"
                                                            value={values[4].truby_povorotnye_duplex_q}
                                                            change={handleChangeQA}
                                                            required={values[4].truby_povorotnye_duplex !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label
                                                            title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="truby_povorotnye_duplex2"
                                                            value={values[4].truby_povorotnye_duplex2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item variant="secondary">
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Super Duplex - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="truby_povorotnye_super_duplex"
                                                            value={values[4].truby_povorotnye_super_duplex}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Super Duplex - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="truby_povorotnye_super_duplex"
                                                            value={values[4].truby_povorotnye_super_duplex}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[4].truby_povorotnye_super_duplex.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Под Контроль?</Form.Label>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_povorotnye_super_duplex3"
                                                            value={values[4].truby_povorotnye_super_duplex3}
                                                            onChange={handleChangeB}
                                                            label='УЗК'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_povorotnye_super_duplex4"
                                                            value={values[4].truby_povorotnye_super_duplex4}
                                                            onChange={handleChangeB}
                                                            label='Рентген'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_povorotnye_super_duplex5"
                                                            value={values[4].truby_povorotnye_super_duplex5}
                                                            onChange={handleChangeB}
                                                            label='Магнитный'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_povorotnye_super_duplex6"
                                                            value={values[4].truby_povorotnye_super_duplex6}
                                                            onChange={handleChangeB}
                                                            label='Капиллярный (пенетрантами)'
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Super Duplex</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="truby_povorotnye_super_duplex_q"
                                                            value={values[4].truby_povorotnye_super_duplex_q}
                                                            change={handleChangeQA}
                                                            required={values[4].truby_povorotnye_super_duplex !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label
                                                            title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="truby_povorotnye_super_duplex2"
                                                            value={values[4].truby_povorotnye_super_duplex2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Титан - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="truby_povorotnye_titan"
                                                            value={values[4].truby_povorotnye_titan}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Титан - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="truby_povorotnye_titan"
                                                            value={values[4].truby_povorotnye_titan}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[4].truby_povorotnye_titan.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Под Контроль?</Form.Label>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_povorotnye_titan_u"
                                                            value={values[4].truby_povorotnye_titan_u}
                                                            onChange={handleChangeB}
                                                            label='УЗК'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_povorotnye_titan_r"
                                                            value={values[4].truby_povorotnye_titan_r}
                                                            onChange={handleChangeB}
                                                            label='Рентген'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_povorotnye_titan_m"
                                                            value={values[4].truby_povorotnye_titan_m}
                                                            onChange={handleChangeB}
                                                            label='Магнитный'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_povorotnye_titan_k"
                                                            value={values[4].truby_povorotnye_titan_k}
                                                            onChange={handleChangeB}
                                                            label='Капиллярный (пенетрантами)'
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Титан</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="truby_povorotnye_titan_q"
                                                            value={values[4].truby_povorotnye_titan_q}
                                                            change={handleChangeQA}
                                                            required={values[4].truby_povorotnye_titan !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="truby_povorotnye_titan2"
                                                            value={values[4].truby_povorotnye_titan2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                    </>
                                    }
                                    <ListGroup.Item variant="dark">
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Трубы неповоротные</Form.Label>
                                                <Form.Select
                                                    name="truby_nepovorotnye_2"
                                                    value={values[4].truby_nepovorotnye_2}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>
                                                </Form.Select>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    {(values[4].truby_nepovorotnye_2 !== 'Нет' && values[4].truby_nepovorotnye_2 !== '') && <>
                                        <ListGroup.Item>
                                            <Row>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Сколько всего лет опыта? Примерно</Form.Label>
                                                    <QA
                                                        rows={2}
                                                        option={radios20}
                                                        name="truby_nepovorotnye_2_2"
                                                        value={values[4].truby_nepovorotnye_2_2}
                                                        change={handleChangeQA}
                                                        required={values[4].truby_nepovorotnye_2 !== 'Нет'}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Толщины (мм)</Form.Label>
                                                    <InputGroup className="mb-3">
                                                        <Form.Control
                                                            placeholder="мин"
                                                            type="number"
                                                            name="truby_nepovorotnye_2_3"
                                                            value={values[4].truby_nepovorotnye_2_3}
                                                            onChange={handleChange}
                                                            required={values[4].truby_nepovorotnye_2 !== 'Нет'}
                                                        />
                                                        <Form.Control
                                                            placeholder="макс"
                                                            type="number"
                                                            name="truby_nepovorotnye_2_3_till"
                                                            value={values[4].truby_nepovorotnye_2_3_till}
                                                            onChange={handleChange}
                                                            required={values[4].truby_nepovorotnye_2 !== 'Нет'}
                                                        />
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item variant="secondary">
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Hержавейка - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="truby_nepovorotnye_nerzhaveyka"
                                                            value={values[4].truby_nepovorotnye_nerzhaveyka}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Hержавейка - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="truby_nepovorotnye_nerzhaveyka"
                                                            value={values[4].truby_nepovorotnye_nerzhaveyka}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[4].truby_nepovorotnye_nerzhaveyka.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Под Контроль?</Form.Label>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_nepovorotnye_nerzhaveyka3"
                                                            value={values[4].truby_nepovorotnye_nerzhaveyka3}
                                                            onChange={handleChangeB}
                                                            label='УЗК'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_nepovorotnye_nerzhaveyka4"
                                                            value={values[4].truby_nepovorotnye_nerzhaveyka4}
                                                            onChange={handleChangeB}
                                                            label='Рентген'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_nepovorotnye_nerzhaveyka5"
                                                            value={values[4].truby_nepovorotnye_nerzhaveyka5}
                                                            onChange={handleChangeB}
                                                            label='Магнитный'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_nepovorotnye_nerzhaveyka6"
                                                            value={values[4].truby_nepovorotnye_nerzhaveyka6}
                                                            onChange={handleChangeB}
                                                            label='Капиллярный (пенетрантами)'
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Hержавейка</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="truby_nepovorotnye_nerzhaveyka_q"
                                                            value={values[4].truby_nepovorotnye_nerzhaveyka_q}
                                                            change={handleChangeQA}
                                                            required={values[4].truby_nepovorotnye_nerzhaveyka !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label
                                                            title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="truby_nepovorotnye_nerzhaveyka2"
                                                            value={values[4].truby_nepovorotnye_nerzhaveyka2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Алюминий - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="truby_nepovorotnye_alyuminiy"
                                                            value={values[4].truby_nepovorotnye_alyuminiy}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Алюминий - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="truby_nepovorotnye_alyuminiy"
                                                            value={values[4].truby_nepovorotnye_alyuminiy}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[4].truby_nepovorotnye_alyuminiy.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Под Контроль?</Form.Label>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_nepovorotnye_alyuminiy3"
                                                            value={values[4].truby_nepovorotnye_alyuminiy3}
                                                            onChange={handleChangeB}
                                                            label='УЗК'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_nepovorotnye_alyuminiy4"
                                                            value={values[4].truby_nepovorotnye_alyuminiy4}
                                                            onChange={handleChangeB}
                                                            label='Рентген'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_nepovorotnye_alyuminiy5"
                                                            value={values[4].truby_nepovorotnye_alyuminiy5}
                                                            onChange={handleChangeB}
                                                            label='Магнитный'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_nepovorotnye_alyuminiy6"
                                                            value={values[4].truby_nepovorotnye_alyuminiy6}
                                                            onChange={handleChangeB}
                                                            label='Капиллярный (пенетрантами)'
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Алюминий</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="truby_nepovorotnye_alyuminiy_q"
                                                            value={values[4].truby_nepovorotnye_alyuminiy_q}
                                                            change={handleChangeQA}
                                                            required={values[4].truby_nepovorotnye_alyuminiy !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label
                                                            title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="truby_nepovorotnye_alyuminiy2"
                                                            value={values[4].truby_nepovorotnye_alyuminiy2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item variant="secondary">
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Углеродистая сталь - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="truby_nepovorotnye_stal"
                                                            value={values[4].truby_nepovorotnye_stal}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Углеродистая сталь - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="truby_nepovorotnye_stal"
                                                            value={values[4].truby_nepovorotnye_stal}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[4].truby_nepovorotnye_stal.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Под Контроль?</Form.Label>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_nepovorotnye_stal3"
                                                            value={values[4].truby_nepovorotnye_stal3}
                                                            onChange={handleChangeB}
                                                            label='УЗК'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_nepovorotnye_stal4"
                                                            value={values[4].truby_nepovorotnye_stal4}
                                                            onChange={handleChangeB}
                                                            label='Рентген'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_nepovorotnye_stal5"
                                                            value={values[4].truby_nepovorotnye_stal5}
                                                            onChange={handleChangeB}
                                                            label='Магнитный'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_nepovorotnye_stal6"
                                                            value={values[4].truby_nepovorotnye_stal6}
                                                            onChange={handleChangeB}
                                                            label='Капиллярный (пенетрантами)'
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Углеродистая сталь</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="truby_nepovorotnye_stal_q"
                                                            value={values[4].truby_nepovorotnye_stal_q}
                                                            change={handleChangeQA}
                                                            required={values[4].truby_nepovorotnye_stal !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label
                                                            title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="truby_nepovorotnye_stal2"
                                                            value={values[4].truby_nepovorotnye_stal2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Hardox - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="truby_nepovorotnye_hardox"
                                                            value={values[4].truby_nepovorotnye_hardox}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Hardox - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="truby_nepovorotnye_hardox"
                                                            value={values[4].truby_nepovorotnye_hardox}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[4].truby_nepovorotnye_hardox.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Под Контроль?</Form.Label>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_nepovorotnye_hardox3"
                                                            value={values[4].truby_nepovorotnye_hardox3}
                                                            onChange={handleChangeB}
                                                            label='УЗК'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_nepovorotnye_hardox4"
                                                            value={values[4].truby_nepovorotnye_hardox4}
                                                            onChange={handleChangeB}
                                                            label='Рентген'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_nepovorotnye_hardox5"
                                                            value={values[4].truby_nepovorotnye_hardox5}
                                                            onChange={handleChangeB}
                                                            label='Магнитный'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_nepovorotnye_hardox6"
                                                            value={values[4].truby_nepovorotnye_hardox6}
                                                            onChange={handleChangeB}
                                                            label='Капиллярный (пенетрантами)'
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Hardox</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="truby_nepovorotnye_hardox_q"
                                                            value={values[4].truby_nepovorotnye_hardox_q}
                                                            change={handleChangeQA}
                                                            required={values[4].truby_nepovorotnye_hardox !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label
                                                            title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="truby_nepovorotnye_hardox2"
                                                            value={values[4].truby_nepovorotnye_hardox2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item variant="secondary">
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Duplex - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="truby_nepovorotnye_duplex"
                                                            value={values[4].truby_nepovorotnye_duplex}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Duplex - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="truby_nepovorotnye_duplex"
                                                            value={values[4].truby_nepovorotnye_duplex}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[4].truby_nepovorotnye_duplex.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Под Контроль?</Form.Label>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_nepovorotnye_duplex3"
                                                            value={values[4].truby_nepovorotnye_duplex3}
                                                            onChange={handleChangeB}
                                                            label='УЗК'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_nepovorotnye_duplex4"
                                                            value={values[4].truby_nepovorotnye_duplex4}
                                                            onChange={handleChangeB}
                                                            label='Рентген'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_nepovorotnye_duplex5"
                                                            value={values[4].truby_nepovorotnye_duplex5}
                                                            onChange={handleChangeB}
                                                            label='Магнитный'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_nepovorotnye_duplex6"
                                                            value={values[4].truby_nepovorotnye_duplex6}
                                                            onChange={handleChangeB}
                                                            label='Капиллярный (пенетрантами)'
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Duplex</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="truby_nepovorotnye_duplex_q"
                                                            value={values[4].truby_nepovorotnye_duplex_q}
                                                            change={handleChangeQA}
                                                            required={values[4].truby_nepovorotnye_duplex !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label
                                                            title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="truby_nepovorotnye_duplex2"
                                                            value={values[4].truby_nepovorotnye_duplex2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Super Duplex - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="truby_nepovorotnye_super_duplex"
                                                            value={values[4].truby_nepovorotnye_super_duplex}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Super Duplex - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="truby_nepovorotnye_super_duplex"
                                                            value={values[4].truby_nepovorotnye_super_duplex}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[4].truby_nepovorotnye_super_duplex.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Под Контроль?</Form.Label>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_nepovorotnye_super_duplex3"
                                                            value={values[4].truby_nepovorotnye_super_duplex3}
                                                            onChange={handleChangeB}
                                                            label='УЗК'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_nepovorotnye_super_duplex4"
                                                            value={values[4].truby_nepovorotnye_super_duplex4}
                                                            onChange={handleChangeB}
                                                            label='Рентген'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_nepovorotnye_super_duplex5"
                                                            value={values[4].truby_nepovorotnye_super_duplex5}
                                                            onChange={handleChangeB}
                                                            label='Магнитный'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_nepovorotnye_super_duplex6"
                                                            value={values[4].truby_nepovorotnye_super_duplex6}
                                                            onChange={handleChangeB}
                                                            label='Капиллярный (пенетрантами)'
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Super Duplex</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="truby_nepovorotnye_super_duplex_q"
                                                            value={values[4].truby_nepovorotnye_super_duplex_q}
                                                            change={handleChangeQA}
                                                            required={values[4].truby_nepovorotnye_super_duplex !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label
                                                            title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="truby_nepovorotnye_super_duplex2"
                                                            value={values[4].truby_nepovorotnye_super_duplex2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item variant="secondary">
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Титан - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="truby_nepovorotnye_titan"
                                                            value={values[4].truby_nepovorotnye_titan}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Титан - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="truby_nepovorotnye_titan"
                                                            value={values[4].truby_nepovorotnye_titan}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[4].truby_nepovorotnye_titan.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Под Контроль?</Form.Label>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_nepovorotnye_titan_u"
                                                            value={values[4].truby_nepovorotnye_titan_u}
                                                            onChange={handleChangeB}
                                                            label='УЗК'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_nepovorotnye_titan_r"
                                                            value={values[4].truby_nepovorotnye_titan_r}
                                                            onChange={handleChangeB}
                                                            label='Рентген'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_nepovorotnye_titan_m"
                                                            value={values[4].truby_nepovorotnye_titan_m}
                                                            onChange={handleChangeB}
                                                            label='Магнитный'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_nepovorotnye_titan_k"
                                                            value={values[4].truby_nepovorotnye_titan_k}
                                                            onChange={handleChangeB}
                                                            label='Капиллярный (пенетрантами)'
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Титан</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="truby_nepovorotnye_titan_q"
                                                            value={values[4].truby_nepovorotnye_titan_q}
                                                            change={handleChangeQA}
                                                            required={values[4].truby_nepovorotnye_titan !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="truby_nepovorotnye_titan2"
                                                            value={values[4].truby_nepovorotnye_titan2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                    </>
                                    }
                                    <ListGroup.Item variant="dark">
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Трубы профильные</Form.Label>
                                                <Form.Select
                                                    name="truby_profilnyye_2"
                                                    value={values[4].truby_profilnyye_2}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>
                                                </Form.Select>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    {(values[4].truby_profilnyye_2 !== 'Нет' && values[4].truby_profilnyye_2 !== '') && <>
                                        <ListGroup.Item variant="secondary">
                                            <Row>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Сколько всего лет опыта? Примерно</Form.Label>
                                                    <QA
                                                        rows={2}
                                                        option={radios20}
                                                        name="truby_profilnyye_2_2"
                                                        value={values[4].truby_profilnyye_2_2}
                                                        change={handleChangeQA}
                                                        required={values[4].truby_profilnyye_2 !== 'Нет'}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Толщины (мм)</Form.Label>
                                                    <InputGroup className="mb-3">
                                                        <Form.Control
                                                            placeholder="мин"
                                                            type="number"
                                                            name="truby_profilnyye_2_3"
                                                            value={values[4].truby_profilnyye_2_3}
                                                            onChange={handleChange}
                                                            required={values[4].truby_profilnyye_2 !== 'Нет'}
                                                        />
                                                        <Form.Control
                                                            placeholder="макс"
                                                            type="number"
                                                            name="truby_profilnyye_2_3_till"
                                                            value={values[4].truby_profilnyye_2_3_till}
                                                            onChange={handleChange}
                                                            required={values[4].truby_profilnyye_2 !== 'Нет'}
                                                        />
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Hержавейка - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="truby_profilnyye_nerzhaveyka"
                                                            value={values[4].truby_profilnyye_nerzhaveyka}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Hержавейка - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="truby_profilnyye_nerzhaveyka"
                                                            value={values[4].truby_profilnyye_nerzhaveyka}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[4].truby_profilnyye_nerzhaveyka.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Под Контроль?</Form.Label>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_profilnyye_nerzhaveyka3"
                                                            value={values[4].truby_profilnyye_nerzhaveyka3}
                                                            onChange={handleChangeB}
                                                            label='УЗК'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_profilnyye_nerzhaveyka4"
                                                            value={values[4].truby_profilnyye_nerzhaveyka4}
                                                            onChange={handleChangeB}
                                                            label='Рентген'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_profilnyye_nerzhaveyka5"
                                                            value={values[4].truby_profilnyye_nerzhaveyka5}
                                                            onChange={handleChangeB}
                                                            label='Магнитный'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_profilnyye_nerzhaveyka6"
                                                            value={values[4].truby_profilnyye_nerzhaveyka6}
                                                            onChange={handleChangeB}
                                                            label='Капиллярный (пенетрантами)'
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Hержавейка</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="truby_profilnyye_nerzhaveyka_q"
                                                            value={values[4].truby_profilnyye_nerzhaveyka_q}
                                                            change={handleChangeQA}
                                                            required={values[4].truby_profilnyye_nerzhaveyka !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label
                                                            title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="truby_profilnyye_nerzhaveyka2"
                                                            value={values[4].truby_profilnyye_nerzhaveyka2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item variant="secondary">
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Алюминий - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="truby_profilnyye_alyuminiy"
                                                            value={values[4].truby_profilnyye_alyuminiy}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Алюминий - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="truby_profilnyye_alyuminiy"
                                                            value={values[4].truby_profilnyye_alyuminiy}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[4].truby_profilnyye_alyuminiy.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Под Контроль?</Form.Label>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_profilnyye_alyuminiy3"
                                                            value={values[4].truby_profilnyye_alyuminiy3}
                                                            onChange={handleChangeB}
                                                            label='УЗК'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_profilnyye_alyuminiy4"
                                                            value={values[4].truby_profilnyye_alyuminiy4}
                                                            onChange={handleChangeB}
                                                            label='Рентген'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_profilnyye_alyuminiy5"
                                                            value={values[4].truby_profilnyye_alyuminiy5}
                                                            onChange={handleChangeB}
                                                            label='Магнитный'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_profilnyye_alyuminiy6"
                                                            value={values[4].truby_profilnyye_alyuminiy6}
                                                            onChange={handleChangeB}
                                                            label='Капиллярный (пенетрантами)'
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Алюминий</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="truby_profilnyye_alyuminiy_q"
                                                            value={values[4].truby_profilnyye_alyuminiy_q}
                                                            change={handleChangeQA}
                                                            required={values[4].truby_profilnyye_alyuminiy !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label
                                                            title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="truby_profilnyye_alyuminiy2"
                                                            value={values[4].truby_profilnyye_alyuminiy2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Углеродистая сталь - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="truby_profilnyye_stal"
                                                            value={values[4].truby_profilnyye_stal}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Углеродистая сталь - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="truby_profilnyye_stal"
                                                            value={values[4].truby_profilnyye_stal}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[4].truby_profilnyye_stal.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Под Контроль?</Form.Label>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_profilnyye_stal3"
                                                            value={values[4].truby_profilnyye_stal3}
                                                            onChange={handleChangeB}
                                                            label='УЗК'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_profilnyye_stal4"
                                                            value={values[4].truby_profilnyye_stal4}
                                                            onChange={handleChangeB}
                                                            label='Рентген'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_profilnyye_stal5"
                                                            value={values[4].truby_profilnyye_stal5}
                                                            onChange={handleChangeB}
                                                            label='Магнитный'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_profilnyye_stal6"
                                                            value={values[4].truby_profilnyye_stal6}
                                                            onChange={handleChangeB}
                                                            label='Капиллярный (пенетрантами)'
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Углеродистая сталь</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="truby_profilnyye_stal_q"
                                                            value={values[4].truby_profilnyye_stal_q}
                                                            change={handleChangeQA}
                                                            required={values[4].truby_profilnyye_stal !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label
                                                            title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="truby_profilnyye_stal2"
                                                            value={values[4].truby_profilnyye_stal2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item variant="secondary">
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Hardox - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="truby_profilnyye_hardox"
                                                            value={values[4].truby_profilnyye_hardox}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Hardox - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="truby_profilnyye_hardox"
                                                            value={values[4].truby_profilnyye_hardox}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[4].truby_profilnyye_hardox.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Под Контроль?</Form.Label>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_profilnyye_hardox3"
                                                            value={values[4].truby_profilnyye_hardox3}
                                                            onChange={handleChangeB}
                                                            label='УЗК'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_profilnyye_hardox4"
                                                            value={values[4].truby_profilnyye_hardox4}
                                                            onChange={handleChangeB}
                                                            label='Рентген'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_profilnyye_hardox5"
                                                            value={values[4].truby_profilnyye_hardox5}
                                                            onChange={handleChangeB}
                                                            label='Магнитный'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_profilnyye_hardox6"
                                                            value={values[4].truby_profilnyye_hardox6}
                                                            onChange={handleChangeB}
                                                            label='Капиллярный (пенетрантами)'
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Hardox</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="truby_profilnyye_hardox_q"
                                                            value={values[4].truby_profilnyye_hardox_q}
                                                            change={handleChangeQA}
                                                            required={values[4].truby_profilnyye_hardox !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label
                                                            title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="truby_profilnyye_hardox2"
                                                            value={values[4].truby_profilnyye_hardox2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Duplex - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="truby_profilnyye_duplex"
                                                            value={values[4].truby_profilnyye_duplex}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Duplex - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="truby_profilnyye_duplex"
                                                            value={values[4].truby_profilnyye_duplex}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[4].truby_profilnyye_duplex.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Под Контроль?</Form.Label>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_profilnyye_duplex3"
                                                            value={values[4].truby_profilnyye_duplex3}
                                                            onChange={handleChangeB}
                                                            label='УЗК'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_profilnyye_duplex4"
                                                            value={values[4].truby_profilnyye_duplex4}
                                                            onChange={handleChangeB}
                                                            label='Рентген'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_profilnyye_duplex5"
                                                            value={values[4].truby_profilnyye_duplex5}
                                                            onChange={handleChangeB}
                                                            label='Магнитный'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_profilnyye_duplex6"
                                                            value={values[4].truby_profilnyye_duplex6}
                                                            onChange={handleChangeB}
                                                            label='Капиллярный (пенетрантами)'
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Duplex</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="truby_profilnyye_duplex_q"
                                                            value={values[4].truby_profilnyye_duplex_q}
                                                            change={handleChangeQA}
                                                            required={values[4].truby_profilnyye_duplex !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label
                                                            title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="truby_profilnyye_duplex2"
                                                            value={values[4].truby_profilnyye_duplex2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item variant="secondary">
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Super Duplex - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="truby_profilnyye_super_duplex"
                                                            value={values[4].truby_profilnyye_super_duplex}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Super Duplex - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="truby_profilnyye_super_duplex"
                                                            value={values[4].truby_profilnyye_super_duplex}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[4].truby_profilnyye_super_duplex.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Под Контроль?</Form.Label>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_profilnyye_super_duplex3"
                                                            value={values[4].truby_profilnyye_super_duplex3}
                                                            onChange={handleChangeB}
                                                            label='УЗК'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_profilnyye_super_duplex4"
                                                            value={values[4].truby_profilnyye_super_duplex4}
                                                            onChange={handleChangeB}
                                                            label='Рентген'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_profilnyye_super_duplex5"
                                                            value={values[4].truby_profilnyye_super_duplex5}
                                                            onChange={handleChangeB}
                                                            label='Магнитный'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_profilnyye_super_duplex6"
                                                            value={values[4].truby_profilnyye_super_duplex6}
                                                            onChange={handleChangeB}
                                                            label='Капиллярный (пенетрантами)'
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Super Duplex</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="truby_profilnyye_super_duplex_q"
                                                            value={values[4].truby_profilnyye_super_duplex_q}
                                                            change={handleChangeQA}
                                                            required={values[4].truby_profilnyye_super_duplex !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="truby_profilnyye_super_duplex2"
                                                            value={values[4].truby_profilnyye_super_duplex2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Титан - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="truby_profilnyye_titan"
                                                            value={values[4].truby_profilnyye_titan}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Титан - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="truby_profilnyye_titan"
                                                            value={values[4].truby_profilnyye_titan}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[4].truby_profilnyye_titan.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Под Контроль?</Form.Label>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_profilnyye_titan_u"
                                                            value={values[4].truby_profilnyye_titan_u}
                                                            onChange={handleChangeB}
                                                            label='УЗК'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_profilnyye_titan_r"
                                                            value={values[4].truby_profilnyye_titan_r}
                                                            onChange={handleChangeB}
                                                            label='Рентген'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_profilnyye_titan_m"
                                                            value={values[4].truby_profilnyye_titan_m}
                                                            onChange={handleChangeB}
                                                            label='Магнитный'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="truby_profilnyye_titan_k"
                                                            value={values[4].truby_profilnyye_titan_k}
                                                            onChange={handleChangeB}
                                                            label='Капиллярный (пенетрантами)'
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Титан</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="truby_profilnyye_titan_q"
                                                            value={values[4].truby_profilnyye_titan_q}
                                                            change={handleChangeQA}
                                                            required={values[4].truby_profilnyye_titan !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label
                                                            title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="truby_profilnyye_titan2"
                                                            value={values[4].truby_profilnyye_titan2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                    </>
                                    }
                                    <ListGroup.Item variant="secondary">
                                        <h5>Сварка труб из нержавеющей стали под Рентген:</h5>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="tig_2"
                                                    value={values[4].tig_2}
                                                    onChange={handleChangeB}
                                                    label='2мм в один проход с присадочной проволокой'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="tig_3"
                                                    value={values[4].tig_3}
                                                    onChange={handleChangeB}
                                                    label='3мм в один проход с присадочной проволокой'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="tig_15"
                                                    value={values[4].tig_15}
                                                    onChange={handleChangeB}
                                                    label='1.5мм без присадочной проволоки'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="tig_10_20"
                                                    value={values[4].tig_10_20}
                                                    onChange={handleChangeB}
                                                    label='10-20мм толщина стенки'
                                                />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <h5>Сварка труб Duplex и SuperDuplex под Рентген:</h5>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="tig_duplex_3_10"
                                                    value={values[4].tig_duplex_3_10}
                                                    onChange={handleChangeB}
                                                    label='с толщиной стенки от 3-10мм'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="tig_duplex_10_20"
                                                    value={values[4].tig_duplex_10_20}
                                                    onChange={handleChangeB}
                                                    label='с толщиной стенки от 10-20мм'
                                                />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Трубы: диаметры</Form.Label>
                                                <Form.Select
                                                    name="truby_diametry_tolshiny_5"
                                                    value={values[8].truby_diametry_tolshiny_5}
                                                    onChange={handleChange}
                                                >
                                                    <option value=""></option>
                                                    <option value="No info">No info</option>
                                                    <option value="16-200">16-200</option>
                                                    <option value="200-600">200-600</option>
                                                    <option value="600-1200">600-1200</option>
                                                </Form.Select>
                                            </Col>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Толщины (мм)</Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Form.Control
                                                        placeholder="мин"
                                                        type="number"
                                                        name="truby_diametry_tolshiny_5_2"
                                                        value={values[8].truby_diametry_tolshiny_5_2}
                                                        onChange={handleChange}
                                                    />
                                                    <Form.Control
                                                        placeholder="макс"
                                                        type="number"
                                                        name="truby_diametry_tolshiny_5_2_till"
                                                        value={values[8].truby_diametry_tolshiny_5_2_till}
                                                        onChange={handleChange}
                                                    />
                                                </InputGroup>
                                            </Col>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Оценка качества</Form.Label>
                                                <QA
                                                    rows={1}
                                                    option={radios10}
                                                    name="truby_diametry_tolshiny_5_3"
                                                    value={values[8].truby_diametry_tolshiny_5_3}
                                                    change={handleChangeQA}
                                                />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item variant="secondary">
                                        <Row>
                                            <Col>
                                                <Form.Label>Сварка труб Duplex. Сколько труб сваривали Рентген?</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="tig_duplex"
                                                    value={values[4].tig_duplex}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Сварка труб SuperDuplex. Сколько труб сваривали Рентген?</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="tig_s_duplex"
                                                    value={values[4].tig_s_duplex}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Какой был процент брака труб? Примерно</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="tig_duplex_faulty"
                                                    value={values[4].tig_duplex_faulty}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Button as="input" type="submit" value="Сохранить" />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Collapse>
                        </Card.Body>
                    </Card>
                </form>) : (<></>)
            }

            {qMask & 1 ? (
                <form onSubmit={onSubmit(5)}>
                    <Card style={{ padding: '3px' }} ref={opens[5].ref} border={Math.pow(2, 5) & qMaskDone ? 'success' : 'light'}>
                        <Card.Header>
                            <Button disabled={opens[5].er} variant={Math.pow(2, 5) & qMaskDone ? 'success' : 'secondary'}
                                    onClick={() => handleOpens(5)}
                                    aria-expanded={opens[5].op}>{opens[5].op ? '-' : '+'}</Button>{' '}
                            Бочки, ёмкости
                        </Card.Header>
                        <Card.Body>
                            <Collapse in={opens[5].op}>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Бочки, ёмкости</Form.Label>
                                                <Form.Select
                                                    name="bochki_yomkosti_2"
                                                    value={values[5].bochki_yomkosti_2}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>
                                                </Form.Select>
                                            </Col>
                                            {(values[5].bochki_yomkosti_2 !== 'Нет' && values[5].bochki_yomkosti_2 !== '') && <>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Сколько всего лет опыта? Примерно</Form.Label>
                                                    <QA
                                                        rows={2}
                                                        option={radios20}
                                                        name="bochki_yomkosti_2_2"
                                                        value={values[5].bochki_yomkosti_2_2}
                                                        change={handleChangeQA}
                                                        required={values[5].bochki_yomkosti_2 !== 'Нет'}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Толщины (мм)</Form.Label>
                                                    <InputGroup className="mb-3">
                                                        <Form.Control
                                                            placeholder="мин"
                                                            type="number"
                                                            name="bochki_yomkosti_2_3"
                                                            value={values[5].bochki_yomkosti_2_3}
                                                            onChange={handleChange}
                                                            required={values[5].bochki_yomkosti_2 !== 'Нет'}
                                                        />
                                                        <Form.Control
                                                            placeholder="макс"
                                                            type="number"
                                                            name="bochki_yomkosti_2_3_till"
                                                            value={values[5].bochki_yomkosti_2_3_till}
                                                            onChange={handleChange}
                                                            required={values[5].bochki_yomkosti_2 !== 'Нет'}
                                                        />
                                                    </InputGroup>
                                                </Col>
                                            </>
                                            }
                                        </Row>
                                    </ListGroup.Item>
                                    {(values[5].bochki_yomkosti_2 !== 'Нет' && values[5].bochki_yomkosti_2 !== '') && <>
                                        <ListGroup.Item variant="secondary">
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Hержавейка - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="tbochki_yomkosti_nerzhaveyka"
                                                            value={values[5].tbochki_yomkosti_nerzhaveyka}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Hержавейка - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="tbochki_yomkosti_nerzhaveyka"
                                                            value={values[5].tbochki_yomkosti_nerzhaveyka}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[5].tbochki_yomkosti_nerzhaveyka.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Под Контроль?</Form.Label>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="tbochki_yomkosti_nerzhaveyka3"
                                                            value={values[5].tbochki_yomkosti_nerzhaveyka3}
                                                            onChange={handleChangeB}
                                                            label='УЗК'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="tbochki_yomkosti_nerzhaveyka4"
                                                            value={values[5].tbochki_yomkosti_nerzhaveyka4}
                                                            onChange={handleChangeB}
                                                            label='Рентген'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="tbochki_yomkosti_nerzhaveyka5"
                                                            value={values[5].tbochki_yomkosti_nerzhaveyka5}
                                                            onChange={handleChangeB}
                                                            label='Магнитный'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="tbochki_yomkosti_nerzhaveyka6"
                                                            value={values[5].tbochki_yomkosti_nerzhaveyka6}
                                                            onChange={handleChangeB}
                                                            label='Капиллярный (пенетрантами)'
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Hержавейка</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="tbochki_yomkosti_nerzhaveyka_q"
                                                            value={values[5].tbochki_yomkosti_nerzhaveyka_q}
                                                            change={handleChangeQA}
                                                            required={values[5].tbochki_yomkosti_nerzhaveyka !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="tbochki_yomkosti_nerzhaveyka2"
                                                            value={values[5].tbochki_yomkosti_nerzhaveyka2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Алюминий - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="tbochki_yomkosti_alyuminiy"
                                                            value={values[5].tbochki_yomkosti_alyuminiy}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Алюминий - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="tbochki_yomkosti_alyuminiy"
                                                            value={values[5].tbochki_yomkosti_alyuminiy}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[5].tbochki_yomkosti_alyuminiy.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Под Контроль?</Form.Label>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="tbochki_yomkosti_alyuminiy3"
                                                            value={values[5].tbochki_yomkosti_alyuminiy3}
                                                            onChange={handleChangeB}
                                                            label='УЗК'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="tbochki_yomkosti_alyuminiy4"
                                                            value={values[5].tbochki_yomkosti_alyuminiy4}
                                                            onChange={handleChangeB}
                                                            label='Рентген'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="tbochki_yomkosti_alyuminiy5"
                                                            value={values[5].tbochki_yomkosti_alyuminiy5}
                                                            onChange={handleChangeB}
                                                            label='Магнитный'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="tbochki_yomkosti_alyuminiy6"
                                                            value={values[5].tbochki_yomkosti_alyuminiy6}
                                                            onChange={handleChangeB}
                                                            label='Капиллярный (пенетрантами)'
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Алюминий</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="tbochki_yomkosti_alyuminiy_q"
                                                            value={values[5].tbochki_yomkosti_alyuminiy_q}
                                                            change={handleChangeQA}
                                                            required={values[5].tbochki_yomkosti_alyuminiy !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="tbochki_yomkosti_alyuminiy2"
                                                            value={values[5].tbochki_yomkosti_alyuminiy2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item variant="secondary">
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Углеродистая сталь - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="tbochki_yomkosti_stal"
                                                            value={values[5].tbochki_yomkosti_stal}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Углеродистая сталь - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="tbochki_yomkosti_stal"
                                                            value={values[5].tbochki_yomkosti_stal}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[5].tbochki_yomkosti_stal.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Под Контроль?</Form.Label>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="tbochki_yomkosti_stal3"
                                                            value={values[5].tbochki_yomkosti_stal3}
                                                            onChange={handleChangeB}
                                                            label='УЗК'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="tbochki_yomkosti_stal4"
                                                            value={values[5].tbochki_yomkosti_stal4}
                                                            onChange={handleChangeB}
                                                            label='Рентген'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="tbochki_yomkosti_stal5"
                                                            value={values[5].tbochki_yomkosti_stal5}
                                                            onChange={handleChangeB}
                                                            label='Магнитный'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="tbochki_yomkosti_stal6"
                                                            value={values[5].tbochki_yomkosti_stal6}
                                                            onChange={handleChangeB}
                                                            label='Капиллярный (пенетрантами)'
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Углеродистая сталь</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="tbochki_yomkosti_stal_q"
                                                            value={values[5].tbochki_yomkosti_stal_q}
                                                            change={handleChangeQA}
                                                            required={values[5].tbochki_yomkosti_stal !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="tbochki_yomkosti_stal2"
                                                            value={values[5].tbochki_yomkosti_stal2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Hardox - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="tbochki_yomkosti_hardox"
                                                            value={values[5].tbochki_yomkosti_hardox}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Hardox - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="tbochki_yomkosti_hardox"
                                                            value={values[5].tbochki_yomkosti_hardox}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[5].tbochki_yomkosti_hardox.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Под Контроль?</Form.Label>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="tbochki_yomkosti_hardox3"
                                                            value={values[5].tbochki_yomkosti_hardox3}
                                                            onChange={handleChangeB}
                                                            label='УЗК'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="tbochki_yomkosti_hardox4"
                                                            value={values[5].tbochki_yomkosti_hardox4}
                                                            onChange={handleChangeB}
                                                            label='Рентген'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="tbochki_yomkosti_hardox5"
                                                            value={values[5].tbochki_yomkosti_hardox5}
                                                            onChange={handleChangeB}
                                                            label='Магнитный'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="tbochki_yomkosti_hardox6"
                                                            value={values[5].tbochki_yomkosti_hardox6}
                                                            onChange={handleChangeB}
                                                            label='Капиллярный (пенетрантами)'
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Hardox</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="tbochki_yomkosti_hardox_q"
                                                            value={values[5].tbochki_yomkosti_hardox_q}
                                                            change={handleChangeQA}
                                                            required={values[5].tbochki_yomkosti_hardox !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="tbochki_yomkosti_hardox2"
                                                            value={values[5].tbochki_yomkosti_hardox2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item variant="secondary">
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Duplex - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="tbochki_yomkosti_duplex"
                                                            value={values[5].tbochki_yomkosti_duplex}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Duplex - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="tbochki_yomkosti_duplex"
                                                            value={values[5].tbochki_yomkosti_duplex}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[5].tbochki_yomkosti_duplex.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Под Контроль?</Form.Label>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="tbochki_yomkosti_duplex3"
                                                            value={values[5].tbochki_yomkosti_duplex3}
                                                            onChange={handleChangeB}
                                                            label='УЗК'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="tbochki_yomkosti_duplex4"
                                                            value={values[5].tbochki_yomkosti_duplex4}
                                                            onChange={handleChangeB}
                                                            label='Рентген'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="tbochki_yomkosti_duplex5"
                                                            value={values[5].tbochki_yomkosti_duplex5}
                                                            onChange={handleChangeB}
                                                            label='Магнитный'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="tbochki_yomkosti_duplex6"
                                                            value={values[5].tbochki_yomkosti_duplex6}
                                                            onChange={handleChangeB}
                                                            label='Капиллярный (пенетрантами)'
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Duplex</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="tbochki_yomkosti_duplex_q"
                                                            value={values[5].tbochki_yomkosti_duplex_q}
                                                            change={handleChangeQA}
                                                            required={values[5].tbochki_yomkosti_duplex !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="tbochki_yomkosti_duplex2"
                                                            value={values[5].tbochki_yomkosti_duplex2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Super Duplex - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="tbochki_yomkosti_super_duplex"
                                                            value={values[5].tbochki_yomkosti_super_duplex}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Super Duplex - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="tbochki_yomkosti_super_duplex"
                                                            value={values[5].tbochki_yomkosti_super_duplex}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[5].tbochki_yomkosti_super_duplex.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Под Контроль?</Form.Label>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="tbochki_yomkosti_super_duplex3"
                                                            value={values[5].tbochki_yomkosti_super_duplex3}
                                                            onChange={handleChangeB}
                                                            label='УЗК'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="tbochki_yomkosti_super_duplex4"
                                                            value={values[5].tbochki_yomkosti_super_duplex4}
                                                            onChange={handleChangeB}
                                                            label='Рентген'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="tbochki_yomkosti_super_duplex5"
                                                            value={values[5].tbochki_yomkosti_super_duplex5}
                                                            onChange={handleChangeB}
                                                            label='Магнитный'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="tbochki_yomkosti_super_duplex6"
                                                            value={values[5].tbochki_yomkosti_super_duplex6}
                                                            onChange={handleChangeB}
                                                            label='Капиллярный (пенетрантами)
                                                            '
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Super Duplex</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="tbochki_yomkosti_super_duplex_q"
                                                            value={values[5].tbochki_yomkosti_super_duplex_q}
                                                            change={handleChangeQA}
                                                            required={values[5].tbochki_yomkosti_super_duplex !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="tbochki_yomkosti_super_duplex2"
                                                            value={values[5].tbochki_yomkosti_super_duplex2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item variant="secondary">
                                            <Row>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Титан - метод сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="tbochki_yomkosti_titan"
                                                            value={values[5].tbochki_yomkosti_titan}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Титан - метод сварки</Form.Label>
                                                        <Form.Select
                                                            name="tbochki_yomkosti_titan"
                                                            value={values[5].tbochki_yomkosti_titan}
                                                            onChange={handleChangeM}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                                {(values[5].tbochki_yomkosti_titan.length !== 0) && <>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Под Контроль?</Form.Label>
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="tbochki_yomkosti_titan_u"
                                                            value={values[5].tbochki_yomkosti_titan_u}
                                                            onChange={handleChangeB}
                                                            label='УЗК'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="tbochki_yomkosti_titan_r"
                                                            value={values[5].tbochki_yomkosti_titan_r}
                                                            onChange={handleChangeB}
                                                            label='Рентген'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="tbochki_yomkosti_titan_m"
                                                            value={values[5].tbochki_yomkosti_titan_m}
                                                            onChange={handleChangeB}
                                                            label='Магнитный'
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            name="tbochki_yomkosti_titan_k"
                                                            value={values[5].tbochki_yomkosti_titan_k}
                                                            onChange={handleChangeB}
                                                            label='Капиллярный (пенетрантами)'
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Оценка качества - Титан</Form.Label>
                                                        <QA
                                                            rows={1}
                                                            option={radios10}
                                                            name="tbochki_yomkosti_titan_q"
                                                            value={values[5].tbochki_yomkosti_titan_q}
                                                            change={handleChangeQA}
                                                            required={values[5].tbochki_yomkosti_titan !== ''}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            name="tbochki_yomkosti_titan2"
                                                            value={values[5].tbochki_yomkosti_titan2}
                                                            onChange={handleChange}
                                                        />
                                                    </Col>
                                                </>
                                                }
                                            </Row>
                                        </ListGroup.Item>
                                    </>
                                    }
                                    <ListGroup.Item>
                                        <h5>Сварка ёмкостей TIG под Рентген:</h5>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="migmag_duplex"
                                                    value={values[5].migmag_duplex}
                                                    onChange={handleChangeB}
                                                    label='с толщиной стенки 3мм без скоса кромок в один проход с присадочной проволокой в горизонтальном положении'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="migmag_duplex_1"
                                                    value={values[5].migmag_duplex_1}
                                                    onChange={handleChangeB}
                                                    label='с толщиной стенки 4мм без скоса кромок в один проход с присадочной проволокой в горизонтальном положении'
                                                />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Button as="input" type="submit" value="Сохранить" />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Collapse>
                        </Card.Body>
                    </Card>
                </form>) : (<></>)
            }

            {qMask & 1 ? (
                <form onSubmit={onSubmit(6)}>
                    <Card style={{ padding: '3px' }} ref={opens[6].ref} border={Math.pow(2, 6) & qMaskDone ? 'success' : 'light'}>
                        <Card.Header>
                            <Button disabled={opens[6].er} variant={Math.pow(2, 6) & qMaskDone ? 'success' : 'secondary'}
                                    onClick={() => handleOpens(6)}
                                    aria-expanded={opens[6].op}>{opens[6].op ? '-' : '+'}</Button>{' '}
                            Вспомогательное оборудование
                        </Card.Header>
                        <Card.Body>
                            <Collapse in={opens[6].op}>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Сварка на манипуляторе</Form.Label>
                                                <Form.Select
                                                    name="svarka_na_manipulyatore_2"
                                                    value={values[6].svarka_na_manipulyatore_2}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>

                                                </Form.Select>
                                            </Col>
                                            {(values[6].svarka_na_manipulyatore_2 !== 'Нет' && values[6].svarka_na_manipulyatore_2 !== '') && <>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Сколько всего лет опыта? Примерно</Form.Label>
                                                    <QA
                                                        rows={2}
                                                        option={radios20}
                                                        name="svarka_na_manipulyatore_2_2"
                                                        value={values[6].svarka_na_manipulyatore_2_2}
                                                        change={handleChangeQA}
                                                        required={values[6].svarka_na_manipulyatore_2 !== 'Нет'}
                                                    />
                                                </Col>
                                            </>
                                            }
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item variant="secondary">
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Сварка на ротаторе</Form.Label>
                                                <Form.Select
                                                    name="svarka_rotatore_2"
                                                    value={values[6].svarka_rotatore_2}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>

                                                </Form.Select>
                                            </Col>
                                            {(values[6].svarka_rotatore_2 !== 'Нет' && values[6].svarka_rotatore_2 !== '') && <>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Сколько всего лет опыта? Примерно</Form.Label>
                                                    <QA
                                                        rows={2}
                                                        option={radios20}
                                                        name="svarka_rotatore_2_2"
                                                        value={values[6].svarka_rotatore_2_2}
                                                        change={handleChangeQA}
                                                        required={values[6].svarka_rotatore_2 !== 'Нет'}
                                                    />
                                                </Col>
                                            </>
                                            }
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Сварка трактором</Form.Label>
                                                <Form.Select
                                                    name="svarka_traktorom_2"
                                                    value={values[6].svarka_traktorom_2}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>

                                                </Form.Select>
                                            </Col>
                                            {(values[6].svarka_traktorom_2 !== 'Нет' && values[6].svarka_traktorom_2 !== '') && <>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Сколько всего лет опыта? Примерно</Form.Label>
                                                    <QA
                                                        rows={2}
                                                        option={radios20}
                                                        name="svarka_traktorom_2_2"
                                                        value={values[6].svarka_traktorom_2_2}
                                                        change={handleChangeQA}
                                                        required={values[6].svarka_traktorom_2 !== 'Нет'}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Оценка качества</Form.Label>
                                                    <QA
                                                        rows={1}
                                                        option={radios10}
                                                        name="svarka_traktorom_2_4"
                                                        value={values[6].svarka_traktorom_2_4}
                                                        change={handleChangeQA}
                                                        required={values[6].svarka_traktorom_2 !== 'Нет'}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Толщины (мм)</Form.Label>
                                                    <InputGroup className="mb-3">
                                                        <Form.Control
                                                            placeholder="мин"
                                                            type="number"
                                                            name="svarka_traktorom_2_3"
                                                            value={values[6].svarka_traktorom_2_3}
                                                            onChange={handleChange}
                                                            required={values[6].svarka_traktorom_2 !== 'Нет'}
                                                        />
                                                        <Form.Control
                                                            placeholder="макс"
                                                            type="number"
                                                            name="svarka_traktorom_2_3_till"
                                                            value={values[6].svarka_traktorom_2_3_till}
                                                            onChange={handleChange}
                                                            required={values[6].svarka_traktorom_2 !== 'Нет'}
                                                        />
                                                    </InputGroup>
                                                </Col>
                                            </>
                                            }
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Button as="input" type="submit" value="Сохранить" />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Collapse>
                        </Card.Body>
                    </Card>
                </form>) : (<></>)
            }

            {qMask & 1 ? (
                <form onSubmit={onSubmit(7)}>
                    <Card style={{ padding: '3px' }} ref={opens[7].ref} border={Math.pow(2, 7) & qMaskDone ? 'success' : 'light'}>
                        <Card.Header>
                            <Button disabled={opens[7].er} variant={Math.pow(2, 7) & qMaskDone ? 'success' : 'secondary'}
                                    onClick={() => handleOpens(7)}
                                    aria-expanded={opens[7].op}>{opens[7].op ? '-' : '+'}</Button>{' '}
                            Сварочные аппараты
                        </Card.Header>
                        <Card.Body>
                            <Collapse in={opens[7].op}>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <BrowserView>
                                                    <h6>Выберите марки сварочных аппаратов, с которыми работали</h6>
                                                    <Check2
                                                        cols={3}
                                                        rows={3}
                                                        option={optionApparati}
                                                        name="svar_apparati"
                                                        value={values[7].svar_apparati}
                                                        change={handleChangeC}
                                                    />
                                                </BrowserView>
                                                <MobileView>
                                                    <Form.Label title="Выберите один из вариантов.">Выберите конструкции</Form.Label>
                                                    <Form.Select
                                                        name="svar_apparati"
                                                        value={values[7].svar_apparati}
                                                        onChange={handleChangeM}
                                                        required
                                                        multiple={true}
                                                    >
                                                        <Options
                                                            option={optionApparati}
                                                        />
                                                    </Form.Select>
                                                </MobileView>
                                            </Col>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Другие</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="svar_apparati_other"
                                                    value={values[7].svar_apparati_other}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Button as="input" type="submit" value="Сохранить" />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Collapse>
                        </Card.Body>
                    </Card>
                </form>) : (<></>)
            }
            {qMask & 1 ? (
                <form onSubmit={onSubmit(8)}>
                    <Card style={{ padding: '3px' }} ref={opens[8].ref} border={Math.pow(2, 8) & qMaskDone ? 'success' : 'light'}>
                        <Card.Header>
                            <Button disabled={opens[8].er} variant={Math.pow(2, 8) & qMaskDone ? 'success' : 'secondary'}
                                    onClick={() => handleOpens(8)}
                                    aria-expanded={opens[8].op}>{opens[8].op ? '-' : '+'}</Button>{' '}
                            Умеете использовать строгач?
                        </Card.Header>
                        <Card.Body>
                            <Collapse in={opens[8].op}>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Умеете использовать строгач?</Form.Label>
                                                <Form.Select
                                                    name="umeete_ispolzovat_strogach_2"
                                                    value={values[8].umeete_ispolzovat_strogach_2}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>

                                                </Form.Select>
                                            </Col>
                                            {(values[8].umeete_ispolzovat_strogach_2 !== 'Нет' && values[8].umeete_ispolzovat_strogach_2 !== '') && <>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Сколько всего лет опыта? Примерно</Form.Label>
                                                    <QA
                                                        rows={2}
                                                        option={radios20}
                                                        name="umeete_ispolzovat_strogach_2_2"
                                                        value={values[8].umeete_ispolzovat_strogach_2_2}
                                                        change={handleChangeQA}
                                                        required={values[8].umeete_ispolzovat_strogach_2 !== 'Нет'}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Толщины (мм)</Form.Label>
                                                    <InputGroup className="mb-3">
                                                        <Form.Control
                                                            placeholder="мин"
                                                            type="number"
                                                            name="umeete_ispolzovat_strogach_2_3"
                                                            value={values[8].umeete_ispolzovat_strogach_2_3}
                                                            onChange={handleChange}
                                                            required={values[8].umeete_ispolzovat_strogach_2 !== 'Нет'}
                                                        />
                                                        <Form.Control
                                                            placeholder="макс"
                                                            type="number"
                                                            name="umeete_ispolzovat_strogach_2_3_till"
                                                            value={values[8].umeete_ispolzovat_strogach_2_3_till}
                                                            onChange={handleChange}
                                                            required={values[8].umeete_ispolzovat_strogach_2 !== 'Нет'}
                                                        />
                                                    </InputGroup>
                                                </Col>
                                            </>
                                            }
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Button as="input" type="submit" value="Сохранить" />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Collapse>
                        </Card.Body>
                    </Card>
                </form>) : (<></>)
            }
            {qMask & 1 ? (
                <form onSubmit={onSubmit(9)}>
                    <Card style={{ padding: '3px' }} ref={opens[9].ref} border={Math.pow(2, 9) & qMaskDone ? 'success' : 'light'}>
                        <Card.Header>
                            <Button disabled={opens[9].er} variant={Math.pow(2, 9) & qMaskDone ? 'success' : 'secondary'}
                                    onClick={() => handleOpens(9)}
                                    aria-expanded={opens[9].op}>{opens[9].op ? '-' : '+'}</Button>{' '}
                            Пространственные положения
                        </Card.Header>
                        <Card.Body>
                            <Collapse in={opens[9].op}>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Нижнее положение</Form.Label>
                                                <Form.Select
                                                    name="nizhnem_polozhenii_5"
                                                    value={values[9].nizhnem_polozhenii_5}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>

                                                </Form.Select>
                                            </Col>
                                            {(values[9].nizhnem_polozhenii_5 !== 'Нет' && values[9].nizhnem_polozhenii_5 !== '') && <>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Оценка качества - Нижнее положение</Form.Label>
                                                    <QA
                                                        rows={1}
                                                        option={radios10}
                                                        name="nizhnem_polozhenii_5_2"
                                                        value={values[9].nizhnem_polozhenii_5_2}
                                                        change={handleChangeQA}
                                                        required={values[9].nizhnem_polozhenii_5 !== 'Нет'}
                                                    />
                                                </Col>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Выберите процесс сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="nizhnem_proc"
                                                            value={values[9].nizhnem_proc}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Выберите процесс сварки</Form.Label>
                                                        <Form.Select
                                                            name="nizhnem_proc"
                                                            value={values[9].nizhnem_proc}
                                                            onChange={handleChangeM}
                                                            required={values[9].nizhnem_polozhenii_5 !== 'Нет'}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                            </>
                                            }
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item variant="secondary">
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Потолочное положение</Form.Label>
                                                <Form.Select
                                                    name="potolochnoe_polozhenie_5"
                                                    value={values[9].potolochnoe_polozhenie_5}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>

                                                </Form.Select>
                                            </Col>
                                            {(values[9].potolochnoe_polozhenie_5 !== 'Нет' && values[9].potolochnoe_polozhenie_5 !== '') && <>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Оценка качества - Потолочное положение</Form.Label>
                                                    <QA
                                                        rows={1}
                                                        option={radios10}
                                                        name="potolochnoe_polozhenie_5_2"
                                                        value={values[9].potolochnoe_polozhenie_5_2}
                                                        change={handleChangeQA}
                                                        required={values[9].potolochnoe_polozhenie_5 !== 'Нет'}
                                                    />
                                                </Col>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Выберите процесс сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="potolochnoe_proc"
                                                            value={values[9].potolochnoe_proc}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Выберите процесс сварки</Form.Label>
                                                        <Form.Select
                                                            name="potolochnoe_proc"
                                                            value={values[9].potolochnoe_proc}
                                                            onChange={handleChangeM}
                                                            required={values[9].potolochnoe_polozhenie_5 !== 'Нет'}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                            </>
                                            }
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Вертикальное положение</Form.Label>
                                                <Form.Select
                                                    name="vertikalnoe_polozhenie_5"
                                                    value={values[9].vertikalnoe_polozhenie_5}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>

                                                </Form.Select>
                                            </Col>
                                            {(values[9].vertikalnoe_polozhenie_5 !== 'Нет' && values[9].vertikalnoe_polozhenie_5 !== '') && <>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Оценка качества - Вертикальное положение</Form.Label>
                                                    <QA
                                                        rows={1}
                                                        option={radios10}
                                                        name="vertikalnoe_polozhenie_5_2"
                                                        value={values[9].vertikalnoe_polozhenie_5_2}
                                                        change={handleChangeQA}
                                                        required={values[9].vertikalnoe_polozhenie_5 !== 'Нет'}
                                                    />
                                                </Col>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Выберите процесс сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="vertikalnoe_proc"
                                                            value={values[9].vertikalnoe_proc}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Выберите процесс сварки</Form.Label>
                                                        <Form.Select
                                                            name="vertikalnoe_proc"
                                                            value={values[9].vertikalnoe_proc}
                                                            onChange={handleChangeM}
                                                            required={values[9].vertikalnoe_polozhenie_5 !== 'Нет'}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                            </>
                                            }
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item variant="secondary">
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Горизонтальное положение</Form.Label>
                                                <Form.Select
                                                    name="gorizontalnoe_polozhenie_5"
                                                    value={values[9].gorizontalnoe_polozhenie_5}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>

                                                </Form.Select>
                                            </Col>
                                            {(values[9].gorizontalnoe_polozhenie_5 !== 'Нет' && values[9].gorizontalnoe_polozhenie_5 !== '') && <>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Оценка качества - Горизонтальное положение</Form.Label>
                                                    <QA
                                                        rows={1}
                                                        option={radios10}
                                                        name="gorizontalnoe_polozhenie_5_2"
                                                        value={values[9].gorizontalnoe_polozhenie_5_2}
                                                        change={handleChangeQA}
                                                        required={values[9].gorizontalnoe_polozhenie_5 !== 'Нет'}
                                                    />
                                                </Col>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Выберите процесс сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="gorizontalnoe_proc"
                                                            value={values[9].gorizontalnoe_proc}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Выберите процесс сварки</Form.Label>
                                                        <Form.Select
                                                            name="gorizontalnoe_proc"
                                                            value={values[9].gorizontalnoe_proc}
                                                            onChange={handleChangeM}
                                                            required={values[9].gorizontalnoe_polozhenie_5 !== 'Нет'}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                            </>
                                            }
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Пластина в вертикальном положении</Form.Label>
                                                <Form.Select
                                                    name="pod_gradusov_5"
                                                    value={values[9].pod_gradusov_5}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>

                                                </Form.Select>
                                            </Col>
                                            {(values[9].pod_gradusov_5 !== 'Нет' && values[9].pod_gradusov_5 !== '') && <>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Оценка качества - Пластина в вертикальном положении</Form.Label>
                                                    <QA
                                                        rows={1}
                                                        option={radios10}
                                                        name="pod_gradusov_5_2"
                                                        value={values[9].pod_gradusov_5_2}
                                                        change={handleChangeQA}
                                                        required={values[9].pod_gradusov_5 !== 'Нет'}
                                                    />
                                                </Col>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Выберите процесс сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="pod_gradusov_proc"
                                                            value={values[9].pod_gradusov_proc}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Выберите процесс сварки</Form.Label>
                                                        <Form.Select
                                                            name="pod_gradusov_proc"
                                                            value={values[9].pod_gradusov_proc}
                                                            onChange={handleChangeM}
                                                            required={values[9].pod_gradusov_5 !== 'Нет'}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                            </>
                                            }
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item variant="secondary">
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Пластина в потолочном положении</Form.Label>
                                                <Form.Select
                                                    name="pod_gradusov_5_p"
                                                    value={values[9].pod_gradusov_5_p}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>
                                                </Form.Select>
                                            </Col>
                                            {(values[9].pod_gradusov_5_p !== 'Нет' && values[9].pod_gradusov_5_p !== '') && <>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Оценка качества - Пластина в потолочном положении</Form.Label>
                                                    <QA
                                                        rows={1}
                                                        option={radios10}
                                                        name="pod_gradusov_5_2_p"
                                                        value={values[9].pod_gradusov_5_2_p}
                                                        change={handleChangeQA}
                                                        required={values[9].pod_gradusov_5_p !== 'Нет'}
                                                    />
                                                </Col>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Выберите процесс сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="pod_gradusov_proc_p"
                                                            value={values[9].pod_gradusov_proc_p}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Выберите процесс сварки</Form.Label>
                                                        <Form.Select
                                                            name="pod_gradusov_proc_p"
                                                            value={values[9].pod_gradusov_proc_p}
                                                            onChange={handleChangeM}
                                                            required={values[9].pod_gradusov_5_p !== 'Нет'}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                            </>
                                            }
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Труба под 45 градусов</Form.Label>
                                                <Form.Select
                                                    name="pod_truba_5"
                                                    value={values[9].pod_truba_5}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>
                                                </Form.Select>
                                            </Col>
                                            {(values[9].pod_truba_5 !== 'Нет' && values[9].pod_truba_5 !== '') && <>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Оценка качества - Труба под 45 градусов</Form.Label>
                                                    <QA
                                                        rows={1}
                                                        option={radios10}
                                                        name="pod_truba_5_2"
                                                        value={values[9].pod_truba_5_2}
                                                        change={handleChangeQA}
                                                        required={values[9].pod_truba_5 !== 'Нет'}
                                                    />
                                                </Col>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Выберите процесс сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="pod_truba_proc"
                                                            value={values[9].pod_truba_proc}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Выберите процесс сварки</Form.Label>
                                                        <Form.Select
                                                            name="pod_truba_proc"
                                                            value={values[9].pod_truba_proc}
                                                            onChange={handleChangeM}
                                                            required={values[9].pod_truba_5 !== 'Нет'}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                            </>
                                            }
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Деталь под 45 градусов</Form.Label>
                                                <Form.Select
                                                    name="detail_pod_5"
                                                    value={values[9].detail_pod_5}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>
                                                </Form.Select>
                                            </Col>
                                            {(values[9].detail_pod_5 !== 'Нет' && values[9].detail_pod_5 !== '') && <>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Оценка качества - Деталь под 45 градусов</Form.Label>
                                                    <QA
                                                        rows={1}
                                                        option={radios10}
                                                        name="detail_pod_5_2"
                                                        value={values[9].detail_pod_5_2}
                                                        change={handleChangeQA}
                                                        required={values[9].detail_pod_5 !== 'Нет'}
                                                    />
                                                </Col>
                                                <Col>
                                                    <BrowserView>
                                                        <h6>Выберите процесс сварки</h6>
                                                        <Check2
                                                            cols={3}
                                                            rows={3}
                                                            option={optionPros}
                                                            name="detail_pod_proc"
                                                            value={values[9].detail_pod_proc}
                                                            change={handleChangeC}
                                                        />
                                                    </BrowserView>
                                                    <MobileView>
                                                        <Form.Label title="Выберите один из вариантов.">Выберите процесс сварки</Form.Label>
                                                        <Form.Select
                                                            name="detail_pod_proc"
                                                            value={values[9].detail_pod_proc}
                                                            onChange={handleChangeM}
                                                            required={values[9].detail_pod_5 !== 'Нет'}
                                                            multiple={true}
                                                        >
                                                            <Options
                                                                option={optionPros}
                                                            />
                                                        </Form.Select>
                                                    </MobileView>
                                                </Col>
                                            </>
                                            }
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Form.Label>Под какое максимальное давление cваривали (кг, бар)</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="max_pressure"
                                                    value={values[9].max_pressure}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    {/* <ListGroup.Item variant="secondary">
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Сварка по 10 бальнной шкале</Form.Label>
                                                <QA
                                                    rows={1}
                                                    option={radios10}
                                                    name="svarka_shkale_5"
                                                    value={values[9].svarka_shkale_5}
                                                    change={handleChangeQA}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item> */}
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Button as="input" type="submit" value="Сохранить" />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Collapse>
                        </Card.Body>
                    </Card>
                </form>) : (<></>)
            }
            {qMask & 1 ? (
                <form onSubmit={onSubmit(10)}>
                    <Card style={{ padding: '3px' }} ref={opens[10].ref} border={Math.pow(2, 10) & qMaskDone ? 'success' : 'light'}>
                        <Card.Header>
                            <Button disabled={opens[10].er} variant={Math.pow(2, 10) & qMaskDone ? 'success' : 'secondary'}
                                    onClick={() => handleOpens(10)}
                                    aria-expanded={opens[10].op}>{opens[10].op ? '-' : '+'}</Button>{' '}
                            Дополнительные вопросы
                        </Card.Header>
                        <Card.Body>
                            <Collapse in={opens[10].op}>
                                <ListGroup variant="flush">
                                    {/* <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Вы знаете технологию сварки нержавеющей стали?</Form.Label>
                                                <Form.Select
                                                    name="tehnology_nerz"
                                                    value={values[10].tehnology_nerz}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="No info">No info</option>
                                                    <option value="Не уверен">Не уверен</option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>
                                                </Form.Select>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Вы знаете технологию сварки углеродистой(чёрной) стали?</Form.Label>
                                                <Form.Select
                                                    name="tehnology_carbon"
                                                    value={values[10].tehnology_carbon}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="No info">No info</option>
                                                    <option value="Не уверен">Не уверен</option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>
                                                </Form.Select>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item> */}
                                    <ListGroup.Item variant="secondary">
                                        <h5>Умеете работать с:</h5>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="knowledge_facet_tube"
                                                    value={values[10].knowledge_facet_tube}
                                                    onChange={handleChangeB}
                                                    label='фаскоснимателем для стальных труб?'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="knowledge_facet_plate"
                                                    value={values[10].knowledge_facet_plate}
                                                    onChange={handleChangeB}
                                                    label='фаскоснимателем по листовому металлу?'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="knowledge_center"
                                                    value={values[10].knowledge_center}
                                                    onChange={handleChangeB}
                                                    label='центраторами для стыковки труб?'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="shablon"
                                                    value={values[10].shablon}
                                                    onChange={handleChangeB}
                                                    label='шаблоном для измерения катета шва?'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="heating_material"
                                                    value={values[10].heating_material}
                                                    onChange={handleChangeB}
                                                    label='подогревом материала?'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="thermo_pen"
                                                    value={values[10].thermo_pen}
                                                    onChange={handleChangeB}
                                                    label='термокарандашом?'
                                                />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <h5>Умеете сваривать:</h5>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="migmag_angle"
                                                    value={values[10].migmag_angle}
                                                    onChange={handleChangeB}
                                                    label='полуавтоматом углом вперед или назад'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="tig_tandem"
                                                    value={values[10].tig_tandem}
                                                    onChange={handleChangeB}
                                                    label='методом Тандем (дуга в дугу)'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="migmag_vert_down"
                                                    value={values[10].migmag_vert_down}
                                                    onChange={handleChangeB}
                                                    label='вертикальные швы сверху вниз'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="tig_duplex_impulse"
                                                    value={values[10].tig_duplex_impulse}
                                                    onChange={handleChangeB}
                                                    label='Tig на режиме импульс'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="knowledge_gap"
                                                    value={values[10].knowledge_gap}
                                                    onChange={handleChangeB}
                                                    label='трубы с зазором более 3мм'
                                                />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item variant="secondary">
                                        <h5>Способны?</h5>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="knowledge_electric_clean"
                                                    value={values[10].knowledge_electric_clean}
                                                    onChange={handleChangeB}
                                                    label='делать электрохимическую очистку сварных швов'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="knowledge_repair_defect"
                                                    value={values[10].knowledge_repair_defect}
                                                    onChange={handleChangeB}
                                                    label='исправлять дефекты сварных швов'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="knowledge_facet_bolgarka"
                                                    value={values[10].knowledge_facet_bolgarka}
                                                    onChange={handleChangeB}
                                                    label='делать фаски труб болгаркой'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="kaskad"
                                                    value={values[10].kaskad}
                                                    onChange={handleChangeB}
                                                    label='работать в сварке каскадом (многослойная сварка)'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="knowledge_setup_w_machine"
                                                    value={values[10].knowledge_setup_w_machine}
                                                    onChange={handleChangeB}
                                                    label='настраивать сварочный аппарат'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="knowledge_work_wps"
                                                    value={values[10].knowledge_work_wps}
                                                    onChange={handleChangeB}
                                                    label='работать по WPS'
                                                />
                                            </Col>
                                        </Row>

                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <h5>Разбираетесь в:</h5>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="knowledge_gas"
                                                    value={values[10].knowledge_gas}
                                                    onChange={handleChangeB}
                                                    label='выборе защитного газа для сварки?'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="knowledge_wire"
                                                    value={values[10].knowledge_wire}
                                                    onChange={handleChangeB}
                                                    label='марках сварочной проволоки?'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="knowledge_brands_welding_filler_rods"
                                                    value={values[10].knowledge_brands_welding_filler_rods}
                                                    onChange={handleChangeB}
                                                    label='марках сварочных присадочных прутков?'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="knowledge_brands_welding_electrodes"
                                                    value={values[10].knowledge_brands_welding_electrodes}
                                                    onChange={handleChangeB}
                                                    label='марках сварочных электродах?'
                                                />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Button as="input" type="submit" value="Сохранить" />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Collapse>
                        </Card.Body>
                    </Card>
                </form>) : (<></>)
            }
            {qMask & 2 ? (
                <form onSubmit={onSubmit(11)}>
                    <Card style={{ padding: '3px' }} ref={opens[11].ref} border={Math.pow(2, 11) & qMaskDone ? 'success' : 'light'}>
                        <Card.Header>
                            <Button disabled={opens[11].er} variant={Math.pow(2, 11) & qMaskDone ? 'success' : 'secondary'}
                                    onClick={() => handleOpens(11)}
                                    aria-expanded={opens[11].op}>{opens[11].op ? '-' : '+'}</Button>{' '}
                            Металлоконструкции
                        </Card.Header>
                        <Card.Body>
                            <Collapse in={opens[11].op}>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Металлоконструкции</Form.Label>
                                                <Form.Select
                                                    name="sborka_metalokonstrukcij_6_1"
                                                    value={values[11].sborka_metalokonstrukcij_6_1}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>
                                                </Form.Select>
                                            </Col>
                                            {(values[11].sborka_metalokonstrukcij_6_1 !== 'Нет' && values[11].sborka_metalokonstrukcij_6_1 !== '') && <>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Сколько всего лет опыта? Примерно</Form.Label>
                                                    <QA
                                                        rows={2}
                                                        option={radios20}
                                                        name="sborka_metalokonstrukcij_6"
                                                        value={values[11].sborka_metalokonstrukcij_6}
                                                        change={handleChangeQA}
                                                        required={values[11].sborka_metalokonstrukcij_6_1 !== 'Нет'}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Оценка качества</Form.Label>
                                                    <QA
                                                        rows={1}
                                                        option={radios10}
                                                        name="sborka_metalokonstrukcij_6_2"
                                                        value={values[11].sborka_metalokonstrukcij_6_2}
                                                        change={handleChangeQA}
                                                        required={values[11].sborka_metalokonstrukcij_6_1 !== 'Нет'}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={3}
                                                        name="sborka_metalokonstrukcij_6_3"
                                                        value={values[11].sborka_metalokonstrukcij_6_3}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </>
                                            }
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Button as="input" type="submit" value="Сохранить" />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Collapse>
                        </Card.Body>
                    </Card>
                </form>) : (<></>)
            }
            {qMask & 2 ? (
                <form onSubmit={onSubmit(12)}>
                    <Card style={{ padding: '3px' }} ref={opens[12].ref} border={Math.pow(2, 12) & qMaskDone ? 'success' : 'light'}>
                        <Card.Header>
                            <Button disabled={opens[12].er} variant={Math.pow(2, 12) & qMaskDone ? 'success' : 'secondary'}
                                    onClick={() => handleOpens(12)}
                                    aria-expanded={opens[12].op}>{opens[12].op ? '-' : '+'}</Button>{' '}
                            Машиностроение
                        </Card.Header>
                        <Card.Body>
                            <Collapse in={opens[12].op}>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Машиностроение</Form.Label>
                                                <Form.Select
                                                    name="sborka_mashinostroenie_6_1"
                                                    value={values[12].sborka_mashinostroenie_6_1}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>
                                                </Form.Select>
                                            </Col>
                                            {(values[12].sborka_mashinostroenie_6_1 !== 'Нет' && values[12].sborka_mashinostroenie_6_1 !== '') && <>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Сколько всего лет опыта? Примерно</Form.Label>
                                                    <QA
                                                        rows={2}
                                                        option={radios20}
                                                        name="sborka_mashinostroenie_6"
                                                        value={values[12].sborka_mashinostroenie_6}
                                                        change={handleChangeQA}
                                                        required={values[12].sborka_mashinostroenie_6_1 !== 'Нет'}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Оценка качества</Form.Label>
                                                    <QA
                                                        rows={1}
                                                        option={radios10}
                                                        name="sborka_mashinostroenie_6_2"
                                                        value={values[12].sborka_mashinostroenie_6_2}
                                                        change={handleChangeQA}
                                                        required={values[12].sborka_mashinostroenie_6_1 !== 'Нет'}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={3}
                                                        name="sborka_mashinostroenie_6_3"
                                                        value={values[12].sborka_mashinostroenie_6_3}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </>
                                            }
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Button as="input" type="submit" value="Сохранить" />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Collapse>
                        </Card.Body>
                    </Card>
                </form>) : (<></>)
            }
            {qMask & 2 ? (
                <form onSubmit={onSubmit(13)}>
                    <Card style={{ padding: '3px' }} ref={opens[13].ref} border={Math.pow(2, 13) & qMaskDone ? 'success' : 'light'}>
                        <Card.Header>
                            <Button disabled={opens[13].er} variant={Math.pow(2, 13) & qMaskDone ? 'success' : 'secondary'}
                                    onClick={() => handleOpens(13)}
                                    aria-expanded={opens[13].op}>{opens[13].op ? '-' : '+'}</Button>{' '}
                            Трубы
                        </Card.Header>
                        <Card.Body>
                            <Collapse in={opens[13].op}>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Трубы</Form.Label>
                                                <Form.Select
                                                    name="sborka_trub_6_1"
                                                    value={values[13].sborka_trub_6_1}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>

                                                </Form.Select>
                                            </Col>
                                            {(values[13].sborka_trub_6_1 !== 'Нет' && values[13].sborka_trub_6_1 !== '') && <>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Сколько всего лет опыта? Примерно</Form.Label>
                                                    <QA
                                                        rows={2}
                                                        option={radios20}
                                                        name="sborka_trub_6"
                                                        value={values[13].sborka_trub_6}
                                                        change={handleChangeQA}
                                                        required={values[13].sborka_trub_6_1 !== 'Нет'}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Оценка качества</Form.Label>
                                                    <QA
                                                        rows={1}
                                                        option={radios10}
                                                        name="sborka_trub_6_2"
                                                        value={values[13].sborka_trub_6_2}
                                                        change={handleChangeQA}
                                                        required={values[13].sborka_trub_6_1 !== 'Нет'}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={3}
                                                        name="sborka_trub_6_3"
                                                        value={values[13].sborka_trub_6_3}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </>
                                            }
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Button as="input" type="submit" value="Сохранить" />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Collapse>
                        </Card.Body>
                    </Card>
                </form>) : (<></>)
            }
            {qMask & 2 ? (
                <form onSubmit={onSubmit(14)}>
                    <Card style={{ padding: '3px' }} ref={opens[14].ref} border={Math.pow(2, 14) & qMaskDone ? 'success' : 'light'}>
                        <Card.Header>
                            <Button disabled={opens[14].er} variant={Math.pow(2, 14) & qMaskDone ? 'success' : 'secondary'}
                                    onClick={() => handleOpens(14)}
                                    aria-expanded={opens[14].op}>{opens[14].op ? '-' : '+'}</Button>{' '}
                            Бочки, ёмкости
                        </Card.Header>
                        <Card.Body>
                            <Collapse in={opens[14].op}>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Бочки, ёмкости</Form.Label>
                                                <Form.Select
                                                    name="sborka_bochek_6_1"
                                                    value={values[14].sborka_bochek_6_1}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>
                                                </Form.Select>
                                            </Col>
                                            {(values[14].sborka_bochek_6_1 !== 'Нет' && values[14].sborka_bochek_6_1 !== '') && <>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Сколько всего лет опыта? Примерно</Form.Label>
                                                    <QA
                                                        rows={2}
                                                        option={radios20}
                                                        name="sborka_bochek_6"
                                                        value={values[14].sborka_bochek_6}
                                                        change={handleChangeQA}
                                                        required={values[14].sborka_bochek_6_1 !== 'Нет'}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Оценка качества</Form.Label>
                                                    <QA
                                                        rows={1}
                                                        option={radios10}
                                                        name="sborka_bochek_6_2"
                                                        value={values[14].sborka_bochek_6_2}
                                                        change={handleChangeQA}
                                                        required={values[14].sborka_bochek_6_1 !== 'Нет'}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Комментарий</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={3}
                                                        name="sborka_bochek_6_3"
                                                        value={values[14].sborka_bochek_6_3}
                                                        onChange={handleChange}
                                                    />
                                                </Col>
                                            </>
                                            }
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Button as="input" type="submit" value="Сохранить" />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Collapse>
                        </Card.Body>
                    </Card>
                </form>) : (<></>)
            }
            {qMask & 2 ? (
                <form onSubmit={onSubmit(15)}>
                    <Card style={{ padding: '3px' }} ref={opens[15].ref} border={Math.pow(2, 15) & qMaskDone ? 'success' : 'light'}>
                        <Card.Header>
                            <Button disabled={opens[15].er} variant={Math.pow(2, 15) & qMaskDone ? 'success' : 'secondary'}
                                    onClick={() => handleOpens(15)}
                                    aria-expanded={opens[15].op}>{opens[15].op ? '-' : '+'}</Button>{' '}
                            Какие конструкции?
                        </Card.Header>
                        <Card.Body>
                            <Collapse in={opens[15].op}>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <BrowserView>
                                                    <h6>Какие изделия и конструкции еще собирали</h6>
                                                    <Check2
                                                        cols={3}
                                                        rows={3}
                                                        option={optionKonst}
                                                        name="konst_kakie"
                                                        value={values[15].konst_kakie}
                                                        change={handleChangeC}
                                                    />
                                                </BrowserView>
                                                <MobileView>
                                                    <Form.Label title="Выберите один из вариантов.">Выберите конструкции</Form.Label>
                                                    <Form.Select
                                                        name="konst_kakie"
                                                        value={values[15].konst_kakie}
                                                        onChange={handleChangeM}
                                                        required
                                                        multiple={true}
                                                    >
                                                        <Options
                                                            option={optionKonst}
                                                        />
                                                    </Form.Select>
                                                </MobileView>
                                            </Col>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Другие</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="konst_kakie_other"
                                                    value={values[15].konst_kakie_other}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Button as="input" type="submit" value="Сохранить" />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Collapse>
                        </Card.Body>
                    </Card>
                </form>) : (<></>)
            }
            {qMask & 2 ? (
                <form onSubmit={onSubmit(16)}>
                    <Card style={{ padding: '3px' }} ref={opens[16].ref} border={Math.pow(2, 16) & qMaskDone ? 'success' : 'light'}>
                        <Card.Header>
                            <Button disabled={opens[16].er} variant={Math.pow(2, 16) & qMaskDone ? 'success' : 'secondary'}
                                    onClick={() => handleOpens(16)}
                                    aria-expanded={opens[16].op}>{opens[16].op ? '-' : '+'}</Button>{' '}
                            Сборка по приспособам, в кондукторах?
                        </Card.Header>
                        <Card.Body>
                            <Collapse in={opens[16].op}>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Сборка по приспособам, в кондукторах</Form.Label>
                                                <Form.Select
                                                    name="sborka_konduktorah_6_1"
                                                    value={values[16].sborka_konduktorah_6_1}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>

                                                </Form.Select>
                                            </Col>
                                            {(values[16].sborka_konduktorah_6_1 !== 'Нет' && values[16].sborka_konduktorah_6_1 !== '') && <>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Сколько всего лет опыта? Примерно</Form.Label>
                                                    <QA
                                                        rows={2}
                                                        option={radios20}
                                                        name="sborka_konduktorah_6"
                                                        value={values[16].sborka_konduktorah_6}
                                                        change={handleChangeQA}
                                                        required={values[16].sborka_konduktorah_6_1 !== 'Нет'}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Оценка качества</Form.Label>
                                                    <QA
                                                        rows={1}
                                                        option={radios10}
                                                        name="sborka_konduktorah_6_2"
                                                        value={values[16].sborka_konduktorah_6_2}
                                                        change={handleChangeQA}
                                                        required={values[16].sborka_konduktorah_6_1 !== 'Нет'}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Сборка первым номером или вторым?</Form.Label>
                                                    <Form.Select
                                                        name="sborka_konduktorah_6_3"
                                                        value={values[16].sborka_konduktorah_6_3}
                                                        onChange={handleChange}
                                                        required={values[16].sborka_konduktorah_6_1 !== 'Нет'}
                                                    >
                                                        <option value=""></option>
                                                        <option value="No info">No info</option>
                                                        <option value="первым">первым</option>
                                                        <option value="вторым">вторым</option>
                                                    </Form.Select>
                                                </Col>
                                            </>
                                            }
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Button as="input" type="submit" value="Сохранить" />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Collapse>
                        </Card.Body>
                    </Card>
                </form>) : (<></>)
            }
            {qMask & 2 ? (
                <form onSubmit={onSubmit(17)}>
                    <Card style={{ padding: '3px' }} ref={opens[17].ref} border={Math.pow(2, 17) & qMaskDone ? 'success' : 'light'}>
                        <Card.Header>
                            <Button disabled={opens[17].er} variant={Math.pow(2, 17) & qMaskDone ? 'success' : 'secondary'}
                                    onClick={() => handleOpens(17)}
                                    aria-expanded={opens[17].op}>{opens[17].op ? '-' : '+'}</Button>{' '}
                            Оценка сборки по 10 бальнной шкале?
                        </Card.Header>
                        <Card.Body>
                            <Collapse in={opens[17].op}>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Оценка качества</Form.Label>
                                                <QA
                                                    rows={1}
                                                    option={radios10}
                                                    name="sborkavtorym_6_2"
                                                    value={values[17].sborkavtorym_6_2}
                                                    change={handleChangeQA}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Button as="input" type="submit" value="Сохранить" />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Collapse>
                        </Card.Body>
                    </Card>
                </form>) : (<></>)
            }
            {qMask & 2 ? (
                <form onSubmit={onSubmit(18)}>
                    <Card style={{ padding: '3px' }} ref={opens[18].ref} border={Math.pow(2, 18) & qMaskDone ? 'success' : 'light'}>
                        <Card.Header>
                            <Button disabled={opens[18].er} variant={Math.pow(2, 18) & qMaskDone ? 'success' : 'secondary'}
                                    onClick={() => handleOpens(18)}
                                    aria-expanded={opens[18].op}>{opens[18].op ? '-' : '+'}</Button>{' '}
                            Рихтовка?
                        </Card.Header>
                        <Card.Body>
                            <Collapse in={opens[18].op}>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Опыт в рихтовке мет.конструкций?</Form.Label>
                                                <Form.Select
                                                    name="opyt_konstrukcij_7"
                                                    value={values[18].opyt_konstrukcij_7}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>

                                                </Form.Select>
                                            </Col>
                                            {(values[18].opyt_konstrukcij_7 !== 'Нет' && values[18].opyt_konstrukcij_7 !== '') && <>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Каким газом или смесью?</Form.Label>
                                                    <Form.Select
                                                        name="esli_smesyu_7"
                                                        value={values[18].esli_smesyu_7}
                                                        onChange={handleChange}
                                                        required={values[18].opyt_konstrukcij_7 !== 'Нет'}
                                                    >
                                                        <option value=""></option>
                                                        <option value="No info">No info</option>
                                                        <option value="пропан + кислород">пропан + кислород</option>
                                                        <option value="ацетилен + кислород">ацетилен + кислород</option>
                                                    </Form.Select>
                                                </Col>
                                            </>
                                            }
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Button as="input" type="submit" value="Сохранить" />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Collapse>
                        </Card.Body>
                    </Card>
                </form>) : (<></>)
            }
            {qMask & 10 ? (
                <form onSubmit={onSubmit(19)}>
                    <Card style={{ padding: '3px' }} ref={opens[19].ref} border={Math.pow(2, 19) & qMaskDone ? 'success' : 'light'}>
                        <Card.Header>
                            <Button disabled={opens[19].er} variant={Math.pow(2, 19) & qMaskDone ? 'success' : 'secondary'}
                                    onClick={() => handleOpens(19)}
                                    aria-expanded={opens[19].op}>{opens[19].op ? '-' : '+'}</Button>{' '}
                            В каких чертежах Вы понимаете?
                        </Card.Header>
                        <Card.Body>
                            <Collapse in={opens[19].op}>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Сварочные чертежи</Form.Label>
                                                <Form.Select
                                                    name="svarochnye_chertezhi_8"
                                                    value={values[19].svarochnye_chertezhi_8}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="No info">No info</option>
                                                    <option value="простой">простой</option>
                                                    <option value="средний">средний</option>
                                                    <option value="выше среднего">выше среднего</option>
                                                </Form.Select>
                                            </Col>
                                            {(values[19].svarochnye_chertezhi_8 !== 'No info' && values[19].svarochnye_chertezhi_8 !== '') && <>
                                                {/* <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Сварочные чертежи - оценка качества</Form.Label>
                                                    <QA
                                                        rows={1}
                                                        option={radios10}
                                                        name="svarochnye_chertezhi_8_2"
                                                        value={values[19].svarochnye_chertezhi_8_2}
                                                        change={handleChangeQA}
                                                        required={values[19].svarochnye_chertezhi_8 !== 'No info'}
                                                    />
                                                </Col> */}
                                            </>
                                            }
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item variant="secondary">
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Сборочные чертежи</Form.Label>
                                                <Form.Select
                                                    name="sborochnye_chertezhi_8"
                                                    value={values[19].sborochnye_chertezhi_8}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="No info">No info</option>
                                                    <option value="простой">простой</option>
                                                    <option value="средний">средний</option>
                                                    <option value="выше среднего">выше среднего</option>
                                                </Form.Select>
                                            </Col>
                                            {(values[19].sborochnye_chertezhi_8 !== 'No info' && values[19].sborochnye_chertezhi_8 !== '') && <>
                                                {/* <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Сборочные чертежи - оценка качества</Form.Label>
                                                    <QA
                                                        rows={1}
                                                        option={radios10}
                                                        name="sborochnye_chertezhi_8_2"
                                                        value={values[19].sborochnye_chertezhi_8_2}
                                                        change={handleChangeQA}
                                                        required={values[19].sborochnye_chertezhi_8 !== 'No info'}
                                                    />
                                                </Col> */}
                                            </>
                                            }
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Механические чертежи</Form.Label>
                                                <Form.Select
                                                    name="mehanicheskie_chertezhi_8"
                                                    value={values[19].mehanicheskie_chertezhi_8}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="No info">No info</option>
                                                    <option value="простой">простой</option>
                                                    <option value="средний">средний</option>
                                                    <option value="выше среднего">выше среднего</option>
                                                </Form.Select>
                                            </Col>
                                            {(values[19].mehanicheskie_chertezhi_8 !== 'No info' && values[19].mehanicheskie_chertezhi_8 !== '') && <>
                                                {/* <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Механические чертежи - оценка качества</Form.Label>
                                                    <QA
                                                        rows={1}
                                                        option={radios10}
                                                        name="mehanicheskie_chertezhi_8_2"
                                                        value={values[19].mehanicheskie_chertezhi_8_2}
                                                        change={handleChangeQA}
                                                        required={values[19].mehanicheskie_chertezhi_8 !== 'No info'}
                                                    />
                                                </Col> */}
                                            </>
                                            }
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item variant="secondary">
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Изометрические чертежи (трубы)</Form.Label>
                                                <Form.Select
                                                    name="izometricheskie_chertezhi_8"
                                                    value={values[19].izometricheskie_chertezhi_8}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="No info">No info</option>
                                                    <option value="простой">простой</option>
                                                    <option value="средний">средний</option>
                                                    <option value="выше среднего">выше среднего</option>
                                                </Form.Select>
                                            </Col>
                                            {(values[19].izometricheskie_chertezhi_8 !== 'No info' && values[19].izometricheskie_chertezhi_8 !== '') && <>
                                                {/* <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Изометрические чертежи (трубы) - оценка качества</Form.Label>
                                                    <QA
                                                        rows={1}
                                                        option={radios10}
                                                        name="izometricheskie_chertezhi_8_2"
                                                        value={values[19].izometricheskie_chertezhi_8_2}
                                                        change={handleChangeQA}
                                                        required={values[19].izometricheskie_chertezhi_8 !== 'No info'}
                                                    />
                                                </Col> */}
                                            </>
                                            }
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Button as="input" type="submit" value="Сохранить" />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Collapse>
                        </Card.Body>
                    </Card>
                </form>) : (<></>)
            }
            {qMask & 2 ? (
                <form onSubmit={onSubmit(20)}>
                    <Card style={{ padding: '3px' }} ref={opens[20].ref} border={Math.pow(2, 20) & qMaskDone ? 'success' : 'light'}>
                        <Card.Header>
                            <Button disabled={opens[20].er} variant={Math.pow(2, 20) & qMaskDone ? 'success' : 'secondary'}
                                    onClick={() => handleOpens(20)}
                                    aria-expanded={opens[20].op}>{opens[20].op ? '-' : '+'}</Button>{' '}
                            Выберите станки
                        </Card.Header>
                        <Card.Body>
                            <Collapse in={opens[20].op}>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <BrowserView>
                                                    <h6>Выберите станки, с какими умеете работать</h6>
                                                    <Check2
                                                        cols={3}
                                                        rows={4}
                                                        option={optionStanok}
                                                        name="stano"
                                                        value={values[20].stano}
                                                        change={handleChangeC}
                                                    />
                                                </BrowserView>
                                                <MobileView>
                                                    <Form.Label title="Выберите один из вариантов.">Выберите станки, с какими умеете работать</Form.Label>
                                                    <Form.Select
                                                        name="stano"
                                                        value={values[20].stano}
                                                        onChange={handleChangeM}
                                                        multiple={true}
                                                    >
                                                        <Options
                                                            option={optionStanok}
                                                        />
                                                    </Form.Select>
                                                </MobileView>
                                            </Col>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Другие</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="stano_other"
                                                    value={values[20].stano_other}
                                                    onChange={handleChange}
                                                />
                                                <Form.Label title="Выберите один из вариантов.">Самостоятельно сделать изделие или проект</Form.Label>
                                                <Form.Select
                                                    name="sposobnost_samostoyatelno_10"
                                                    value={values[20].sposobnost_samostoyatelno_10}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="No info">No info</option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>

                                                </Form.Select>
                                                <Form.Label title="Выберите один из вариантов.">Заготовка / подготовка деталей</Form.Label>
                                                <Form.Select
                                                    name="sposobnost_samostoyatelno_zagotovitj"
                                                    value={values[20].sposobnost_samostoyatelno_zagotovitj}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="No info">No info</option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>

                                                </Form.Select>
                                                <Form.Label title="Выберите один из вариантов.">Делать разметку</Form.Label>
                                                <Form.Select
                                                    name="sposobnost_samostoyatelno_razmetka"
                                                    value={values[20].sposobnost_samostoyatelno_razmetka}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="No info">No info</option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>
                                                </Form.Select>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Button as="input" type="submit" value="Сохранить" />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Collapse>
                        </Card.Body>
                    </Card>
                </form>) : (<></>)
            }
            {qMask & 12 ? (
                <form onSubmit={onSubmit(21)}>
                    <Card style={{ padding: '3px' }} ref={opens[21].ref} border={Math.pow(2, 21) & qMaskDone ? 'success' : 'light'}>
                        <Card.Header>
                            <Button disabled={opens[21].er} variant={Math.pow(2, 21) & qMaskDone ? 'success' : 'secondary'}
                                    onClick={() => handleOpens(21)}
                                    aria-expanded={opens[21].op}>{opens[21].op ? '-' : '+'}</Button>{' '}
                            Монтажные работы
                        </Card.Header>
                        <Card.Body>
                            <Collapse in={opens[21].op}>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>
                                        <h5>Умеете пользоваться грузоподъемным оборудованием?</h5>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="instalwork_waist"
                                                    value={values[21].instalwork_waist}
                                                    onChange={handleChangeB}
                                                    label='ручной цепной талью'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="instalwork_️winches"
                                                    value={values[21].instalwork_️winches}
                                                    onChange={handleChangeB}
                                                    label='лебёдки'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="instalwork_screw_jacks"
                                                    value={values[21].instalwork_screw_jacks}
                                                    onChange={handleChangeB}
                                                    label='домкраты винтовые'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="instalwork_hydraulic_jacks"
                                                    value={values[21].instalwork_hydraulic_jacks}
                                                    onChange={handleChangeB}
                                                    label='домкраты гидравлические'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="instalwork_rack_jacks"
                                                    value={values[21].instalwork_rack_jacks}
                                                    onChange={handleChangeB}
                                                    label='домкраты реечные'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="instalwork_lifts"
                                                    value={values[21].instalwork_lifts}
                                                    onChange={handleChangeB}
                                                    label='подъемники'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="instalwork_beam_crane"
                                                    value={values[21].instalwork_beam_crane}
                                                    onChange={handleChangeB}
                                                    label='кран-балка'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="instalwork_forklifts"
                                                    value={values[21].instalwork_forklifts}
                                                    onChange={handleChangeB}
                                                    label='погрузчики вилочные'
                                                />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item variant="secondary">
                                        <h5>Умеете?</h5>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="instalwork_turning_large_structures"
                                                    value={values[21].instalwork_turning_large_structures}
                                                    onChange={handleChangeB}
                                                    label='кантовать большие металлоконструкции'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="instalwork_mount_large_structures"
                                                    value={values[21].instalwork_mount_large_structures}
                                                    onChange={handleChangeB}
                                                    label='монтировать крупноразмерные металлические конструкции'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="instalwork_dismantle_large_structures"
                                                    value={values[21].instalwork_dismantle_large_structures}
                                                    onChange={handleChangeB}
                                                    label='демонтировать крупноразмерные металлические конструкции'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="instalwork_carryout_slinging_structures"
                                                    value={values[21].instalwork_carryout_slinging_structures}
                                                    onChange={handleChangeB}
                                                    label='выполнять строповку тяжелых металлоконструкций'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="instalwork_carryout_unslinging_structures"
                                                    value={values[21].instalwork_carryout_unslinging_structures}
                                                    onChange={handleChangeB}
                                                    label='выполнять расстроповку тяжелых металлоконструкций'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Check
                                                    type="checkbox"
                                                    name="instalwork_thermal_straightening"
                                                    value={values[21].instalwork_thermal_straightening}
                                                    onChange={handleChangeB}
                                                    label='делать тепловую правку металла'
                                                />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Button as="input" type="submit" value="Сохранить" />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Collapse>
                        </Card.Body>
                    </Card>
                </form>) : (<></>)
            }
            {qMask & 15 ? (
                <form onSubmit={onSubmit(22)}>
                    <Card style={{ padding: '3px' }} ref={opens[22].ref} border={Math.pow(2, 22) & qMaskDone ? 'success' : 'light'}>
                        <Card.Header>
                            <Button disabled={opens[22].er} variant={Math.pow(2, 22) & qMaskDone ? 'success' : 'secondary'}
                                    onClick={() => handleOpens(22)}
                                    aria-expanded={opens[22].op}>{opens[22].op ? '-' : '+'}</Button>{' '}
                            Сертификаты и документы
                        </Card.Header>
                        <Card.Body>
                            <Collapse in={opens[22].op}>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Veronumero</Form.Label>
                                                <Form.Select
                                                    name="veronumero_9"
                                                    value={values[22].veronumero_9}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="No info">No info</option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>
                                                </Form.Select>
                                            </Col>
                                            {(values[22].veronumero_9 !== 'Нет' && values[22].veronumero_9 !== '') && <>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Veronumero номер</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="veronumero_9_2"
                                                        value={values[22].veronumero_9_2}
                                                        onChange={handleChange}
                                                        required={values[22].veronumero_9 !== 'Нет'}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Действует до</Form.Label>
                                                    <DatePicker
                                                        selected={values[22].veronumero_9_3}
                                                        onChange={(date) => handleChangeDate("veronumero_9_3", date)}
                                                        required={values[22].veronumero_9 !== 'Нет'}
                                                    />
                                                </Col>
                                            </>
                                            }
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item variant="secondary">
                                        <Row>
                                            <Col>
                                                <Form.Label
                                                    title="Выберите один из вариантов.">Henkilötunnus</Form.Label>
                                                <Form.Select
                                                    name="henkilotunnus_9"
                                                    value={values[22].henkilotunnus_9}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="No info">No info</option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>
                                                </Form.Select>
                                            </Col>
                                            {(values[22].henkilotunnus_9 !== 'Нет' && values[22].henkilotunnus_9 !== '') && <>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Henkilötunnus номер</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="henkilotunnus_9_2"
                                                        value={values[22].henkilotunnus_9_2}
                                                        onChange={handleChange}
                                                        required={values[22].henkilotunnus_9 !== 'Нет'}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Действует до</Form.Label>
                                                    <DatePicker
                                                        selected={values[22].henkilotunnus_9_3}
                                                        onChange={(date) => handleChangeDate("henkilotunnus_9_3", date)}
                                                        required={values[22].henkilotunnus_9 !== 'Нет'}
                                                    />
                                                </Col>
                                            </>
                                            }
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Зеленая карта техники безопасности</Form.Label>
                                                <Form.Select
                                                    name="zelenaya_bezopastnosti_9"
                                                    value={values[22].zelenaya_bezopastnosti_9}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="No info">No info</option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>
                                                </Form.Select>
                                            </Col>
                                            {(values[22].zelenaya_bezopastnosti_9 !== 'Нет' && values[22].zelenaya_bezopastnosti_9 !== '') && <>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Действует до</Form.Label>
                                                    <DatePicker
                                                        selected={values[22].zelenaya_bezopastnosti_9_1}
                                                        onChange={(date) => handleChangeDate("zelenaya_bezopastnosti_9_1", date)}
                                                        required={values[22].zelenaya_bezopastnosti_9 !== 'Нет'}
                                                    />
                                                </Col>
                                            </>
                                            }
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item variant="secondary">
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Серая карта огневые работы</Form.Label>
                                                <Form.Select
                                                    name="seraya_raboty_9"
                                                    value={values[22].seraya_raboty_9}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="No info">No info</option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>
                                                </Form.Select>
                                            </Col>
                                            {(values[22].seraya_raboty_9 !== 'Нет' && values[22].seraya_raboty_9 !== '') && <>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Действует до</Form.Label>
                                                    <DatePicker
                                                        selected={values[22].seraya_raboty_9_1}
                                                        onChange={(date) => handleChangeDate("seraya_raboty_9_1", date)}
                                                        required={values[22].seraya_raboty_9 !== 'Нет'}
                                                    />
                                                </Col>
                                            </>
                                            }
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <BrowserView>
                                                    <h6>Выберите действующие Сертификаты</h6>
                                                    <Check2
                                                        cols={3}
                                                        rows={3}
                                                        option={optionSert}
                                                        name="serdejs"
                                                        value={values[22].serdejs}
                                                        change={handleChangeC}
                                                    />
                                                </BrowserView>
                                                <MobileView>
                                                    <Form.Label title="Выберите один из вариантов.">Выберите действующие Сертификаты</Form.Label>
                                                    <Form.Select
                                                        name="serdejs"
                                                        value={values[22].serdejs}
                                                        onChange={handleChangeM}
                                                        multiple={true}
                                                    >
                                                        <Options
                                                            option={optionPros}
                                                        />
                                                    </Form.Select>
                                                </MobileView>
                                            </Col>
                                            <Col>
                                                <BrowserView>
                                                    <h6>Выберите недействующие Сертификаты</h6>
                                                    <Check2
                                                        cols={3}
                                                        rows={3}
                                                        option={optionSert}
                                                        name="notserdejs"
                                                        value={values[22].notserdejs}
                                                        change={handleChangeC}
                                                    />
                                                </BrowserView>
                                                <MobileView>
                                                    <Form.Label title="Выберите один из вариантов.">Выберите недействующие Сертификаты</Form.Label>
                                                    <Form.Select
                                                        name="notserdejs"
                                                        value={values[22].notserdejs}
                                                        onChange={handleChangeM}
                                                        multiple={true}
                                                    >
                                                        <Options
                                                            option={optionSert}
                                                        />}
                                                    </Form.Select>
                                                </MobileView>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item variant="secondary">
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Пасспорт - гражданство какой страны</Form.Label>
                                                <Form.Select
                                                    name="passport"
                                                    value={values[22].passport}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="No info">No info</option>
                                                    <option value="aliens">Alien’s</option>
                                                    <option value="eesti">Эстония</option>
                                                    <option value="soome">Финляндия</option>
                                                    <option value="lat">Латвия</option>
                                                    <option value="lit">Литва</option>
                                                    <option value="poola">Польша</option>
                                                    <option value="vene">Россия</option>
                                                    <option value="valgevene">Беларусь</option>
                                                    <option value="ukrainian">Украина</option>
                                                    <option value="romanian">Румыния</option>
                                                    <option value="bulgarian">Болгария</option>
                                                    <option value="uzbekistan">Узбекистан</option>
                                                    <option value="kazahstan">Казахстан</option>
                                                </Form.Select>
                                            </Col>
                                            {(values[22].passport !== 'No info' && values[22].passport !== '') && <>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Действует до</Form.Label>
                                                    <DatePicker
                                                        selected={values[22].passport_1}
                                                        onChange={(date) => handleChangeDate("passport_1", date)}
                                                        required={values[22].passport !== 'No info'}
                                                    />
                                                </Col>
                                            </>
                                            }
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Водительские права</Form.Label>
                                                <Form.Select
                                                    name="voditelskie_prava_9"
                                                    value={values[22].voditelskie_prava_9}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="No info">No info</option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>
                                                </Form.Select>
                                            </Col>
                                            {(values[22].voditelskie_prava_9 !== 'Нет' && values[22].voditelskie_prava_9 !== '') && <>
                                                <Col>
                                                    <Form.Label
                                                        title="Выберите один из вариантов.">Категория</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="voditelskie_prava_9_1"
                                                        value={values[22].voditelskie_prava_9_1}
                                                        onChange={handleChange}
                                                        required={values[22].voditelskie_prava_9 !== 'Нет'}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label title="Выберите один из вариантов.">Наличие машины для командировок</Form.Label>
                                                    <Form.Select
                                                        name="nalichie_mashiny_9"
                                                        value={values[22].nalichie_mashiny_9}
                                                        onChange={handleChange}
                                                        required={values[22].voditelskie_prava_9 !== 'Нет'}
                                                    >
                                                        <option value=""></option>
                                                        <option value="No info">No info</option>
                                                        <option value="Да">Да</option>
                                                        <option value="Нет">Нет</option>
                                                    </Form.Select>
                                                </Col>
                                            </>
                                            }
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item variant="secondary">
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Англ. Язык уровень</Form.Label>
                                                <Form.Select
                                                    name="angl_uroven_9"
                                                    value={values[22].angl_uroven_9}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="No info">No info</option>
                                                    <option value="начальный">начальный</option>
                                                    <option value="средний">средний</option>
                                                    <option value="высокий">высокий</option>
                                                </Form.Select>
                                            </Col>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Финс. Язык уровень</Form.Label>
                                                <Form.Select
                                                    name="fins_uroven_9"
                                                    value={values[22].fins_uroven_9}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="No info">No info</option>
                                                    <option value="начальный">начальный</option>
                                                    <option value="средний">средний</option>
                                                    <option value="высокий">высокий</option>
                                                </Form.Select>
                                            </Col>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Другие языки</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="another_lang"
                                                    value={values[22].another_lang}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Form.Label title="Выберите один из вариантов.">Курите?</Form.Label>
                                                <Form.Select
                                                    name="kurite_9"
                                                    value={values[22].kurite_9}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value=""></option>
                                                    <option value="No info">No info</option>
                                                    <option value="Да">Да</option>
                                                    <option value="Нет">Нет</option>
                                                </Form.Select>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    {/* <ListGroup.Item variant="secondary">
                                        <Row>
                                            <Col>
                                                <BrowserView>
                                                    <h6>В каких странах работали?</h6>
                                                    <Check2
                                                        cols={3}
                                                        rows={3}
                                                        option={optionCountries}
                                                        name="countries"
                                                        value={values[22].countries}
                                                        change={handleChangeC}
                                                    />
                                                </BrowserView>
                                                <MobileView>
                                                    <Form.Label title="Выберите из вариантов.">В каких странах работали?</Form.Label>
                                                    <Form.Select
                                                        name="countries"
                                                        value={values[22].countries}
                                                        onChange={handleChangeM}
                                                        multiple={true}
                                                    >
                                                        <option value=""></option>
                                                        <option value="1">Эстония</option>
                                                        <option value="2">Финляндия</option>
                                                        <option value="3">Швеция</option>
                                                        <option value="4">Норвегия</option>
                                                        <option value="5">Дания</option>
                                                        <option value="6">Латвия</option>
                                                        <option value="7">Литва</option>
                                                        <option value="8">Польша</option>
                                                        <option value="9">Россия</option>
                                                        <option value="10">Украина</option>
                                                        <option value="11">Беларусь</option>
                                                        <option value="12">Другое</option>
                                                    </Form.Select>
                                                </MobileView>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item> */}
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Button as="input" type="submit" value="Сохранить" />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Collapse>
                        </Card.Body>
                    </Card>
                </form>) : (<></>)
            }
            {qMask & 15 ? (
                <form onSubmit={onSubmit(23)}>
                    <Card style={{ padding: '3px' }} ref={opens[23].ref} border={Math.pow(2, 23) & qMaskDone ? 'success' : 'light'}>
                        <Card.Header>
                            <Button disabled={opens[23].er | !values[23].approved} variant={Math.pow(2, 23) & qMaskDone ? 'success' : 'secondary'}
                                    onClick={() => handleOpens(23)}
                                    aria-expanded={opens[23].op}>{opens[23].op ? '-' : '+'}</Button>{' '}
                            {values[23].approved ? 'Форма Обратной Связи по последним местам работы' : 'block content will be available after approval'}
                        </Card.Header>
                        <Card.Body>
                            <Collapse in={opens[23].op & values[23].approved}>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>
                                        <Row>
                                            <Form.Label title="Введите данные."><h5>Форма Обратной Связи по последним местам работы</h5></Form.Label>
                                            <Col>
                                                <Form.Label title="Введите данные."><h6>№1 ФИРМА / ЗАВОД</h6></Form.Label>
                                                <Form.Control
                                                    placeholder="Название"
                                                    type="text"
                                                    name="company"
                                                    value={values[23].company}
                                                    onChange={handleChange}
                                                />
                                                <Form.Label title="Введите данные.">ПЕРИОД РАБОТЫ</Form.Label>
                                                <Form.Control
                                                    placeholder="гггг-гггг"
                                                    type="text"
                                                    name="companyexp"
                                                    value={values[23].companyexp}
                                                    onChange={handleChange}
                                                />
                                                <Form.Label title="Введите данные.">КОНТАКТНОЕ ЛИЦО</Form.Label>
                                                <Form.Control
                                                    placeholder="Имя Фамилия"
                                                    type="text"
                                                    name="companyperson"
                                                    value={values[23].companyperson}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                            <Col>
                                                <Form.Label title="Введите данные."><h6>№2 ФИРМА / ЗАВОД</h6></Form.Label>
                                                <Form.Control
                                                    placeholder="Название"
                                                    type="text"
                                                    name="company2"
                                                    value={values[23].company2}
                                                    onChange={handleChange}
                                                />
                                                <Form.Label title="Введите данные.">ПЕРИОД РАБОТЫ</Form.Label>
                                                <Form.Control
                                                    placeholder="гггг-гггг"
                                                    type="text"
                                                    name="companyexp2"
                                                    value={values[23].companyexp2}
                                                    onChange={handleChange}
                                                />
                                                <Form.Label title="Введите данные.">КОНТАКТНОЕ ЛИЦО</Form.Label>
                                                <Form.Control
                                                    placeholder="Имя Фамилия"
                                                    type="text"
                                                    name="companyperson2"
                                                    value={values[23].companyperson2}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                            <Col>
                                                <Form.Label title="Введите данные."><h6>№3 ФИРМА / ЗАВОД</h6></Form.Label>
                                                <Form.Control
                                                    placeholder="Название"
                                                    type="text"
                                                    name="company3"
                                                    value={values[23].company3}
                                                    onChange={handleChange}
                                                />
                                                <Form.Label title="Введите данные.">ПЕРИОД РАБОТЫ</Form.Label>
                                                <Form.Control
                                                    placeholder="гггг-гггг"
                                                    type="text"
                                                    name="companyexp3"
                                                    value={values[23].companyexp3}
                                                    onChange={handleChange}
                                                />
                                                <Form.Label title="Введите данные.">КОНТАКТНОЕ ЛИЦО</Form.Label>
                                                <Form.Control
                                                    placeholder="Имя Фамилия"
                                                    type="text"
                                                    name="companyperson3"
                                                    value={values[23].companyperson3}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                            <Col>
                                                <Form.Label title="Введите данные."><h6>№4 ФИРМА / ЗАВОД</h6></Form.Label>
                                                <Form.Control
                                                    placeholder="Название"
                                                    type="text"
                                                    name="company4"
                                                    value={values[23].company4}
                                                    onChange={handleChange}
                                                />
                                                <Form.Label title="Введите данные.">ПЕРИОД РАБОТЫ</Form.Label>
                                                <Form.Control
                                                    placeholder="гггг-гггг"
                                                    type="text"
                                                    name="companyexp4"
                                                    value={values[23].companyexp4}
                                                    onChange={handleChange}
                                                />
                                                <Form.Label title="Введите данные.">КОНТАКТНОЕ ЛИЦО</Form.Label>
                                                <Form.Control
                                                    placeholder="Имя Фамилия"
                                                    type="text"
                                                    name="companyperson4"
                                                    value={values[23].companyperson4}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>
                                                <Button as="input" type="submit" value="Сохранить" />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Collapse>
                        </Card.Body>
                    </Card>
                </form>) : (<></>)
            }
        </div>
    );
}
