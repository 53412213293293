import axios from 'axios';
import BASEURL16 from "../env";
import authHeader from "./auth-header";

class AnketaService {

    get() {
        console.log('get ');
        const u = BASEURL16 + '/anketa/get';
        return axios.get(u, {headers: authHeader()})
    }

    save(d) {
        console.log('save ', d);
        const u = BASEURL16 + '/anketa/save';
        return axios.post(u, d, {headers: authHeader()})
    }

    refreshToken(rtoken) {
        const u = BASEURL16 + '/auth/refresh';
        console.log('URL ', u)
        return axios.post(u, {token: rtoken})
    }
}

export default new AnketaService();
