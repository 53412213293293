import React, {Component} from "react";
import { Routes, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import AuthService from "./services/auth.service";
import Login from "./components/login.component";
import Register from "./components/register.component";
import Home from "./components/home.component";
import Anketa from "./components/Anketa";
import Restore from "./components/restore.component";

class App extends Component {

    constructor(props) {
        super(props);
        this.logOut = this.logOut.bind(this);
        this.state = {
            showModeratorBoard: false,
            showAdminBoard: false,
            currentUser: undefined,
        };
    }

    componentDidMount() {
        const user = AuthService.getCurrentUser();
        if (user) {
            this.setState({
                currentUser: user,
            });
        }
    }

    logOut() {
        AuthService.logout();
    }
    logIn(user, t) {
        console.log("logIn ", user)
        t.setState({
            currentUser: user,
        })
    }

    render() {
        const {currentUser} = this.state;
        return (
            <div>
                <nav className="navbar navbar-expand navbar-dark bg-dark">
                    {currentUser ? (
                        <div className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <a href="/login" className="nav-link" onClick={this.logOut}>
                                    LogOut
                                </a>
                            </li>
                        </div>
                    ) : (
                        <div className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <Link to={"/login"} className="nav-link">
                                    Login
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={"/register"} className="nav-link">
                                    Sign Up
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link to={"/restore"} className="nav-link">
                                    Forgot Password?
                                </Link>
                            </li>
                        </div>
                    )}
                </nav>
                <div className="mx-2 mt-3">
                    <Routes>
                        <Route exact path="/login" element={<Login logIn={this.logIn} t={this}/>}/>
                        <Route exact path="/register" element={<Register logIn={this.logIn} t={this}/>}/>
                        <Route exact path="/restore" element={<Restore logIn={this.logIn} t={this}/>}/>
                        {currentUser ? (
                            <Route exact path="/" element={<Anketa/>}/>
                        ) : (
                            <Route exact path="/" element={<Home/>}/>
                        )}
                    </Routes>
                </div>
            </div>
        );
    }
}

export default App;
