import React, {useState} from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AuthService from "../services/auth.service";
// import {useNavigate} from 'react-router-dom';

const Restore = (props) => {

    // const navigate = useNavigate();
    // const redirect = event => navigate('/');
    const [checkBtnEmail, setCheckBtnEmail] = useState();
    const [checkBtnToken, setCheckBtnToken] = useState();
    const [checkBtnPassword, setCheckBtnPassword] = useState();

    const [stage, setStage] = useState(1);
    const [formEmail, setFormEmail] = useState();
    const [formToken, setFormToken] = useState();
    const [formPassword, setFormPassword] = useState();

    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    const Timer = () => {
        setTimeout(() => {
            console.log('Timer')
            setMessage('')
        }, 3000);
    }

    // useEffect(() => {
    //     console.log(
    //         "Occurs ONCE, AFTER the initial render. ", props
    //     );
    // }, []);


    const required = value => {
        if (!value) {
            return (
                <div className="alert alert-danger" role="alert">
                    This field is required!
                </div>
            );
        }
    }

    const passwordC = value => {
        if (value.length < 6 || value.length > 40) {
            return (
                <div className="alert alert-danger" role="alert">
                    The password must be between 6 and 40 characters.
                </div>
            );
        }
    };

    const passwordC2 = (value, props, components) => {
        // console.log(value)
        // console.log(props)
        console.log(components)
        console.log(value)

        if (components.email[0].value !== value) {
            return (
                <div className="alert alert-danger" role="alert">
                    The password and password2 must be the same.
                </div>
            );
        } else {
            console.log("passwords OK ")
        }
    };

    const onChangeEmail = e => {
        setEmail(e.target.value);
    }

    const onChangeToken = e => {
        console.log("+++onChangeToken ", e.target.value)
        setToken(e.target.value);
    }

    const onChangePassword = e => {
        setPassword(e.target.value);
    }

    const onChangePassword2 = e => {
        setPassword2(e.target.value);
    }

    const handlePassword = e => {
        e.preventDefault();
        setLoading(true);
        setMessage('');

        formPassword.validateAll();
        if (checkBtnPassword.context._errors.length === 0) {
            AuthService.updatePassword(token, password, password2).then(
                (response) => {
                    console.log("after updatePassword", response)
                    setLoading(false);
                    setMessage(response.data.message);
                    if (response.status === 200) {
                        setMessage('passwors was reset, Please login');
                        props.logIn(JSON.stringify(response.data), props.t)
                        setTimeout(() => {
                            console.log('Timer 1 sec')
                            // redirect()
                        }, 1000);
                    }
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setLoading(false);
                    setMessage(resMessage);
                    Timer();
                }
            );
        } else {
            setLoading(false);
        }
    }


    const handleToken = e => {
        e.preventDefault();
        setLoading(true);
        setMessage('');

        formToken.validateAll();
        if (checkBtnToken.context._errors.length === 0) {
            AuthService.checkToken(token).then(
                (response) => {
                    console.log("after ", response)
                    setLoading(false);
                    setMessage(response.data.message);
                    Timer();
                    if (response.status === 200) {
                        setStage(3)
                    }
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setLoading(false);
                    setMessage(resMessage);
                    Timer();
                }
            );
        } else {
            setLoading(false);
        }
    }

    const handleEmail = e => {
        e.preventDefault();
        setLoading(true);
        setMessage('');

        formEmail.validateAll();
        if (checkBtnEmail.context._errors.length === 0) {
            AuthService.resetPassword(email).then(
                (response) => {
                    console.log("after ", response)
                    setLoading(false);
                    setMessage(response.data.message);
                    Timer();
                    if (response.status === 200) {
                        setStage(2)
                    }
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setLoading(false);
                    setMessage(resMessage);
                    Timer();
                }
            );
        } else {
            setLoading(false);
        }
    }


    return (
        <div className="col-md-12">
            <div className="card card-container">

                {stage === 1 ? (
                    <Form
                        style={{visibility: stage === 1 ? 'visible' : 'hidden'}}
                        onSubmit={handleEmail}
                        ref={c => {
                            setFormEmail(c);
                        }}
                    >
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <Input
                                type="text"
                                className="form-control"
                                name="email"
                                value={email}
                                onChange={onChangeEmail}
                                validations={[required]}
                            />
                        </div>
                        <div className="form-group">
                            <button
                                className="btn btn-primary btn-block"
                                disabled={loading}
                            >
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                <span>Restore</span>
                            </button>
                        </div>

                        <CheckButton
                            style={{display: "none"}}
                            ref={c => {
                                setCheckBtnEmail(c);
                            }}
                        />
                    </Form>
                ) : (
                    stage === 2 ? (
                        <Form
                            style={{visibility: stage === 2 ? 'visible' : 'hidden'}}
                            onSubmit={handleToken}
                            ref={c => {
                                setFormToken(c);
                            }}
                        >
                            <div className="form-group">
                                <label htmlFor="token">Token</label>
                                <Input
                                    type="text"
                                    value={token}
                                    className="form-control"
                                    name="token"
                                    onChange={onChangeToken}
                                    validations={[required]}
                                />
                            </div>
                            <div className="form-group">
                                <button
                                    className="btn btn-primary btn-block"
                                    disabled={loading}
                                >
                                    {loading && (
                                        <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    <span>Check token</span>
                                </button>
                            </div>
                            <CheckButton
                                style={{display: "none"}}
                                ref={c => {
                                    setCheckBtnToken(c);
                                }}
                            />
                        </Form>
                    ) : (
                        <Form
                            style={{visibility: stage === 3 ? 'visible' : 'hidden'}}
                            onSubmit={handlePassword}
                            ref={c => {
                                setFormPassword(c);
                            }}
                        >
                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="password"
                                    value={password}
                                    onChange={onChangePassword}
                                    validations={[required, passwordC]}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="password2">Password</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="password2"
                                    value={password2}
                                    onChange={onChangePassword2}
                                    validations={[required, passwordC, passwordC2]}

                                />
                            </div>
                            <div className="form-group">
                                <button
                                    className="btn btn-primary btn-block"
                                    disabled={loading}
                                >
                                    {loading && (
                                        <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    <span>Reset password</span>
                                </button>
                            </div>
                            <CheckButton
                                style={{display: "none"}}
                                ref={c => {
                                    setCheckBtnPassword(c);
                                }}
                            />
                        </Form>
                    )
                )}
                {message && (
                    <div className="form-group">
                        <div className="alert alert-danger" role="alert">
                            {message}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Restore
