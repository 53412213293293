import React, {useState} from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import AuthService from "../services/auth.service";
import {useNavigate} from 'react-router-dom';

const Login = (props) => {

    const navigate = useNavigate();
    const redirect = event => navigate('/');
    const [checkBtn, setCheckBtn] = useState();
    const [form, setForm] = useState();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    // useEffect(() => {
    //     console.log(
    //         "Occurs ONCE, AFTER the initial render. ", props
    //     );
    // }, []);


    const required = value => {
        if (!value) {
            return (
                <div className="alert alert-danger" role="alert"> This field is required! </div>
            );
        }
    };

    const onChangeEmail = e => {
        setEmail(e.target.value);
    }

    const onChangePassword = e => {
        setPassword(e.target.value)
    }

    const handleLogin = e => {
        e.preventDefault();
        setLoading(true);
        setMessage('');

        form.validateAll();
        if (checkBtn.context._errors.length === 0) {
            AuthService.login(email, password).then(
                (response) => {
                    console.log("after register ", response)
                    if (response.status === 200) {
                        props.logIn(JSON.stringify(response.data), props.t)
                        setLoading(false);
                        redirect()
                    } else {
                        setLoading(false);
                        setMessage(response.data.message);
                    }
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setLoading(false);
                    setMessage(resMessage);
                }
            );
        } else {
            setLoading(false);
        }
    }

    // render() {
    return (
        <div className="col-md-12">
            <div className="card card-container">
                <Form
                    onSubmit={handleLogin}
                    ref={c => {
                        setForm(c);
                    }}
                >
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <Input
                            type="text"
                            className="form-control"
                            name="email"
                            // value={email}
                            onChange={onChangeEmail}
                            validations={[required]}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <Input
                            type="password"
                            className="form-control"
                            name="password"
                            // value={password}
                            onChange={onChangePassword}
                            validations={[required]}
                        />
                    </div>
                    <br/>
                    <div className="form-group">
                        <button
                            className="btn btn-primary btn-block"
                            disabled={loading}
                        >
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            <span>Login</span>
                        </button>
                    </div>
                    {message && (
                        <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                                {message}
                            </div>
                        </div>
                    )}
                    <CheckButton
                        style={{display: "none"}}
                        ref={c => {
                            setCheckBtn(c);
                        }}
                    />
                </Form>
            </div>
        </div>
    );
}

export default Login
