import React, {useState} from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import {isEmail, isMobilePhone} from "validator";
import AuthService from "../services/auth.service";
import {useNavigate} from "react-router-dom";

const Register = (props) => {

    const navigate = useNavigate();
    const redirect = event => navigate('/login');
    const [checkBtn, setCheckBtn] = useState();
    const [form, setForm] = useState();

    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [phone, setPhone] = useState('');

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [successful, setSuccessful] = useState(false);

    const required = value => {
        if (!value) {
            return (
                <div className="alert alert-danger" role="alert">
                    This field is required!
                </div>
            );
        }
    };

    const fnameC = value => {
        if (value.length < 2 || value.length > 40) {
            return (
                <div className="alert alert-danger" role="alert">
                    The first name is not correct.
                </div>
            );
        }
    };
    const lnameC = value => {
        if (value.length < 2 || value.length > 40) {
            return (
                <div className="alert alert-danger" role="alert">
                    The last name is not correct.
                </div>
            );
        }
    };
    const phoneC = value => {
        if (!isMobilePhone(value, 'any')) {
            return (
                <div className="alert alert-danger" role="alert">
                    The phone is not correct.
                </div>
            );
        }
    };

    const emailC = value => {
        if (!isEmail(value)) {
            return (
                <div className="alert alert-danger" role="alert">
                    This is not a valid email.
                </div>
            );
        }
    };

    const passwordC = value => {
        if (value.length < 6 || value.length > 40) {
            return (
                <div className="alert alert-danger" role="alert">
                    The password must be between 6 and 40 characters.
                </div>
            );
        }
    };

    const onChangeFName = e => {
        console.log("onChangeFName ")
        setFname(e.target.value)
    }
    const onChangeLName = e => {
        console.log("onChangeLName ")
        setLname(e.target.value)
    }
    const onChangePhone = e => {
        console.log("onChangePhone ")
        setPhone(e.target.value)
    }

    const onChangeEmail = e => {
        console.log("onChangeEmail ")
        setEmail(e.target.value)
    }
    const onChangePassword = e => {
        console.log("onChangePassword ")
        setPassword(e.target.value)
    }

    const handleRegister = e => {
        e.preventDefault();
        setLoading(true);
        setMessage('');
        form.validateAll();
        if (checkBtn.context._errors.length === 0) {
            AuthService.register(fname, lname, phone, email, password).then(
                (response) => {
                    console.log("after register ", response)
                    if (response.status === 200) {
                        props.logIn(JSON.stringify(response.data), props.t)
                        setLoading(false);
                        redirect();
                    } else {
                        setLoading(false);
                        setMessage(response.data.message);
                    }
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setLoading(false);
                    setMessage(resMessage);
                }
            );
        }
    }

    return (
        <div className="col-md-12">
            <div className="card card-container">
                <Form
                    onSubmit={handleRegister}
                    ref={c => {
                        setForm(c);
                    }}
                >
                    {!successful && (
                        <div>

                            <div className="form-group">
                                <label htmlFor="fname">First name</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="fname"
                                    onChange={onChangeFName}
                                    validations={[required, fnameC]}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="lname">Last name</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="lname"
                                    onChange={onChangeLName}
                                    validations={[required, lnameC]}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="phone">Phone</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="phone"
                                    onChange={onChangePhone}
                                    validations={[required, phoneC]}
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    name="email"
                                    onChange={onChangeEmail}
                                    validations={[required, emailC]}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <Input
                                    type="password"
                                    className="form-control"
                                    name="password"
                                    onChange={onChangePassword}
                                    validations={[required, passwordC]}
                                />
                            </div>
                            <br/>
                            <div className="form-group">
                                <button className="btn btn-primary btn-block">Sign Up</button>
                            </div>
                        </div>
                    )}
                    {message && (
                        <div className="form-group">
                            <div
                                className={
                                    successful
                                        ? "alert alert-success"
                                        : "alert alert-danger"
                                }
                                role="alert"
                            >
                                {message}
                            </div>
                        </div>
                    )}
                    <CheckButton
                        style={{display: "none"}}
                        ref={c => {
                            setCheckBtn(c);
                        }}
                    />
                </Form>
            </div>
        </div>
    );
}

export default Register
