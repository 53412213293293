const BASEURL16='https://multiverse.ee/wapi'
// const BASEURL16 = 'http://10.0.1.2:3000/wapi'
// const BASEURL16 = 'http://192.168.2.119:3000/wapi'
// const BASEURL16 = 'http://192.168.0.120:3000/wapi'
// const BASEURL16 = 'http://192.168.2.104:3000/wapi'
// const BASEURL16 = 'http://192.168.2.101:3000/wapi'
// const BASEURL16 = 'http://192.168.1.161:3000/wapi'

export default BASEURL16;




