import axios from "axios";
import BASEURL16 from   "../env";

class AuthService {

    updatePassword = (token, password, password2) => {
        console.log('-------updatePassword------- ');
        const u = BASEURL16 + '/auth/reset2/' + token;
        return axios
            .post(u, {password, password2})
            .then(res => {
                console.log('res ', res);
                return res;
            });
    };

    checkToken = (token) => {
        console.log('-------checkToken------- ');
        const u = BASEURL16 + '/auth/reset/' + token;
        console.log('URL ', u)
        return axios
            .get(u)
            .then(res => {
                console.log('res ', res);
                return res;
            });
    };


    resetPassword = (email) => {
        console.log('-------resetPassword------- ');
        const u = BASEURL16 + '/auth/forgot';
        return axios
            .post(u, {
                email}
                )
            .then(res => {
                console.log('res ', res);
                return res;
            });
    };


    login(email, password) {
        console.log(email);
        console.log(password);
        const u = BASEURL16 + '/auth/login';
        return axios
            .post(u, {
                email,
                password
            })
            .then(res => {
                console.log('res ', res);
                if (res.data.accessToken) {
                    localStorage.setItem("user", JSON.stringify(res.data));
                    console.log('OK ', localStorage.getItem('user'));
                }
                return res;
            });
    }

    logout() {
        localStorage.removeItem("user");
    }

    register(fname, lname, phone, email, password) {
        console.log(fname);
        console.log(lname);
        console.log(phone);
        console.log(email);
        console.log(password);
        const u = BASEURL16 + '/auth/register';
        return axios
            .post(u, {
                fname,
                lname,
                phone,
                email,
                password
            })
            .then(res => {
                console.log('res ', res);
                if (res.data.accessToken) {
                    localStorage.setItem("user", JSON.stringify(res.data));
                    console.log('OK ', localStorage.getItem('user'));
                }
                return res;
            });
    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem('user'));
    }
}

export default new AuthService();
