import Form from 'react-bootstrap/Form';

function Check2({option, name, value, change, cols, rows}) {

    console.log('--------Check2 ', value)

    const l = option.length;
    let c = -1;
    return (
        <>
            {[...Array(Math.ceil(l / cols))].map((x, i) =>
                <div style={{float: 'left', paddingRight: '15px'}} key={`d-${i}`}>
                    {[...Array((l - i * rows) > rows ? rows : Math.abs(l - i * rows))].map(() => (
                            ++c,
                            option.map((a, y) => (
                                y === c ?
                                    <div key={`${name}-${a.v}`} className="mb-3">
                                        <Form.Check
                                            type='checkbox'
                                            label={a.l}
                                            onChange={() => change(name, value, a.v)}
                                            checked={value.includes(a.v)}
                                        />
                                    </div> :
                                    null
                                ))
                        )
                    )}
                </div>
            )}
        </>
    );
}

export default Check2;
