import {ButtonGroup, ToggleButton} from "react-bootstrap";
import React from "react";

function QA({option, name, value, change, rows}) {
    const l = option.length;
    let c = -1;
    return (
        <>
            {[...Array(rows)].map((x, i) =>
                <div style={{float: 'left', paddingBottom: '5px'}} key={`d-${i}`}>
                    <ButtonGroup>
                    {[...Array((l - i * 10) > 10 ? 10 : Math.abs(l - i * 10))].map(() => (
                            ++c,
                                    option.map((a, y) => (
                                    y === c ?
                                    <ToggleButton
                                        style={{width: 35}}
                                        key={y}
                                        id={`radio-${name}-${y}`}
                                        type="radio"
                                        variant='outline-secondary'
                                        size="sm"
                                        value={a.value}
                                        checked={value === a.value}
                                        onChange={() => change(name, a.value)}
                                    >
                                        {a.name}
                                    </ToggleButton> :
                                    null
                                    ))
                        )
                    )}
                    </ButtonGroup>
                </div>
            )}
        </>
    );
}

export default QA;
